import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";
import title from "../Title";
const Banner = ({data}) => {
    const interleaveOffset = 0.5;

    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };


    useEffect(() => {

        const pagination = document.querySelector('.swiper-pagination-horizontal');
        if (pagination) {
            pagination?.classList?.add('container');
        } else {
        }

    }, [data]);

    // handle slider progress
    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
        }
    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".slide-inner")
                .style.transition = `${speed}ms`;
        }
    };


    // remove extra height from mobile
    useEffect(() => {

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;

        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);


        window.addEventListener('load',function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });
        window.addEventListener('resize',function () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

        });

    }, [data]);

    const [exploreClicked, setExploreClicked] = useState(false);

    const handleExploreClick = () => {
        setExploreClicked(true);
    }
    const handleMouseLeave = () => {
        setExploreClicked(false);
    };
    return (
        <StyledBanner className={'home-banner'}>
            <Container >
                <Row>
                    <Col>
                        <div className={'swiper-pagination'}></div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="sweeper-nav">
                    <ul className={'navigation'}>
                        <li id={'parenting-prev'} className={'prev'}>
                            <svg id="Component_29_5" data-name="Component 29 – 5" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 25 25">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle id="Ellipse_462" data-name="Ellipse 462" cx="12.5" cy="12.5" r="12.5" fill="none" stroke="#fff" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_302" data-name="Mask Group 302" clip-path="url(#clip-path)">
                                    <g id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                                        <circle cx="12.5" cy="12.5" r="12" fill="none"/>
                                    </g>
                                    <g id="Group_19850" data-name="Group 19850" transform="translate(-619 -716.5)">
                                        <g id="Group_19216" data-name="Group 19216" transform="translate(627 725.5)">
                                            <line id="Line_3882" data-name="Line 3882" x1="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883" data-name="Line 3883" x2="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377" data-name="Line 12377" x2="10" transform="translate(627.5 728.5)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                    <g id="Group_19851" data-name="Group 19851" transform="translate(-599 -716.5)">
                                        <g id="Group_19216-2" data-name="Group 19216" transform="translate(627 725.5)">
                                            <line id="Line_3882-2" data-name="Line 3882" x1="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883-2" data-name="Line 3883" x2="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377-2" data-name="Line 12377" x2="10" transform="translate(627.5 728.5)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>
                        </li>
                        <li id={'parenting-next'} className={'next'}>
                            <svg id="Component_30_4" data-name="Component 30 – 4" xmlns="http://www.w3.org/2000/svg"  width="35" height="35" viewBox="0 0 25 25">
                                <defs>
                                    <clipPath id="clip-path">
                                        <circle id="Ellipse_462" data-name="Ellipse 462" cx="12.5" cy="12.5" r="12.5" fill="none" stroke="#fff" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_302" data-name="Mask Group 302" clip-path="url(#clip-path)">
                                    <g id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#fff" stroke-width="1">
                                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                                        <circle cx="12.5" cy="12.5" r="12" fill="none"/>
                                    </g>
                                    <g id="Group_19850" data-name="Group 19850" transform="translate(-12 9)">
                                        <g id="Group_19216" data-name="Group 19216" transform="translate(7.346)">
                                            <line id="Line_3882" data-name="Line 3882" x2="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883" data-name="Line 3883" x1="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377" data-name="Line 12377" x1="10" transform="translate(0 3)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                    <g id="Group_19851" data-name="Group 19851" transform="translate(8 9)">
                                        <g id="Group_19216-2" data-name="Group 19216" transform="translate(7.346)">
                                            <line id="Line_3882-2" data-name="Line 3882" x2="3.154" y2="3.154" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_3883-2" data-name="Line 3883" x1="3.154" y2="3.154" transform="translate(0 3.154)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                        <line id="Line_12377-2" data-name="Line 12377" x1="10" transform="translate(0 3)" fill="none" stroke="#fffefb" stroke-linecap="round" stroke-width="1"/>
                                    </g>
                                </g>
                            </svg>


                        </li>
                    </ul>
                </div>
            </Container>

            {
             data && data?.length>0 &&
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={0}
                    autoplay
                    speed='1500'
                    onProgress={handleProgress}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    watchSlidesProgress={true}
                    mousewheelControl={true}
                    keyboardControl={true}
                    loop={true}
                    // navigation={true}
                    navigation={{
                        prevEl: '#parenting-prev',
                        nextEl: '#parenting-next',
                    }}

                    pagination={{ clickable: true }}
                    className="mySwiper"
                >
                    {
                        data && data?.length>0 &&
                        data?.map((element)=>{
                            return(
                                <SwiperSlide>
                                    <div className="slide-inner">
                                        <Img  src={element?.full_path ? element?.full_path  : '/images/dynamic/home/banner.jpg'} alt={'home banner'}/>
                                        <Container className={'container_info'}>
                                            <Row>
                                                <Col>
                                                    <div className="slide-inner__info">
                                                        <p>{element?.short_title}</p>
                                                        <h2>{ReactHtmlParser(element?.short_desc)}</h2>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
            }

            {
                data &&

                <Container className="project-btn" onMouseLeave={handleMouseLeave}>
                    <Row>
                        <Col>
                            <div className={`explore  explore-btn ${exploreClicked ? 'open' : ''}`}  onMouseEnter={handleExploreClick}>
                                <h5 className={'FirstText'} >Explore</h5>
                            </div>
                            <Link to={'/projects?status=ongoing'}>
                                <div className={`ongoing explore-btn ${exploreClicked ? 'open' : 'close'}`}>
                                    <h5>Ongoing</h5>
                                </div>
                            </Link>
                            <Link to={'/projects?status=completed'}>
                                <div className={`completed explore-btn ${exploreClicked ? 'open' : 'close'}`}>
                                    <h5>Completed</h5>
                                </div>
                            </Link>
                            <Link to={'/projects?status=upcoming'}>
                                <div className={`upcoming explore-btn ${exploreClicked ? 'open' : 'close'}`}>
                                    <h5>Upcoming</h5>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Container>

            }

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  //height: calc(100vh - 100px);
  //height: calc(var(--vh, 1vh) * 100 - 100px);

  position: relative;
  overflow: hidden;
  //margin-top: 100px;

  .home-banner {
    .container {
      position: relative;
    }

  }
  .sweeper-nav {
    position: absolute;
    right: 15%;
    top: 85%;
    z-index: 999;

    ul {
      display: flex;

      li {
        margin-left: 10px;
        cursor: pointer;
        //background: red;
      }
    }
    .next {
      #Group_19850 {
        transition: .2s ease-in-out;
      }

      #Group_19851 {
        transition: .2s ease-in-out;
      }
      transition: .2s ease-in-out;
      &:hover {
        svg {
          #Group_19850 {
            transform: translate(8px, 9px);
            transition: .2s ease-in-out;
          }

          #Group_19851 {
            transform: translate(55px, 9px);
            transition: .2s ease-in-out;
          }

        }
      }


    }

    .prev {
      #Group_19850 {
        transition: .2s ease-in-out;
      }

      #Group_19851 {
        transition: .2s ease-in-out;
      }
      transition: .2s ease-in-out;
      &:hover {
        svg {
          #Group_19850 {
            transform: translate(-800px, -716.5px);
            transition: .2s ease-in-out;
          }

          #Group_19851 {
            transform: translate(-620px, -716.5px);
            transition: .2s ease-in-out;
          }

        }
      }


    }


  }

  .project-btn{
    position: absolute;
    left: 100px;
    width: 100%;
    bottom: 0;
    @media(max-width: 767px){
      left:0;
    }
  }
  //.explore{
  //  margin-bottom: 10px !important;
  //}
  .explore-btn{
    display: inline-flex;
    justify-content: center;
    padding: 30px 0;
    left: 50%;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 0);
    align-items: baseline;
    overflow: hidden;
    //transition: 1s all cubic-bezier(0.37, 0, 0.63, 1);
    cursor: pointer;
    h5{
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      color: #fff;
      @media(max-width: 767px){
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .explore{
    z-index: 99;
    width: 10%;
    position: relative;
    transition: 0.5s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
    @media(max-width: 767px){
      width: 33%;
    }
  }


  .ongoing{
    opacity: 0;
    z-index: 55;
    width: 10%;

  }
  .upcoming{
    opacity: 0;
    z-index: 55;
    width: 10%;

  }
  .completed{
    opacity: 0;
    z-index: 55;
    width: 10%;

  }
  .ongoing.open{
    transform: translate(-145%, 0);
    z-index: 9;
    opacity: 1;
    width: 33%;
    transition: 0.5s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
    border-top: 1px solid #FFF;
    background-color: rgba(0,0,0,0.5);
    &:hover{
      background-color: rgba(208,81,16,0.5);

    }
    @media(max-width: 767px){
      transform: translate(-150%, 0);
    }

  }
  .upcoming.open{
    transform: translate(55%, 0);
    z-index: 9;
    opacity: 1;
    width: 33%;
    transition: 0.5s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
    border-top: 1px solid #FFF;
    background-color: rgba(0,0,0,0.5);
    &:hover{
      background-color: rgba(208,81,16,0.5);
    }
    @media(max-width: 767px){
      transform: translate(50%, 0);
    }
  }

  .completed.open{
    transform: translate(-45%, 0);
    z-index: 100;
    opacity: 1;
    width: 33%;
    transition: 0.5s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
    border-top: 1px solid #FFF;
    background-color: rgba(0,0,0,0.5);
    &:hover{
      background-color: rgba(208,81,16,0.5);
    }
    @media(max-width: 767px){
      transform: translate(-50%, 0);
    }
  }

  .explore.open{
    opacity: 0;
    transition: 0.8s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  .close {
    transform: translate(-45%, 0);
    opacity: 0;
    transition: 0.5s all cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .home-banner{
    .container{
      position: relative;
    }

  }
  .social-icons{
    position: absolute;
    bottom: 40px;
    //top: 50%;
    //transform: translateY(-50%);
    z-index: 5;
  }

  .icon{
    padding:  12px 16px;
    margin-right: 20px;
  }

  @keyframes bounce {
    from {
      transition: var(--transition);
      transform: translate3d(0,0,0);
    }

    to {
      transition: var(--transition);
      transform: translate3d(0,10px,0);
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .slide-inner {
    position: relative;
    height: 100vh;
    .container_info {
      position: absolute;
      left: 0;
      right: 0;
      top: 40% !important;
      transform: translateY(-50%);
      z-index: 2;
    }
 
    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;
      p{
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 50px;
        font-size: 18px;
        line-height: 26px;
        transform: translateY(400px);
        transition: 2.5s all cubic-bezier(0.4, 0, 0, 1);
        position: relative;


        @media(max-width: 767px){
          margin-bottom: 30px;
        }

      }

      h2 {
        font-size: 60px;
        font-weight: 400;
        line-height: 75px;
        font-family: "Century", Euclid Square, Arial, Helvetica, freesans, sans-serif ;
        color: #FFFFFF;
        position: relative;
        transform: translateY(400px);
        transition: 3s all cubic-bezier(0.4, 0, 0, 1);
        margin: 0;
        text-transform: uppercase;
      }

      .dc-btn {
        position: relative;
        transform: translateY(400px);
        transition-delay: 0.5s;
        transition: 3s all cubic-bezier(0.4, 0, 0, 1);

      }
    }
  }

  .swiper-slide-active {
    .slide-inner {
      &__info {
        h2 {
          transform: translateY(0px);
        }
        p {
          transform: translateY(0px);
        }

        .dc-btn {
          transform: translateY(0px);
        }
      }

    }
  }
  .swiper-pagination-bullet-active{
    font-size: 30px;
  }
  swiper-pagination-bullets{
    //display: flex;
    //background-color: yellow;

  }
  .swiper-pagination-bullet{
    display: block !important;
    background-color: white;
    opacity: 1 !important;
  }
  .swiper-pagination-lock {
    display: block !important;
  }
  .swiper{
    position: relative;
  }
  .swiper-pagination {
    display: flex;
    //justify-content: end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 200px;
    z-index: 99;
    
    @media(max-width: 767px){
      bottom: 150px;
    }
  }
  .swiper-pagination-bullet-active{
    outline: auto;
    background-color: transparent;
    opacity: 0;
  }

  //navigation
  .navigation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    display: inline-block;
    z-index: 1;

    ul {
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          path {
            transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          svg {
            path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }


  //scroll to next section
  .scrollTo {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 10;
    animation: bounce 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    circle {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
    }

    #Ellipse_400 {
      transition: 0.5s all cubic-bezier(0.4, 0, 0, 1);
      fill: ${hover};
      stroke: ${hover}
    }

    &:hover {
      circle {
        stroke: ${hover}
      }

      path {
        stroke: rgba(255, 255, 255, 1);
      }

      #Ellipse_400 {
        r: 19.5px;

      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }

        .dc-btn {
          a {
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    //border-bottom-right-radius: 30px;
    .icon{
      margin-right: 10px;
    }
    .project-btn{
      left: 0;
    }

    .slide-inner {
      .container_info {
        top: unset;
        transform: unset;
        bottom: 150px;
      }


      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 34px;
          line-height: 40px;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      bottom: 30px;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
  }
`;
export default React.memo(Banner);

