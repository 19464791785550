import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints as ApiServices, apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";

import {fetchProjectDetail} from "../../api/redux/project";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchMedia, fetchMediaDetail} from "../../api/redux/media";
import MediaDetails from "../../components/media/MediaDetails";

const MyComponent = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();


    // api call
    const Store = useSelector((state) => state);
    let news=Store?.media?.posts?.data?.list;
    let newsDetails=Array.isArray(news)? news.find(f => f?.page_data?.slug === slug) : '';
    let banner=newsDetails?.sections?.find(f => f?.section_data?.template=== 'inner_banner');
    let upperDesc=newsDetails?.sections?.find(f => f?.section_data?.template === 'upper_description');
    let video=newsDetails?.sections?.find(f => f?.section_data?.template === 'video');
    let lowerDesc=newsDetails?.sections?.find(f => f?.section_data?.template === 'lower_description');

    useEffect(() => {
        window.scroll(0, 0)
    }, [news])

    useEffect(() => {

            let api_services = ApiServices.CHILD_PAGE;
            let param = {
                [ApiParamKey.page_id]: '49',
                [ApiParamKey.value]: 'media-center',
                [ApiParamKey.get_section]: 'true',
                [ApiParamKey.image]: 'yes',
                [ApiParamKey.post]: 'yes',

            }
        setTimeout(() => {
            dispatch(fetchMedia([api_services, param]));
        }, 500);

    }, [])

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                {/*<title>{getData?.detail?.title ? getData?.detail?.title : ' | Climax Holdings Ltd.'}</title>*/}
                <meta name="description" content="Explore the heart of our journey through captivating moments and impactful experiences. Dive into our media and events hub, where we showcase the latest news, captivating stories, and upcoming events that define our organization's dynamic spirit. Join us in celebrating our milestones and connecting with our vibrant community."/>
            </Helmet>

            <StyledComponent>
                {/*<h1>{'On detail'}</h1>*/}
                {/*{getData?.detailLoading ? <Loading/> :*/}
                {/*    <>*/}
                {/*        <h2>{getData?.detail?.title}</h2>*/}
                {/*        <p>{getData?.detail?.body}</p>*/}
                {/*    </>*/}
                {/*}*/}
                <MediaDetails banner={banner?.images?.list?.[0]?.full_path}
                              upperDesc={upperDesc?.section_data?.description}
                              video={video?.images?.list?.[0]?.full_path}
                              videoLink={video?.images?.list?.[0]?.short_desc}
                              lowerDesc={lowerDesc?.section_data?.description}
                              lowerImg={lowerDesc?.images?.list?.[0]?.full_path}
                              data={newsDetails}
                />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
