import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Climax from "../../components/about/Climax";
import ClimaxReverse from "../../components/about/ClimaxReverse";
import Identity from "../../components/about/Identity";
import AboutOverview from "../../components/about/AboutOverview";
import MissionVission from "../../components/about/MissionVission";
import AboutQuote from "../../components/about/AboutQuote";
import Footer from "../../components/Footer";
import InnerBanner from "../../components/InnerBanner";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchHome} from "../../api/redux/home";
import {useDispatch, useSelector} from "react-redux";
import {fetchAbout} from "../../api/redux/about";
import {TimelineLite} from "gsap";
import {loading} from "../../api/redux/global";

const MyComponent = () => {

    const dispath = useDispatch()
    const location = useLocation();

    // preloader start on page/router change
    useEffect(() => {
        const tl = new TimelineLite();
        dispath(loading());
        tl.fromTo('.page-transition', {
            xPercent: -100, // Start from left side
            opacity: 1,
            display: 'flex'
        }, {
            xPercent: 0, // Move to the center
            duration: 1,
            ease: 'power3.out' // Use a smooth easing function

        }).to('.page-transition .logo', {
            duration: .3,
            opacity: 1
        }, '-=.7');
    }, [location?.pathname]);

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'about-us',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }
        dispath(fetchAbout([api_url , api_params]))

    }, [location?.pathname])

    const getData = useSelector((state) => state.about);

    const aboutBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-us-banner');
    const aboutOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-overview');
    const aboutMission = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-vision');
    const aboutClimax = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'climax-zigzag');
    const aboutIdentity = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'symbolic-identity');
    const aboutQuotes = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'quotes');

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About | Climax Holdings Ltd.</title>
                <meta name="description" content="Discover Climax Holdings Ltd., where innovation, quality, and sustainability converge to create extraordinary properties. With a proven track record of excellence, our vision is to lead the real estate landscape to make incredible homes. Our meticulous design, sustainable technologies, and commitment to trust redefine living spaces, exceeding client expectations, as well as embodying environmental responsibility."/>
            </Helmet>

            <StyledComponent>
                <InnerBanner parallax
                    img={aboutBanner?.images?.list?.[0]?.full_path}
                    title={aboutBanner?.section_data?.subtitle}
                />
                <AboutOverview data={aboutOverview}/>
                <MissionVission data={aboutMission}/>
                {
                    aboutClimax?.posts?.list && aboutClimax?.posts?.list?.length>0 &&
                    aboutClimax?.posts?.list?.map((element,index)=>{
                        if(index%2===0){
                            if(index===0){
                                return(

                                    <Climax
                                        img={element?.images?.[0]?.full_path}
                                        svg={element?.images?.find(f => f?.thumb === 'on')? element?.images?.find(f => f?.thumb === 'on')?.full_path : ''}
                                        title={element?.data?.title}
                                        padding="200px"
                                        mobilePaddingTop="120px"
                                        description={element?.data?.description}
                                    />
                                )
                            }
                            return(

                                <Climax
                                    img={element?.images?.[0]?.full_path}
                                    svg={element?.images?.find(f => f?.thumb === 'on')? element?.images?.find(f => f?.thumb === 'on')?.full_path : ''}
                                    title={element?.data?.title}
                                    padding="100px"
                                    mobilePaddingTop="60px"
                                    description={element?.data?.description}
                                />
                            )


                        }
                        else{
                            if(index === 5){
                                return (
                                    <ClimaxReverse
                                        img={element?.images?.[0]?.full_path}
                                        svg={element?.images?.find(f => f?.thumb === 'on')? element?.images?.find(f => f?.thumb === 'on')?.full_path : ''}
                                        title={element?.data?.title}
                                        description={element?.data?.description}
                                        padding="200px"
                                        mobilePaddingBottom="120px"
                                    />
                                )
                            }
                            return (
                                <ClimaxReverse
                                    img={element?.images?.[0]?.full_path}
                                    svg={element?.images?.find(f => f?.thumb === 'on')? element?.images?.find(f => f?.thumb === 'on')?.full_path : ''}
                                    title={element?.data?.title}
                                    description={element?.data?.description}
                                />
                            )

                        }
                    })
                }

                <Identity data={aboutIdentity}/>
                <AboutQuote data={aboutQuotes}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default React.memo(MyComponent);
