import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {title} from "../../styles/globalStyleVars";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";

const MyComponent = ({data}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });
    const onSubmit = async (e) => {
        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('form_id', 'contact-form');
        formData.append('spam_protector', '');

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            reset();
            try {
                const response = await dispatch(postForm([api_services, formData]));

                if (response && response.payload.result==='success') {
                    success(response.payload.message);
                }
                else if (response && response.error && response.error) {
                    error(response.payload.message);
                }
            } catch (err) {
                console.error('API Error:', err); // Check for any errors in the console
                error('An error occurred while submitting the form.');
            }
        }
    };

    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         console.log(responseData?.success);
    //         success(responseData?.success)
    //     }
    //
    // }, [responseData])


    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        {
                            data?.section_data?.subtitle &&
                            <Title text={data?.section_data?.subtitle} nosplit/>
                        }

                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={{span:10,offset:1}} className='modal-data__content'>

                        <Form  onSubmit={handleSubmit(onSubmit,onError)}>
                            <input name={'spam_protector'} type='hidden'/>
                            <input name={'form_id'}  value={'contact-form'} type='hidden'/>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name",{
                                    required: 'Name is required',
                                    pattern: {
                                        message: 'Name must contain only letters',
                                    },

                                })}
                                type="text"
                                placeholder="Name*"
                               />
                            <div className="form__phoneEmail">
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone",{
                                        required:{
                                            value:true,
                                            message:'please enter your phone first'
                                        },
                                        pattern:{
                                            // value:/^01[0-9]{9}$/,
                                            message:'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number"
                                    placeholder="Phone*"/>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email",{
                                        required:{
                                            value:true,
                                            message:'please enter your email'
                                        },
                                        pattern:{
                                            value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message:'please enter a valid email address'
                                        }
                                    })}

                                    type="email" placeholder="Email*"/>
                            </div>
                            <Form.Control
                                className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("message",{
                                    required:{
                                        // value:true,
                                        message:'please enter your Message'
                                    },

                                })}
                                type="textarea" placeholder="Message"/>

                           <Row>
                               <Col md={2}>
                                   <div className="button-group">
                                       <div onClick={handleSubmit(onSubmit,onError)}>
                                           <Button text={'Submit'} fontWeight={'700'} color={'#D05110'} svgColor={'#D05110'} background={'#ffffff'}/>
                                       </div>
                                   </div>
                               </Col>
                           </Row>
                        </Form>

                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  .title{
    margin-bottom: 60px;
    h3{
      color: #ffffff;
      font-family: ${title};

    }
  }
  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;
    padding-bottom: 20px;
    &::placeholder{
      color: #FFFFFF;
      opacity: 0.5;
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .form__phoneEmail{
    display: flex;
    gap: 20px;
    @media(max-width: 767px){
      flex-direction: column;
      input{
        width: 100% !important;
      }
    }
    input{
      width: 49%;
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }
  .button-group{
    padding-top: 20px;
  }
  @media(max-width: 767px){
    padding-top: 120px;
    .button-group{
      display: flex;
      justify-content: center;
    }
  }
  


  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    &:focus{
      border-bottom: 1px solid #FFFFFF !important;
    }
    &:hover{
      border-bottom: 1px solid #FFFFFF !important;
    }
  }

`;

export default React.memo(MyComponent);
