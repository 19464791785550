import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VisibilitySensor from "react-visibility-sensor";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";

const RandomSliderV1 = ({data}) => {
    // SwiperCore.use([Autoplay]);
    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();

    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(1)

    useEffect(() => {

        leftRef.current.addEventListener('click', () => {
            if (document.querySelector('.swiper-button-prev')) {
                document.querySelector('.swiper-button-prev').click()
            }
        });
        rightRef.current.addEventListener('click', () => {
            if (document.querySelector('.swiper-button-next')) {
                document.querySelector('.swiper-button-next').click()
            }
        });
        // mLeftRef.current.addEventListener('click', () => {
        //     if (document.querySelector('.swiper-button-prev')) {
        //         document.querySelector('.swiper-button-prev').click()
        //     }
        // });
        // mRightRef.current.addEventListener('click', () => {
        //     if (document.querySelector('.swiper-button-next')) {
        //         document.querySelector('.swiper-button-next').click()
        //     }
        //
        // });
        setOffset(document.querySelector(' .container').offsetLeft)

    }, [])


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [data])


    useEffect(() => {
        // document.querySelector('.swiper-container').style.paddingLeft = offset + 'px'
    }, [offset, sliderRef])


    useEffect(() => {
        if (sliderRef.current) {
            const swiperInstance = sliderRef.current.swiper;
            if (swiperInstance) {
                setTotalNumber(swiperInstance.slides.length);
            }
        }
    }, [data])
    const [swiperIndex, setSwiperIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setSwiperIndex(swiper.realIndex);
    };


    return (
        <StyledBlog offset={offset} className='blog-slider pt-200 pb-200'>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12} >
                        <div className="blog_header">
                            <div className="blog-title">
                                <Title text={'Latest <span>updates </span>'} fontSize={'60'}
                                lineHeight={'68'}
                                />
                            </div>


                            <div className="blog-button">
                                <Button src={'/media'} text={'Explore All'} background={'#D05110'} hoverBackground={'#3C4348'}/>
                                <div className="slider-nav">
                                    <ul>
                                        <li className='hover prevhover slider-prev' ref={leftRef}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li className='hover nexthover slider-next' ref={rightRef}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

            <VisibilitySensor offset={{top: -150}}>
                <div className={` fade-up blog-slider__slider-wrap`}>
                    <div className="blog-slider__slider-wrap__inner">
                        {
                            data?.list && data?.list?.length>0 ?
                        <Swiper

                            loop={false}
                            slidesPerView={3}
                            spaceBetween={30}
                            speed={1500}
                            // slidesPerGroup={1}
                            keyboardControl={true}
                            modules={[Autoplay, Pagination, Navigation, Mousewheel]}
                            navigation
                            onSlideChange={handleSlideChange}
                            pagination={{
                                type: 'fraction',
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            ref={sliderRef}
                            className="mySwiper"
                        >
                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element)=>{

                                    return(
                                        <SwiperSlide>
                                            <div className='blog-single fade-up'>
                                                <div className="blog-single__inner">
                                                    <Link to={`/media/${element?.page_data?.slug}`}><a/></Link>
                                                    <Img src={element?.images?.list?.[0]?.full_path} objectFit={'cover'} layout={'fill'} alt={'news image'}/>
                                                    <div className="blog-single__inner__content">
                                                        <div className="blog-single__inner__content__top">
                                                            <p>{element?.page_data?.description}</p>
                                                            <h5>{element?.page_data?.title}</h5>
                                                        </div>
                                                        <div className="blog-single__inner__content__bottom">
                                                            <div>
                                                                <h2>{element?.page_data?.subtitle}</h2>
                                                                <p>{element?.page_data?.short_desc}</p>
                                                            </div>
                                                            <div>
                                                                <p>{element?.page_data?.type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }



                        </Swiper> : ''
                        }
                    </div>

                    {/*<div className="slider-nav-mobile">*/}
                    {/*    <ul>*/}
                    {/*        <li className='hover' ref={mLeftRef}><BsChevronLeft/></li>*/}
                    {/*        <li className='hover' ref={mRightRef}><BsChevronRight/></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </VisibilitySensor>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  background: #F5F5F5;
  .blog-title {
    position: relative;

    p {
      position: absolute;
      top: 0;
      right: 0;

    }

  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
    align-items: center;
    gap: 40px;

    .slider-nav {
      ul {
        display: flex;
        gap: 20px;
      }

      .slider-prev{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #3C4348;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          transform: rotate(180deg);
          z-index: 5;
        }
      }
      .slider-next{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #3C4348;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          z-index: 5;
        }
      }
    }
  }
  

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    margin-right: ${props => props.offset + 15}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }

  .blog-single__inner {
    padding-top: calc(460 / 370 * 100%);
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #3C4348;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        transition: height .4s ease;
      }

      &__top {
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #FFFFFF;
          position: absolute;
          left: 40px;
          bottom: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h5 {
          position: absolute;
          bottom: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          color: #FFFFFF;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        top: 40px;
        left: 40px;
        right: 40px;
        display: flex;
        justify-content: space-between;
        z-index: 2;

        h2 {
          font-size: 60px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 48px;
          transition: color .3s ease;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #FFFFFF;
          text-align: left;
          font-weight: 400;
          line-height: 24px;
          transition: color .3s ease;

          span {
            display: block;
            color: #F0EDE3;
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content:after {
        height: 0;
      }
      .blog-single__inner__content__top {
        h5 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF;

        h4 {
          color: #F0EDE3;
        }

        p {
          color: #F0EDE3;

          span {
            color: #F0EDE3;
          }
        }
      }
    }

  }



  @media (max-width: 767px) {
    .blog-single__inner__content__top {
      p, h2 {
        left: 30px;
        right: 30px;
        bottom: 30px;
      }
    }

    .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
      left: 30px;
      right: 30px;
      top: 30px;
    }

    .swiper-initialized {
      margin-left: 0;
      margin-right: 0;
    }

    .blog-slider {
      &__slider-wrap {
        margin-left: 15px;
        margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 40px;
            width: 40px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .slider-nav {
        //display: none;
      }
    }

    .slider-nav-mobile {
      display: block;
    }

  }
  
  .blog_header{
    display: flex;
    justify-content: space-between;
    @media(max-width: 767px){
      flex-direction: column;
      .blog-button{
        display: flex;
        flex-direction: row-reverse;
        gap: 30px;
      }
    }
  }

`;
export default React.memo(RandomSliderV1);

