import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Address from "../../components/contact/Address";
import ContactForm from "../../components/contact/ContactForm";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchCsr} from "../../api/redux/csr";
import {fetchContact} from "../../api/redux/contact";
import InnerBanner from "../../components/InnerBanner";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'contact',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }
        setTimeout(() => {
            dispath(fetchContact([api_url , api_params]))
        }, 500);

    }, [])

    const getData = useSelector((state) => state.contact);
    const contactBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-banner');
    const contactAddress= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-map');
    const contactForm= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-form');

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact | Climax Holdings Ltd.</title>
                <meta name="description" content="Contact us to know more"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={contactBanner?.section_data?.subtitle} img={contactBanner?.images?.list?.[0]?.full_path}/>
                <Address img={contactAddress?.images?.list?.[0]?.full_path} link={contactAddress?.images?.list?.[0]?.short_desc} address={contactAddress?.section_data} contactList={contactAddress?.posts?.list}/>
                <ContactForm data={contactForm}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
