import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import Select, {components} from "react-select";
import {hover} from "../../styles/globalStyleVars";


const NewsList = ({title_top, title_middle,data, id,clear,setClear, setClick, sticky}) => {
    const [isCompleted, setIsCompleted] = useState(false)

    const [index, setIndex] = useState( 6)


    const initialPosts = data?.slice( 0, index);
    let navLinks=document.querySelectorAll('.inner-menu ul li a');

    const getInfo = (id,type) => {
        setClick(type)
        let navLinks=document.querySelectorAll('.inner-menu ul li ');
        for(let j=0;j<=navLinks.length;j++)
        {
            if(j===id){
                navLinks[j]?.classList?.add('active')
            }
            else navLinks[j]?.classList?.remove('active')
        }
    }
    const loadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
            document.querySelector('.laodmorehide').classList.add('hide');
        } else {
            setIsCompleted(false)
        }
    }


    useEffect(() => {
        if(clear === 0){
            setIndex(6)
        }
    }, [clear])




    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen,
            // transform: state.selectProps.menuIsOpen && "rotate(180deg)",
            paddingRight: 30,
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#FFFFFF` : '#3C4348',
            backgroundColor: state.isSelected ? '#D05110' : '#FFF',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            letterSpacing: 2,
            textTransform : 'capitalize',
            // paddingLeft: 10,
            // paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 500,
            "&:hover": {
                backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#181D24',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `#181D24` : '#FFFDFB',
            margin: 0,
            fontSize: 16,
            zIndex:10,



        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 16,
            // paddingBottom:10,
        }),

    };
    const [selected, setSelected] = useState('');
    const handleSelect = (value) => {
        setClick(value)
    }

    const option = [
        {value: 'News', label: 'News'},
        {value: 'Article', label: 'Article'},
        {value: 'Blogs', label: 'Blogs'},
    ]
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#D05110" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#D05110" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };




    return (

        <StyeldSection id={`${id ? id : 'NewsList'}`} className={`slider_component_latest pb-200 `} sticky={sticky}>

            <Container className={'version_one'}>

                <Row className="inner-menu">
                    <Col md={12}>
                        <ul>
                            <li className={'active'} key={0} onClick={() => getInfo(0, 'All')}><a>ALL</a></li>
                            <li className={''} key={1} onClick={() => getInfo(1, 'Article')}><a>ARTICLES</a></li>
                            <li className={''} key={2} onClick={() => getInfo(2, 'Blog')}><a>BLOGS</a></li>
                            <li className={''} key={3} onClick={() => getInfo(3, 'News')}><a>NEWS</a></li>
                        </ul>
                        <Select
                            classNamePrefix="filter"
                            onChange={op => handleSelect(op.label)}
                            isSearchable={false}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            options={option}
                            placeholder={'All'}
                            className={'mobile_select'}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        initialPosts?.length>0 ?
                            initialPosts && initialPosts?.length>0 &&
                            initialPosts?.map((element)=>{
                                return(
                                    <Col md={4} className={'single_item_news'} key={element?.id}>
                                        <div className='blog-single fade-up'>
                                            <div className="blog-single__inner">
                                                <Link to={`/media/${element?.page_data?.slug}`}>
                                                    <Img src={element?.images?.list?.[0]?.full_path} objectFit={'cover'} layout={'fill'} alt={'news'}/>
                                                    <div className="blog-single__inner__content">
                                                        <div className="blog-single__inner__content__top">
                                                            <p>{element?.page_data?.description}</p>
                                                            <h5>{element?.page_data?.title}</h5>

                                                        </div>
                                                        <div className="blog-single__inner__content__bottom">
                                                            <div>
                                                                <h2>{element?.page_data?.subtitle}</h2>
                                                                <p>{element?.page_data?.short_desc}</p>
                                                            </div>
                                                            <div>
                                                                <p>{element?.page_data?.type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                )

                            }) : <Col className={'no_data'}><p>No data found</p></Col>
                    }

                </Row>

                <div className="clearfix"></div>
                <Row>
                    <Col md={12} className={'text-center'}>
                        {data?.length<= 6 ? '': (
                            <div className={'laodmorehide'} onClick={loadMore}>
                                <Button margin={'20px 0 0'}  background={'#D05110'} hoverBackground={'#3C4348'} text={'Load More'}/>

                            </div>
                        )}

                    </Col>
                </Row>
            </Container>

        </StyeldSection>

    )

};

const StyeldSection = styled.section`
  background: #F9F9F9;
  overflow: hidden;
  //padding-top: 80px;
  .laodmorehide.hide{
    display: none;
  }
  @media(min-width: 1500px){
    .container{
    }
  }
  .single_item_news{
    margin-bottom: 40px;
    .blog-single__inner {
      padding-top: calc(460 / 370 * 100%);
      position: relative;
      img{
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

      &__content {
        &:after {
          content: '';
          height: 100%;
          width: 100%;
          background: rgba(60,67,72,1);
          top: 0;
          left: 0;
          right: 0;
          position: absolute;
          transition: height .4s ease;
        }

        &__top {
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #ffffff;
            position: absolute;
            left: 40px;
            bottom: 40px;
            z-index: 2;
            right: 40px;
            margin: 0;
            transform: translateY(-30px);
            opacity: 0;
            transition: all .6s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          h5 {
            position: absolute;
            bottom: 40px;
            left: 40px;
            right: 40px;
            z-index: 2;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            color: #FFFFFF;
            margin: 0;
            transition: all .3s ease;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          top: 40px;
          left: 40px;
          right: 40px;
          display: flex;
          justify-content: space-between;
          z-index: 2;

          h2 {
            font-size: 60px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 48px;
            transition: color .3s ease;
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            color: #FFFFFF;
            text-align: left;
            font-weight: 400;
            line-height: 24px;
            transition: color .3s ease;

            span {
              display: block;
              color: #F0EDE3;
            }
          }
        }
      }

      &:hover {
        .blog-single__inner__content:after {
          height: 0;
        }
        .blog-single__inner__content__top {
          h5 {
            opacity: 0;
            transform: translateY(-20px);
          }

          p {
            transform: none;
            opacity: 1;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: #FFF;

          h2 {
            color: #FFFFFF;
          }

          p {
            color: #F0EDE3;

            span {
              color: #F0EDE3;
            }
          }
        }
      }

    }



    @media (max-width: 767px) {
      .blog-single__inner__content__top {
        p, h2 {
          left: 30px;
          right: 30px;
          bottom: 30px;
        }
      }

      .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
        left: 30px;
        right: 30px;
        top: 30px;
      }

      .swiper-initialized {
        margin-left: 0;
        margin-right: 0;
      }

      .blog-slider {
        &__slider-wrap {
          margin-left: 15px;
          margin-right: 15px;

          .slider-nav-mobile {
            margin-top: 40px;

            ul {
              display: flex;
            }

            li {
              height: 50px;
              width: 50px;
              background-color: #000;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;

              &:nth-of-type(1) {
                margin-right: 20px;
              }

              svg {
                color: #ffffff;
                z-index: 2;
              }
            }
          }
        }
      }

      .blog-button {
        margin-bottom: 40px;

        .slider-nav {
          display: none;
        }
      }

      .slider-nav-mobile {
        display: block;
      }

    }
  }
  .no_data{
    p{
      margin-left: 10px;
    }
  }
  .text-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner-menu {
    padding: 100px 0 60px ;
    @media(max-width: 767px){

    }
    ul {
      display: flex;
      //justify-content: center;
      @media(max-width: 767px){
        display: none;
      }

      li {
        &:not(:nth-last-of-type(1)) {
          margin-right: 30px;
        }

        &.active{
          background-color: #ED5333;
          color: #ffffff;
          border-radius: 50px;
          a{
            color: #ffffff;
          }
        }
        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #3C4348;
          border: 1px solid #D05110;
          padding: 0 35px;
          border-radius: 50px;
          height: 45px;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease !important;



          &:hover {
            background:#ED5333;
            color: #F0EDE3 !important;
          }
          //
          //&.active {
          //  &:after {
          //    width: 100%;
          //  }
          //}
        }
      }
    }
  }

  @media (max-width: 767px) {

    .inner-menu {
      padding-top: 60px;
      padding-bottom: 40px;
      ul {
        padding-left: 15px;
        padding-right: 15px;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: calc(50% - 15px);

          margin: 0 0 25px !important;
          &:not(:nth-of-type(1)) {
            //display: none;
          }

          a {
            width: 100%;
            padding: 0 15px;
            &:after {
              display: none;
            }

            &:nth-of-type(1):after {
              // content: '';
              // border-radius: 50%;
              // background-position: center;
              // height: 24px;
              // width: 24px;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // background-color: transparent;
              // background-repeat: no-repeat !important;
              // position: absolute;
              // right: 0;
              // left: auto;
              // top: 0;
              // bottom: 0;
              // margin: auto;
            }
          }
        }
      }
    }
  }

  .mobile_select{
    display: none;
    @media(max-width: 767px){
      display: block;
    }
  }

  .css-13cymwt-control{
    border-radius: 23px;
    border: 1px solid ${hover} !important;
    &:hover{
      border: 1px solid ${hover} !important;
    }
  }
  .css-1u9des2-indicatorSeparator{
    background-color: transparent;
  }
  .css-1dimb5e-singleValue {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #3C4348 !important;
  }
  .css-1jqq78o-placeholder{
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #3C4348 !important;
    padding-left: 36px;
  }
  
  
  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #3C4348 !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 2px !important;
    text-transform: capitalize !important;
  }
  .filter__single-value.css-1dimb5e-singleValue{
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 700;
    padding-left: 30px;
  }

`;


export default React.memo(NewsList);

