import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <Container className={"scontainer"}>
                <Row>
                    <Col>
                        <h3>{data?.section_data?.description}</h3>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #f5f5f5;
  padding-top: 120px;
  padding-bottom: 120px;
  
  h3{
    font-family: 'Ubuntu', Arial, Helvetica, freesans, sans-serif;
    font-size: 40px !important;
    line-height: 48px !important;
  }
  @media(max-width: 767px){
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export default React.memo(MyComponent);
