import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Accordion, Col, Container, Row} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import {connect} from 'react-redux'
import {gsap, TimelineLite} from "gsap/gsap-core";
import {CSSPlugin} from 'gsap/CSSPlugin'
import {hover} from "../styles/globalStyleVars";

const Menu = ({logo, color, img, setSectionId}) => {
    let [menuItems, setMenuItems] = useState('false');
    gsap.registerPlugin(CSSPlugin);
    const [tl] = useState(new TimelineLite());

    let handleMenuToggle = (e) => {
        e.stopPropagation();
        setMenuItems('toggled');
    };

    let handleClose = () => {
        setMenuItems('');
    };

    let {pathname} = useLocation();

    const hamburgerRef = useRef(null)
    const menuRef = useRef(null)
    const mobileMenuRaf = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 100) {
                // setNewClass('menu-fixed')
                document.querySelector(".menu-bar").classList.add('menu-fixed')
            } else {
                // setNewClass('menu')
                document.querySelector(".menu-bar").classList.remove('menu-fixed')
            }
        });

        // menu click on mobile
        hamburgerRef.current.addEventListener('click', () => {

            if (!mobileMenuRaf.current.classList.contains('menu-open')) {
                mobileMenuRaf.current.classList.add('menu-open');
                hamburgerRef.current.classList.add('menu-open');
                setTimeout(() => {
                    menuRef.current.style.cssText = `opacity:1`;
                }, 100)
                document.querySelector('.menu-bar').classList.add('menu-open')
            } else {
                menuRef.current.style.cssText = `opacity:0`;
                hamburgerRef.current.classList.remove('menu-open');
                setTimeout(() => {
                    mobileMenuRaf.current.classList.remove('menu-open')
                }, 1000)
                document.querySelector('.menu-bar').classList.remove('menu-open')
            }
        })

        let getLinkTag = document.querySelectorAll('.mobile-menu__items__ul li a');

        for (let i of getLinkTag) {
            i.addEventListener('click', () => {
                menuRef.current.style.cssText = `opacity:0`;
                hamburgerRef.current.classList.remove('menu-open');
                setTimeout(() => {
                    mobileMenuRaf.current.classList.remove('menu-open')
                }, 1000)
            })
        }

        // sub menu

        if (window.screen.width < 769) {
            let menuSub = document.querySelectorAll('.has-sub');
            let menuOpen = document.querySelector('.menu-down');
            let menuOpenInner = document.querySelector('.menu-down ul');

            for (let i of menuSub) {

                i.addEventListener('click', () => {

                    if (!i.classList.contains('menu-down')) {
                        let getLiLength = i.querySelectorAll('ul li').length * i.querySelector('ul li').offsetHeight - 20;
                        let ulSelect = i.querySelector('ul');
                        tl.to(ulSelect, {
                            duration: 0,
                            visibility: 'visible'
                        });

                        tl.to(ulSelect, {
                            duration: .4,
                            opacity: '1',
                            height: getLiLength,
                            marginTop: '30px'
                        });
                        i.classList.add('menu-down');
                    } else {
                        let hasMenuDown = i.querySelector('ul');
                        tl.to(hasMenuDown, {
                            duration: .4,
                            height: 0,
                            opacity: 0,
                            marginTop: '0px',
                            visibility: 'hidden'
                        });

                        setTimeout(() => {
                            i.classList.remove('menu-down')
                        }, 500)
                    }

                })

            }
        }


    }, [mobileMenuRaf]);

    return (
        <StyledMenu className='menu-bar'>
            <Container>
                <Row className='desktop-menu'>
                    <Col sm={3} className='align-items-center d-flex'>
                        <Link to={'/'}><img src={'/images/static/logo.svg'} alt="mainLogo"/></Link>
                    </Col>

                    <Col sm={9}>
                        <ul className=''>
                            <li className={pathname === '/' ? 'active' : ''}><Link to={'/'}>Home</Link></li>
                            <li className='has-sub'>About
                                <span><img src={'/images/static/caret-down.svg'} alt={'caret'}/></span>
                                <ul>
                                    <Link to={'/about-us'}><li className={pathname === '/about-us' ? 'active' : ''}>About Us</li></Link>
                                    <Link to={'/about/csr'}><li className={pathname === '/about/csr' ? 'active' : ''}>CSR</li></Link>
                                    <Link to={'/about/leaders'}><li className={pathname === '/about/leaders' ? 'active' : ''}>Leaders</li></Link>
                                </ul>
                            </li>
                            <li className='has-sub'>Projects
                                <span><img src={'/images/static/caret-down.svg'} alt={'caret'}/></span>
                                <ul>
                                    <Link
                                        to={'/projects?status=upcoming'}><li className={pathname === '/projects?status=upcoming' ? 'active' : ''}>Upcoming</li></Link>
                                    <Link
                                        to={'/projects?status=ongoing'}><li className={pathname === '/projects?status=ongoing' ? 'active' : ''}>Ongoing</li></Link>
                                    <Link
                                        to={'/projects?status=completed'}><li className={pathname === '/projects?status=completed' ? 'active' : ''}>Completed</li></Link>

                                </ul>
                            </li>
                            <li className={pathname === '/media' ? 'active' : ''}><Link
                                to={'/media'}>Media</Link></li>
                            <li className={pathname === '/career' ? 'active' : ''}><Link to={'/career'}>Career</Link></li>
                            <li className='has-sub'>Contact
                                <span><img src={'/images/static/caret-down.svg'} alt={'caret'}/></span>
                                <ul>
                                    <Link
                                        to={'/landowner'}><li className={pathname === '/landowner' ? 'active' : ''}>Landowner</li></Link>
                                    <Link
                                        to={'/buyer'}><li className={pathname === '/buyer' ? 'active' : ''}>Buyer</li></Link>
                                    <Link
                                        to={'/contact'}><li className={pathname === '/contact' ? 'active' : ''}>Contact</li></Link>

                                </ul>
                            </li>
                        </ul>
                    </Col>

                </Row>


                <Row className="mobile-menu" ref={mobileMenuRaf}>
                    <Col className='logo'>
                        <Link to={'/'}><img src={'/images/static/logo.svg'} alt="mainLogo"/></Link>
                    </Col>

                    <Col className='hamburger' ref={hamburgerRef}>
                        <img src={'/images/static/mobile-menu.svg'} alt="mobile-menu"/>
                        <img src={'/images/static/hamburger-close.svg'} alt="hamburger-close"/>
                    </Col>

                    <div className="mobile-menu__items" ref={menuRef}>
                        <ul className='mobile-menu__items__ul'>
                            <li><Link to={'/'}>Home</Link></li>

                            <Accordion >
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>About Us <img src={'/images/static/cicle-caret.svg'} alt={'caret'}/></Accordion.Header>
                                    <Accordion.Body>

                                        <ul>
                                            <li><Link to={'/about-us'}>About</Link></li>
                                            <li><Link to={'/about/csr'}>CSR</Link></li>
                                            <li><Link to={'about/leaders'}>Leaders</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Projects <img src={'/images/static/cicle-caret.svg'} alt={'caret'}/></Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to={'/projects?status=upcoming'}>Upcoming</Link></li>
                                            <li><Link to={'/projects?status=ongoing'}>Ongoing</Link></li>
                                            <li><Link to={'/projects?status=completed'}>Completed</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <li><Link to={'/media'}>Media</Link></li>

                                <li><Link to={'/career'}>Career</Link></li>


                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Contact <img src={'/images/static/cicle-caret.svg'} alt={'caret'}/></Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to={'/landowner'}>Landowner</Link></li>
                                            <li><Link to={'/buyer'}>Buyer</Link></li>
                                            <li><Link to={'/contact'}>Contact</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>


                            </Accordion>

                        </ul>
                    </div>

                </Row>

            </Container>
        </StyledMenu>
    )
};

const StyledMenu = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 1000;
  background-color: rgba(60, 67, 72, 0.35);
  display: flex;
  align-items: center;
  transition: background .4s ease;

  img {
    height: 70px;
  }

  .desktop-menu {
    ul {
      display: flex;
      justify-content: flex-end;

      li {
        display: flex;
        height: 80px;
        align-items: center;
        color: #ffffff;
        font-size: 15px;
        margin-right: 30px;
        cursor: pointer;

        &:hover {
          color: #FFFFFF !important;
        }

        a {
          display: flex;
          height: 80px;
          align-items: center;
          color: #ffffff;
          font-size: 15px;

          &:hover {
            color: #FFFFFF !important;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        &.has-sub {
          position: relative;
          
          ul{
            a{
              &:hover{
                color: white !important; 
              }
            }
          }

          &:hover{
            color: white !important;
            span{
              img{
                transform: rotate(180deg);
              }
            }
          }
          span {
            margin-left: 10px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 1px solid #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
              content: '';
              height: 0;
              width: 0;
              background-color: #D05110;
              position: absolute;
              border-radius: 50%;
              transition: all .1s ease;
            }

            img {
              height: 4px;
              z-index: 3;
              transform: rotate(0deg);
              transition: transform 0.3s ease;

            }
          }

          ul {
            opacity: 0;
            position: absolute;
            visibility: hidden;
            top: 84px;
            min-width: 200px;
            background-color: #FFFFFF;
            //padding: 30px;
            justify-content: start;
            transition: all .6s ease;
            display: flex;
            flex-wrap: wrap;
            //box-shadow: 0px 6px 13px rgba(255, 255, 255, 0.05);
            box-shadow: 0 6px 30px rgb(0 0 0 / 24%);
            margin-top: -15px;
            a{
              &:last-child{
                li{
                  border-bottom: 1px solid transparent;
                }
              }
              &:hover{
                color: #FFFFFF !important;
              }
            }
            a {
              height: auto;
              margin: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              color: black;
              padding-top: 5px;
              padding-bottom: 5px;

              li{
                border-bottom: 1px solid rgba(112, 112, 112, 0.1);
                &:hover{
                  color: #FFFFFF !important;

                }
              }
              a{
                &:hover{
                  color: #FFFFFF !important;

                }
              }
              &:hover {
                color:#FFFFFF !important;
                a {
                  color: #FFFFFF !important;
                }
                background-color: ${hover};
              }

              a {
                height: auto;
                font-size: 16px;
                line-height: 24px;
                padding: 10px 0 10px 0;
                white-space: nowrap;
                color: #3C4348;

                &:hover {
                  color: #FFFFFF !important;
                }
              }

              &:nth-last-child(1) a {
                margin-bottom: 0;
              }
            }
            li{
              height: auto;
              margin: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              color: black;
              padding-top: 5px;
              padding-bottom: 5px;

              li{
                border-bottom: 1px solid rgba(112, 112, 112, 0.1);
                &:hover{
                  color: #FFFFFF !important;

                }
              }
              a{
                &:hover{
                  color: #FFFFFF !important;

                }
              }
              &:hover {
                color:#FFFFFF !important;
                a {
                  color: #FFFFFF !important;
                }
                background-color: ${hover};
              }

              a {
                height: auto;
                font-size: 16px;
                line-height: 24px;
                padding: 10px 0 10px 0;
                white-space: nowrap;
                color: #3C4348;

                &:hover {
                  color: #FFFFFF !important;
                }
              }

              &:nth-last-child(1) a {
                margin-bottom: 0;
              }
            }

          }


        }


        &:hover {
          span {
            border-color: #D05110;

            &:after {
              height: 100%;
              width: 100%;
            }
          }

          ul {
            opacity: 1;
            visibility: visible;
          }
        }

        &:nth-last-child(1) {
          ul {
            left: -109px;

            &:after {
              right: 50px;
            }
          }
        }
      }

    }
  }

  .mobile-menu {
    display: none;

    .logo img {
      height: 40px;
    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;

      img {
        position: absolute;
        right: 15px;
        height: 25px;

        &:nth-of-type(1) {

        }

        &:nth-of-type(2) {
          display: none;
        }
      }

      &.menu-open img {
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
          right: 20px;
        }
      }
    }

    &__items {
      display: none;
      position: absolute;
      opacity: 0;
      background-color: #3C4348;
      height: 100vh;
      top: 70px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 60px 15px 60px 15px;
      overflow: auto;
      transition: opacity .5s ease;

      ul {
        li {
          padding-bottom: 16px;
          border-bottom: 1px solid #FFF;
          margin-bottom: 30px;
          font-size: 15px;
          text-transform: capitalize;

          a {
            color: #ffffff;
          }
        }
      }
    }

    &.menu-open {

      .mobile-menu__items {
        display: block;
      }

    }

    //accordion
    .accordion-header {
      .accordion-button {
        background-color: transparent;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid #CFCDCC;

        img {
          height: 20px;
        }

        &.collapsed {
          border-bottom: 1px solid #FFF;
        }
      }


    }

    .accordion-body {
      margin-bottom: 50px;

      ul {
        li {
          border: none;
          margin-bottom: 22px;
          padding-bottom: 0;
          &:hover{
            color: #ffffff !important;
          }

          &:after {
            display: none;
          }
          a{
            &:hover{
              color: #ffffff !important;
            }
          }
        }
      }
    }


  }
  

  &.menu-fixed{
    background-color: rgba(60, 67, 72, 0.35);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
  } 
  &.menu-open {
    background-color: #3C4348;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.24);
    
  }

  .active a {
    //color: #FFFFFF !important;
  }


  @media (max-width: 769px) {
    background-color: rgba(60, 67, 72, 0.35);
    //border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    height: 70px;
    overflow: visible;
    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      display: flex;
    }

    &:after {
      content: '';
      position: absolute;
      right: 97px;
      height: calc(100%);
      width: 1px;
      //background-color: rgba(255, 255, 255, 0.77);
      top: 0px;
    }

  }

  @media (max-width: 767px) {
    &:after {
      right: 65px;
    }
  }
  .desktop-menu ul li.has-sub ul a{
    &:hover{
      color: #ffffff !important;
    }
  }

  //.desktop-menu ul li.has-sub ul a li:hover

`;



export default Menu;
