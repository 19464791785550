import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({des, designation, img, name,data,textOne,textTwo,reverse}) => {
    return (
        <StyledComponent className={'parallax-img horizontal-scroll pt-100 pb-200'} data-speed-right={30} data-speed-left={-30} reverse={reverse}>
            <Container className="profile">
                <Row>
                    {
                        reverse?
                            <>
                                <p className={'scroll-right bg-text-one'}>{data?.images?.[0]?.short_title}</p>
                                <p className={'scroll-left bg-text-two'}>{data?.images?.[0]?.short_desc}</p>
                                <Col md={6} className={'profile-left'}>
                                    {
                                        data?.images?.[0]?.full_path &&
                                        <div className="profile__image ">
                                            <Img src={data?.images?.[0]?.full_path} alt={'chairman message'}/>
                                        </div>
                                    }

                                </Col>
                                <Col md={6} className="profile__body">
                                    <div className={'profile__body__inner'}>
                                        <div className="profile__body__name">
                                            {
                                                data?.data?.subtitle &&
                                                <h4 className={'split-up'}>{data?.data?.subtitle}</h4>
                                            }
                                            {
                                                data?.data?.short_desc &&
                                                <p className={'split-up'}>{data?.data?.short_desc}</p>
                                            }
                                        </div>
                                        {
                                            data?.data?.description &&
                                            <>
                                                {ReactHtmlParser(data?.data?.description)}
                                            </>
                                        }

                                    </div>
                                </Col>

                            </> :
                            <>
                                <p className={'scroll-right bg-text-one'}>{data?.images?.[0]?.short_title}</p>
                                <p className={'scroll-left bg-text-two'}>{data?.images?.[0]?.short_desc}</p>

                                <Col md={6} className="profile__body">
                                    <div className={'profile__body__inner'}>
                                        <div className="profile__body__name">
                                            {
                                                data?.data?.subtitle &&
                                                <h4 className={'split-up'}>{data?.data?.subtitle}</h4>
                                            }
                                            {
                                                data?.data?.short_desc &&
                                                <p className={'split-up'}>{data?.data?.short_desc}</p>
                                            }
                                        </div>
                                        {
                                            data?.data?.description &&
                                            <>
                                                {ReactHtmlParser(data?.data?.description)}
                                            </>
                                        }

                                    </div>
                                </Col>
                                <Col md={6} className={'profile-left'}>
                                    {
                                        data?.images?.[0]?.full_path &&
                                        <div className="profile__image ">
                                            <Img src={data?.images?.[0]?.full_path}  alt={'chairman message'}/>
                                        </div>
                                    }

                                </Col>
                            </>
                    }

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  background-color: ${props => props.reverse ? '#F5F5F5' : '#FFFDFC'}  ;
  position: relative;
  .profile{
    
    &__body{
      &__name{
        padding-bottom: 60px;
        @media(max-width:767px){
          margin-top: 60px !important;
        }
        h4{
          font-size: 24px;
          line-height: 32px;
        }
      }
      &__inner{
        padding-bottom: 40px;
        padding-top: 60px;

      }
    }
    &__image{
      position: relative;
      padding-top: calc(550 / 570 * 100%);
    }
  }
  .profile-left{
    //padding-right: 70px;
  }
  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    .row{
      
      //flex-direction: row-reverse !important;
      flex-direction: ${props => props.reverse ? 'row-reverse' : 'column-reverse'}   !important;
    }
  }


  @media(max-width: 767px){
    .profile-left{
      padding-right: 15px;
    }
    .profile{
      padding-bottom: unset;
      &__body{
        padding-bottom: unset;
        &__name{
          padding-bottom: 40px;
        }
      }
    }
  }
  .bg-text-one, .bg-text-two {
    font-size: 200px;
    line-height: 286px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -15%;
    top: 45%;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -15%;
    bottom: 0;
  }

`;

export default React.memo(MyComponent);
