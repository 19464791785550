import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }



  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-MediumItalic.woff2') format('woff2'),
    url('fonts/Ubuntu-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-Medium.woff2') format('woff2'),
    url('fonts/Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-Regular.woff2') format('woff2'),
    url('fonts/Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-Bold.woff2') format('woff2'),
    url('fonts/Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-BoldItalic.woff2') format('woff2'),
    url('fonts/Ubuntu-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-LightItalic.woff2') format('woff2'),
    url('fonts/Ubuntu-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-Italic.woff2') format('woff2'),
    url('fonts/Ubuntu-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Ubuntu';
    src: url('fonts/Ubuntu-Light.woff2') format('woff2'),
    url('fonts/Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Century';
    src: url('fonts/Century.woff2') format('woff2'),
    url('fonts/Century.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy-Heavy.woff2') format('woff2');
    url('fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy-Regular.woff2') format('woff2');
    url('fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('fonts/Gilroy-Bold.woff2') format('woff2');
    url('fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }




  /* @font-face {
    font-family: 'Graphik';
    src: url('fonts/Graphik-Bold.woff2') format('font-woff2'),
    url('fonts/Graphik-Bold.woff') format('font-woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  } */


  body {
    font-family: 'Ubuntu', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-moz-scrollbar {
      display: none;

    }

    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }
  
  
  
  p, a, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }

  h1, h2 {
      //font-family: ${title};
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(255,255,255, 1);
    -webkit-text-fill-color: #FFF;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }
  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  .climax-reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #3C4348;
    z-index: 999999999;
    //display: none;
    //opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 80px;
      opacity: 0;
    }
  }
  
  //Project Modal

  .project-modal{
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }
    .modal-body{
      padding-right: 0;
    }
    .modal-content{
      background: #3C4348;
      color: #FFFDFB;
      padding-bottom: 200px;
      height: 100vh;
    }
    .modal-title_project {
      display: flex;
      justify-content: space-between;
      margin-top: 75px;

      h5 {
        margin-bottom: 100px;
        color: #FFFDFB;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.8;
        text-transform: uppercase;
        //margin-bottom: 60px;
        //padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          //margin-bottom: 50px;
        }
      }
      h3{
        color:#FFFDFB;
        font-size: 40px;
        line-height: 32px;
        font-family: ${title};
        text-transform: uppercase;
        margin-bottom: 60px;
        @media(max-width: 767px){
          font-size: 24px !important;
          margin-bottom: 40px;
        }

      }
    }
    .modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      h5 {
        margin-bottom: 100px;
        color: #FFFDFB;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.8;
        text-transform: uppercase;
        //margin-bottom: 60px;
        //padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          //margin-bottom: 50px;
        }
      }
      h3{
        color:#FFFDFB;
        font-size: 40px;
        line-height: 32px;
        font-family: ${title};
        text-transform: uppercase;
        margin-bottom: 60px;
       

      }
    }
    .modal-data__content{
      position: relative;

    }
    .form-control{
      background-color: transparent;
      border:none;
      border-bottom: 1px solid #B2A89F;
      margin-bottom: 40px;
      color: #FFFDFB;
      padding-left: 0;
      padding-bottom: 20px;
      &::placeholder{
        color: #FFFDFB;
        opacity: 0.5;
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .form__phoneEmail{
      display: flex;
      gap: 20px;
      @media(max-width: 767px){
        flex-direction: column;
        input{
          width: 100% !important;
        }
      }
      input{
        width: 49%;
      }
    }
    

    .attachCvName{
      display: flex;
      gap: 40px;
      margin-bottom: 60px;

      .attach-cv{
        width: 222px;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 28px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        label{
          margin-bottom: 0;
          cursor: pointer;
        }
      }
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
    .file-name{
      font-size: 14px;
      opacity: 0.8;
      color: #FFFDFB;
      padding-left: 10px;
    }

    p{
      color: #FFFDFB;
      margin-bottom: 60px;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      //margin-top: 50px;
      //margin-bottom: 50px;
      //margin-top: 30px;
      svg {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }
        #Ellipse_85 {
          transition: 0.7s all ease;
        }
        #Ellipse_86 {
          transition: 0.7s all ease;
        }
        path{
          transition: 0.7s all ease;
        }
        &:hover {
            #Ellipse_86 {
              r: 0px;
              cx: 25px;
              cy: 25px;
            }
          path{
            stroke: #ffffff;
          }
        }

      }
    }
    .modal-data{
      justify-content: unset;
    }
  }



  //Drop-CV Modal

  .cv-modal{
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }
    .modal-body{
      padding-left: 0;
      padding-right: 0;
    }
    .modal-content{
      background: #3C4348;
      color: #FFFDFB;
      padding-bottom: 200px;
    }
    .modal-title {
      display: flex;
      justify-content: space-between;
      //align-items: center;
      margin-top: 20px;

      h5 {
        margin-bottom: 100px;
        color: #FFFDFB;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.8;
        text-transform: uppercase;
        //margin-bottom: 60px;
        //padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          //margin-bottom: 50px;
        }
      }
    }
    .modal-data__content{
      position: relative;
      
      }
      .form-control{
        background-color: transparent;
        border:none;
        border-bottom: 1px solid #B2A89F;
        margin-bottom: 40px;
        color: #FFFDFB;
        padding-left: 0;
        padding-bottom: 20px;
        &::placeholder{
          color: #FFFDFB;
          opacity: 0.5;
          font-family: Ubuntu;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .form__phoneEmail{
        display: flex;
        gap: 20px;
        @media(max-width: 767px){
          flex-direction: column;
          input{
            width: 100% !important;
          }
        }
        input{
          width: 49%;
        }
      }
      input[type="textarea"]{
        padding-bottom: 80px;
      }

      .attachCvName{
        display: flex;
        gap: 40px;
        margin-bottom: 60px;

        .attach-cv{
          width: 222px;
          cursor: pointer;
          border: 1px solid #fff;
          border-radius: 28px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          label{
            margin-bottom: 0;
            cursor: pointer;
          }
          @media(max-width: 767px){
            width: auto;
          }
        }
        @media(max-width: 767px){

          flex-direction: column;
        }
      }
      .file-name{
        font-size: 14px;
        opacity: 0.8;
        color: #FFFDFB;
        padding-left: 10px;
      }

      p{
        color: #FFFDFB;
        margin-bottom: 60px;
        @media(max-width: 767px){
          margin-bottom: 40px;
        }
      }
    
    .modal-close {
      display: flex;
      justify-content: flex-end;
      //margin-bottom: 50px;
      //margin-top: 30px;
      svg {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }
        #Ellipse_86 {
          transition: 0.7s all ease;
        }
        &:hover {
          svg {
            #Ellipse_86 {
              r: 25px;
              cx: 25px;
              fill: #FC671A ;
            }
          }
        }

      }
    }
    .modal-data{
      justify-content: unset;
    }
    @media(max-width: 767px){
      .cv-submit{
        display: flex !important;
        justify-content: center !important;
      }
    }
  }

//Job Modal
  .job-modal{
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }
    .modal-body{
      padding-right: 0;
      padding-left: 0;
    }
    .modal-content{
      background: #3C4348;
      color: #FFFDFB;
      padding-bottom: 200px;
    }
    .modal-title {
      display: flex;
      justify-content: space-between;
      //align-items: center;
      margin-top: 15px;

      h5 {
        margin-bottom: 60px;
        color: #FFFDFB;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.8;
        text-transform: uppercase;
        //margin-bottom: 60px;
        //padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          //margin-bottom: 50px;
        }
      }
    }
    h3{
      font-family: Century;
      margin-bottom: 60px;
    }
    .modal-data__content{
      position: relative;

    }
    .form-control{
      background-color: transparent;
      border:none;
      border-bottom: 1px solid #B2A89F;
      margin-bottom: 40px;
      color: #FFFDFB;
      padding-left: 0;
      padding-bottom: 20px;
      &::placeholder{
        color: #FFFDFB;
        opacity: 0.5;
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .form__phoneEmail{
      display: flex;
      gap: 20px;
      @media(max-width: 767px){
        flex-direction: column;
        input{
          width: 100% !important;
        }
      }
      input{
        width: 49%;
      }
    }
    input[type="textarea"]{
      padding-bottom: 80px;
    }

    .attachCvName{
      display: flex;
      gap: 40px;
      margin-bottom: 60px;

      .attach-cv{
        width: 222px;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 28px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        label{
          margin-bottom: 0;
          cursor: pointer;
        }
        @media(max-width: 767px){
          width: 100%;
        }
      }
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
    .dc-btn{
      @media(max-width: 767px){
        display: flex;
        justify-content: center;
      }
    }
    .file-name{
      font-size: 14px;
      opacity: 0.8;
      color: #FFFDFB;
      padding-left: 10px;
    }

    p{
      color: #FFFDFB;
      margin-bottom: 60px;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      //margin-bottom: 50px;
      //margin-top: 30px;
      svg {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        position: relative;
        cursor: pointer;
        @media (max-width: 767px) {
          height: 50px;
          width: 50px;
        }
        #Ellipse_86 {
          transition: 0.7s all ease;
        }
        &:hover {
          svg {
            #Ellipse_86 {
              r: 25px;
              cx: 25px;
              fill: #FC671A ;
            }
          }
        }

      }
    }
    .modal-data{
      justify-content: unset;
    }
  }


  .prevhover {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    span {
      z-index: 2;
    }
    svg{
      line{
        transition: all 0.5s ease 0s;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0px;
      margin: auto;
      background-color: #D05110;
      transition: all 0.5s ease 0s;
      border-radius: 50%;
      transform: translateX(110%);
    }

    &:hover {
      &:after {
        transform: translateY(0);
      }
      svg{
        line{
          stroke: #ffffff;
        }
      }
    }
  }

  .nexthover {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    span {
      z-index: 2;
    }
    svg{
      line{
        transition: all 0.5s ease 0s;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0px;
      margin: auto;
      background-color: #D05110;
      transition: all 0.5s ease 0s;
      border-radius: 50%;
      transform: translateX(-110%);
    }

    &:hover {
      &:after {
        transform: translateY(0);
      }
      svg{
        line{
          stroke: #ffffff;
        }
      }
    }
  }

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }
    .modal-dialog{
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;height: 100% !important;
    }
    .modal-body{
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }
  .filter{
    &__control{
      &--is-focused {
        background-color: transparent !important;
        border: 1px solid ${hover};
        outline: none;
        border-bottom: 1px solid transparent;
      }
      &--menu-is-open {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #FFF;
      }
    }
    &__single-value{
      color: #3C4348 !important;
      
    }

  }
  .css-1dimb5e-singleValue{
    color: white !important;
  }
  .css-t3ipsp-control{
    box-shadow: none !important;
    border-color: ${hover} !important;
    border-radius: 22px !important;
  }
  .css-t3ipsp-control:hover{
    border-color: ${hover} !important;
  }
  .css-13cymwt-control{
    background-color: transparent !important;
    &:hover{
      border-color: transparent !important;
      border-bottom: 1px solid #FFFFFF !important;
    }
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    
  }
  .form{
    .filter {
      &__placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 16px;
        font-weight: 300;
      }

      &__control {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 40px;

        &--is-focused {
          background-color: transparent !important;
          border: none;
          outline: none;
          border-bottom: 1px solid #FFFFFF;
        }
      }


      &__indicator {
        margin-right: 0;
        padding: 0;
      }

      &__value-container {
        padding-left: 0;
        padding-right: 0;
        cursor: pointer !important;
      }
    }

  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    &:focus{
      border-bottom: 1px solid #FFFFFF !important;
    }
    &:hover{
      border-bottom: 1px solid #FFFFFF !important;
    }
  }

  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify {
    z-index: 999999999;
  }
  
  .bg-text-one, .bg-text-two{
    font-family: 'Gilroy-Regular', Arial, Helvetica, freesans, sans-serif !important;;
  }

  .modal-close {
    display: flex;
    justify-content: flex-end;
    //margin-top: 50px;
    //margin-bottom: 50px;
    //margin-top: 30px;
    svg {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      position: relative;
      cursor: pointer;
      @media (max-width: 767px) {
        height: 50px;
        width: 50px;
      }
      #Ellipse_85 {
        transition: 0.7s all ease;
      }
      #Ellipse_86 {
        transition: 0.7s all ease;
      }
      path{
        transition: 0.7s all ease;
      }
      &:hover {
        #Ellipse_86 {
          r: 0px;
          cx: 25px;
          cy: 25px;
        }
        path{
          stroke: #ffffff;
        }
      }

    }
  }
  .scroll-up .menu-bar {
    //background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #736640;

      &:hover {
      }

      &:after, &:before {
        box-shadow: none;
      }
    }
  }
  //.remove-bg{
  //  display: none;
  //}

`;



