import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { Row, Col} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    // const [offset, setOffset] = useState(100);
    //
    //
    // useEffect(() => {
    //     if (window.innerWidth > 767) {
    //         setOffset(document.querySelector('.container').offsetLeft)
    //     }
    //     window.addEventListener('resize', () => {
    //         if (window.innerWidth > 767) {
    //             setOffset(document.querySelector('.container').offsetLeft)
    //         }
    //     })
    // }, [window.innerWidth])
    const [offset, setOffset] = useState(100);

    useEffect(() => {
        function updateOffset() {
            const container = document.querySelector('.scontainer');
            if (container) {
                const containerOffset = container.offsetLeft;
                if (window.innerWidth > 767) {
                    setOffset(containerOffset);
                } else {
                    setOffset(100); // Reset to the initial offset when window size is <= 767
                }
            }
        }

        window.addEventListener('resize', updateOffset);

        updateOffset(); // Initial offset update

        return () => {
            window.removeEventListener('resize', updateOffset);
        };
    }, []);






    return (
        <StyledComponent className={'ministry '} offset={offset}>
            <div className={'ministry__container'}  style={{paddingLeft: offset + 30 + 'px'}}>
                <Row className={'identity_row'}>
                    <Col md={{span:4,offset:1}} className={'identity-left'}>
                        <img src={'/images/static/about-logo.png'} alt="about-logo"/>
                         <h3 className={'split-up'}>{data?.section_data?.subtitle}</h3>
                    </Col>
                    <Col md={{span: 6, offset: 1}}>
                        <ul className={'customs-list'} style={{paddingRight: offset + 15 + 'px'}}>
                            {
                                data?.posts?.list && data?.posts?.list?.length>0 &&
                                data?.posts?.list?.map((element)=>{
                                    return(
                                        <li className={'custom-list__single'}>
                                            <div className="custom-list__logo">
                                                <img src={element?.images?.[0]?.full_path} alt={'identity-image'} />

                                            </div>
                                            <div className="custom-list__desc">
                                                <h4 className={'split-up'}>{element?.data?.title}</h4>
                                                {
                                                    element?.data?.description ?
                                                        <p className={''}>{element?.data?.description}</p>:''

                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #F6F6F6;
  @media(max-width: 767px){
    margin-top: 0;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    top: 0;
    background-color: #3C4348;
  }


  .ministry__container {
    position: relative;
    z-index: 2;
    .identity-left{
      h3{
        font-size: 40px;
        line-height: 48px;
        font-family: Century;
      }
    }

    .customs-list {
      //padding: 0;
      //counter-reset: listItem;
    @media(max-width: 767px){
        padding-right: 0!important;
    }

      li {
        display: flex;
        //align-items: center;
        gap: 40px;
        position: relative;
        //padding-left: 62px;
        .custom-list__logo{
          padding-top:10px;
          width: 50px;
        }
        .custom-list__desc{
          h4{
            font-size: 24px;
            line-height: 32px;
            font-family: Ubuntu;
            margin-bottom: 20px !important;
            
          }
          p{
            font-family: Ubuntu;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #C1C1C1;
          padding-bottom: 40px;
        }

        &:not(:first-child) {
          padding-top: 40px;

        }
        

        &:first-child {
          &:before {
            top: 15px !important;
          }
        }
      }

    }

    .row {
      background-color: #FFFFFF;
      padding: 120px 0;
    }
  }

  img {
    //height: 100px;
  }

  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }

  }

  h3 {
    font-size: 40px;
    font-weight: 500;
    color: #404040;
    line-height: 48px;
    margin-top: 40px;
    font-family: Ubuntu;

  }


  @media (max-width: 767px) {
    h3 {
      font-size: 32px;
      line-height: 40px;
      margin-top: 20px;
      margin-bottom: 60px;

    }
    
    .ministry__container {
      padding-left: 0 !important;

      .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .col-sm-2, .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
