import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {hover} from '../../styles/globalStyleVars';
import 'react-modal-video/css/modal-video.min.css'
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";


const FloorPlan = ({data, padding}) => {



    // navigation
    const prev = () => {
        document.querySelector('.floor-plan .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.floor-plan .swiper-button-next').click();
    };


    return (

        <StyledFloorPlan className={`floor-plan ${padding ? padding : 'pt-200 pb-200'}`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="floor-plan__head">
                            {
                                data?.data?.subtitle &&
                                <Title text={data?.data?.subtitle}/>

                            }
                            {
                                data?.images?.length>3 ?
                                <div className="navigation">
                                    <ul>
                                        <li className={'prevhover slider-prev'} onClick={prev}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>

                                        </li>
                                        <li className={'nexthover slider-next'} onClick={next}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>

                                        </li>
                                    </ul>
                                </div> : ''

                            }



                        </div>
                        <div className="floor-plan__content">
                            <LightgalleryProvider>
                                {
                                    data?.images && data?.images?.length>0 &&
                                    <Swiper
                                        modules={[Autoplay, Pagination, Navigation]}
                                        slidesPerView={3}
                                        spaceBetween={0}
                                        loop={true}
                                        speed='1000'
                                        grabCursor={true}
                                        watchSlidesProgress={true}
                                        mousewheelControl={true}
                                        keyboardControl={true}
                                        navigation
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }}

                                        className="mySwiper"
                                    >

                                        {
                                            data?.images && data?.images?.length>0 &&
                                            data?.images?.map((element)=>{
                                                return(
                                                    <SwiperSlide key={element?.id}>
                                                        <div className="slide-inner">
                                                            <LightgalleryItem group="any" src={element?.full_path}>
                                                                <div className="slide-inner__img">
                                                                    <Img src={element?.full_path} alt={'floor plan'}/>
                                                                </div>
                                                            </LightgalleryItem>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                }

                            </LightgalleryProvider>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledFloorPlan>
    );
};
const StyledFloorPlan = styled.section`
  background-color: #F5F5F5;
  overflow: hidden;
  
  .slider-prev{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #323232;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      transform: rotate(180deg);
      z-index: 5;
    }
  }
  .slider-next{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #323232;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      z-index: 5;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
  
  .slide-inner{
    .global-image{
      // border: 1px solid ${hover};
    }
  }

  .floor-plan {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
    }

    &__content {
      .slide-inner {
        &__img {
          padding-top: calc(200 / 370 * 100%);
          position: relative;
          cursor: pointer;
        }
      }
    }
  }

  .navigation {


    ul {
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        svg {
          path {
            transition: all 0.5s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        &:hover {
          svg {
            path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }


  @media (min-width: 1550px) {
    .floor-plan {
      &__head {
        .title {
          h2 {
            font-size: 45px;
            line-height: 55px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .floor-plan {
      &__head {
        .title {
          margin-bottom: 0 !important;
        }
      }
    }
  }


`;

export default React.memo(FloorPlan);

