import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import LandingView from "./LandingView";
import {useLocation} from "react-router-dom";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const OpeningModal = () => {
    const [showModal, setShowModal] = useState(true);

    const handleReject = () => {
        const modal = document.querySelector('.opening-modal');
        const mainDiv = document.querySelector('#opening-modal');

        if (modal) {
            modal.classList.add('hide');
            modal.style.opacity = '0';
            mainDiv.style.opacity = '0';
            setTimeout(() => {
                modal.style.display = 'none';
                mainDiv.style.display = 'none';
            }, 1000)
            disableBodyScroll(this?.targetElement);

        }

        const homeBg = document.querySelector('.remove-bg');
        if(homeBg){
            document.querySelector('.remove-bg')?.classList.remove('remove-bg')
        }
    };


    if (!showModal) {
        // return null; // If the cookie has been accepted or modal is hidden, return null to hide the modal
    }
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') { // Replace '/' with the actual path of the home page
            window.onload = function() {
                setTimeout(() => {
                    const modal = document.querySelector('.opening-modal');
                    modal.style.transform = 'translateY(0px)';
                    const mainDiv = document.querySelector('#opening-modal');
                    mainDiv.style.opacity = '1';
                }, 10);
            };
        }
        else {
            // Reset modal properties here to prevent modal animation
            const modal = document.querySelector('.opening-modal');
            modal.style.transform = 'translateY(-100%)';
            const mainDiv = document.querySelector('#opening-modal');
            mainDiv.style.display = 'none';
        }
    }, [location]);


    return (
        <StyleCookie id={'opening-modal'}>
            <Container fluid>
                <Row>
                    <Col className={'custom-width'} md={12}>
                        <div  className="opening-modal">
                            <div className="opening-modal-content">
                                <div className="text-right">
                                    <div id={''} onClick={handleReject} className="close-modal">
                                        <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                                <circle cx="22" cy="22" r="22" stroke="none"/>
                                                <circle cx="22" cy="22" r="21.5" fill="none"/>
                                            </g>
                                            <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" opacity={'0'} stroke-dasharray="0 142">
                                                <circle cx="22" cy="22" r="22" stroke="none"/>
                                                <circle cx="22" cy="22" r="21.5" fill="none"/>
                                            </g>
                                            <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                                <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                                <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            </g>
                                        </svg>


                                    </div>
                                </div>
                                <LandingView close={handleReject}/>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyleCookie>
    );
};

const StyleCookie = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 1.5s all cubic-bezier(.25, .74, .22, .99);

  .container-fluid {
    height: 100%;

    .row {
      height: 100%;
    .col-md-12{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
      .custom-width {
        margin: auto;
        overflow: hidden;
        
      }
    }
  }

  .opening-modal {
    background-color: #fff;
    //padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    transition: 1.5s all cubic-bezier(.25, .74, .22, .99);
    margin: auto;
    overflow: hidden;
    transform: translateY(100%);
    .text-right {
      display: block;
    }

    .close-modal {
      right: 0px;
      position: relative !important;
      text-align: right;
      margin-bottom: 30px;
      cursor: pointer;
      display: none;

      svg {
        #Ellipse_19 {
          transition: 1s all cubic-bezier(.25, .74, .22, .99);
        }
      }

      &:hover {
        svg {
          #Ellipse_19 {
            opacity: 1;
            stroke-dasharray: 142px;
          }
        }
      }
    }

    h3 {
      line-height: 36px;
      font-size: 28px;
      font-weight: 300;
      color: #3C3C3B;
      margin-bottom: 15px;
    }

    .image-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: calc(380 / 640 * 100%);
      margin-bottom: 20px;

      .stripe {
        transform: translateX(100%);
        transition: all 1s cubic-bezier(.25, .74, .22, .99);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        background: rgba(151, 140, 33, 0.7);
        padding: 20px;
        @media (max-width: 767px) {
          position: relative;
          bottom: 0;
        }

        h6 {
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
          transform: translateX(100%);
          transition: all 1s cubic-bezier(.25, .74, .22, .99);
          font-family: "Avenir Heavy";
        }
      }
    }


    &.hide {
      transform: translateY(-150%) !important;
    }
  }


`;


export default OpeningModal;
