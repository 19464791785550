import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
// import { htmlToReactParser } from 'html-react-parser';


const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'overview'}>
            <div className="back_img">
                <img src={data?.images?.list?.[0]?.full_path ? "/images/static/building.svg":data?.images?.list?.[0]?.full_path} alt="About Overview"/>
            </div>
            <Container>
                <Row>
                    <Col md={9} className={'overview__title'}>
                        <h3 className={'split-up'}>{data?.section_data?.short_desc}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:6,offset:6}} className={'overview__description'}>
                        {
                            data?.section_data?.description &&
                                reactHtmlParser(data?.section_data?.description)

                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    padding-top: 100px;
  padding-bottom: 300px;
  @media(max-width: 767px){
    padding-bottom: 120px;
  }
  position: relative;
  .back_img{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 60%;
    opacity: 0.1;
    img{
      width: 100%;
      height: 100%;
    }
  }
  
  .overview{
    
    &__title{
      h3{
        font-size: 40px;
        line-height: 48px;
        font-weight: 400;
        margin-bottom: 60px;
        @media(max-width: 767px){
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 40px;

        }
      }
    }
    &__description{
      p{
        color:#D05110;
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
  }

`;

export default React.memo(MyComponent);
