import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import ProjectOverview from "../../components/project details/ProjectOverview";
import AtaGlance from "../../components/project details/AtaGlance";
import FeatureAmenities from "../../components/project details/FeatureAmenities";
import FloorPlan from "../../components/project details/FloorPlan";
import ProjectDetailsGallery from "../../components/project details/ProjectDetailsGallery";
import ProjectMap from "../../components/project details/ProjectMap";
import ProjectForm from "../../components/project details/ProjectForm";
import ProjectVideo from "../../components/project details/ProjectVideo";
import InnerBanner from "../../components/InnerBanner";
import ProjectList from "../../components/project/ProjectList";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchCareer} from "../../api/redux/career";
import {fetchProjectDetail, fetchProjects, fetchProjectsList} from "../../api/redux/project";
import {fetchFeatureProjects} from "../../api/redux/home";
import ProjectInnerBanner from "../../components/ProjectInnerBanner";

const MyComponent = () => {


    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'project',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        dispath(fetchProjects([api_url , api_params]))
    }, [])

    const getData = useSelector((state) => state.projects);
    let list=getData?.list?.data;

    const projectBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'project-banner');

    //get the query
    const locations = useLocation();
    const project_status = new URLSearchParams(locations?.search).get("status");


    // step -01

    const [selectStatus, setSelectStatus] = useState(project_status)
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')



    // api call
    useEffect(() => {

        let param = {
            [ApiParamKey.project_status]: selectStatus,
            [ApiParamKey.project_type]: selectType,
            [ApiParamKey.project_location]: selectLocation
        }
    }, [selectStatus, selectType, selectLocation])

    // api call
    useEffect(() => {

        let param = {
            [ApiParamKey.project_status]: selectStatus,
            [ApiParamKey.project_type]: selectType,
            [ApiParamKey.project_location]: selectLocation,
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.image]: 'yes',
        }
        let api_services = apiEndPoints.PROJECT_FILTER
        dispath(fetchProjectsList([api_services,param]))
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Projects | Climax Holdings Ltd.</title>
                <meta name="description" content="
                Discover the essence of our mission in action. Delve into our projects page, where we unveil the transformative initiatives that drive our vision forward. Explore our commitment to innovation, sustainability, and progress as we share the stories of the projects shaping our future.
                "/>
            </Helmet>

            <StyledComponent>
                <ProjectInnerBanner title={projectBanner?.section_data?.subtitle} imgList={projectBanner?.images?.list} />
                <ProjectList data={list} options={getData?.list?.filter}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
