import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import FeatureProject from "../../components/home/FeatureProject";
import Footer from "../../components/Footer";
import Chairman from "../../components/leaders/Chairman";
import ProjectGallery from "../../components/csr/CsrGallery";
import CvDrop from "../../components/career/CvDrop";
import Identity from "../../components/about/Identity";
import Team from "../../components/leaders/Team";
import Climax from "../../components/about/Climax";
import ClimaxReverse from "../../components/about/ClimaxReverse";
import HomeTestimonial from "../../components/home/HomeTestimonial";
import NewsSlider from "../../components/home/NewsSlider";
import LandingView from "../../components/LandingView";
import Concern from "../../components/home/Concern";
import HomeMap from "../../components/home/HomeMap";
import HomeBanner from "../../components/home/HomeBanner";
import {fetchFeatureMedia, fetchHome} from "../../api/redux/home";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchFeatureProjects} from "../../api/redux/home";
import {fetchMedia, fetchMediaDetail} from "../../api/redux/media";
import {useLocation} from "react-router-dom";
import Loadable from "react-loadable";
import { gsap, TimelineLite } from "gsap";



const Home = ({showFooter, onExploreClick}) => {

    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME;
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'home',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }
        setTimeout(() => {
            dispath(fetchHome([api_url , api_params]))
        }, 500);

    }, [location?.pathname])

    const getData = useSelector((state) => state.home);

    const bannerData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-banner');
    const homeOverview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-overview');
    const homeTestimonial = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-testimonial');
    const homeConcern = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-concern');
    const homeMap = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-map');
    const featureProject = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'feature-projects');
    const homeFeature = getData?.posts?.featuredProducts;
    const homeMedia = getData?.posts?.featuredMedia;

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    const LoadbleHomeBanner = Loadable({
        loader: () => import('../../components/home/HomeBanner'),
        loading: () => <div>Loading...</div>,
    });
    const LoadbleFeatureProject = Loadable({
        loader: () => import('../../components/home/FeatureProject'),
        loading: () => <div>Loading...</div>,
    });
    const LoadbleHomeTestimonial = Loadable({
        loader: () => import('../../components/home/HomeTestimonial'),
        loading: () => <div>Loading...</div>,
    });
    const LoadbleNewsSlider = Loadable({
        loader: () => import('../../components/home/NewsSlider'),
        loading: () => <div>Loading...</div>,
    });
    const LoadbleConcern = Loadable({
        loader: () => import('../../components/home/Concern'),
        loading: () => <div>Loading...</div>,
    });



    return (


        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Climax Holdings Ltd.</title>
                <meta name="description" content="Climax Holdings Ltd. is a real estate brand that
consistently delivers exceptional properties,
blending innovation, quality, and sustainability. With
its strong track record and commitment to
excellence."/>
            </Helmet>
                <StyledSection>
                    <div className={'remove-bg'}>
                        {
                            bannerData?.images?.list &&
                            <LoadbleHomeBanner data={bannerData?.images?.list}/>
                        }
                        <CvDrop title={homeOverview?.section_data?.subtitle}
                                description={homeOverview?.section_data?.description}
                                linkButton
                                buttonText={'Explore'}
                                buttonLink={'/about-us'}
                                buttonBack={'#3C4348'}
                                buttonHover={'#D05110'}
                                img={homeOverview?.images?.list?.[0]?.full_path}

                        />
                        <LoadbleFeatureProject data={homeFeature} textOne={featureProject?.section_data?.background_text}
                                        textTwo={featureProject?.section_data?.background_text_two}
                        />
                        <LoadbleHomeTestimonial data={homeTestimonial}/>
                        <LoadbleConcern  data={homeConcern}/>
                        <HomeMap data={homeMap}/>
                        <LoadbleNewsSlider data={homeMedia} />

                    </div>

                </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`

 

`;

export default React.memo(Home);
