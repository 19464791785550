import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {Link, useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import {coloredText} from "../../styles/globalStyleVars";
import Button from "../Button";

SwiperCore.use([Autoplay, Pagination, Navigation]);
const SliderShowRoom = ({padding,data,title,textOne,textTwo}) => {

    let containerRef = useRef();
    const ref = useRef(null);
    const location = useLocation();



    // <width control

    const sourceDivRef = useRef(null);
    const targetDivRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const fullWidth = sourceDivRef.current.offsetWidth;
            const adjustedWidth = fullWidth - 30;
            document.documentElement.style.setProperty('--target-width', `${adjustedWidth}px`);
            document.documentElement.style.setProperty('--target-height', `${adjustedWidth + 80}px`);
            document.documentElement.style.setProperty('--target-height-right', `${adjustedWidth + 30}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    // offset

    // const [offset, setOffset] = useState(100)
    //
    // useEffect(() => {
    //     setOffset(document.querySelector('.container').offsetLeft)
    //
    //     window.addEventListener('load', function () {
    //         setOffset(document.querySelector('.container').offsetLeft)
    //
    //     })
    //     window.addEventListener('resize', function () {
    //         setOffset(document.querySelector('.container').offsetLeft)
    //
    //     })
    // }, [])

    const [offset, setOffset] = useState(100);

    useEffect(() => {
        function updateOffset() {
            const container = document.querySelector('.fcontainer');
            if (container) {
                const containerOffset = container.offsetLeft;
                if (window.innerWidth > 767) {
                    setOffset(containerOffset);
                } else {
                    setOffset(100); // Reset to the initial offset when window size is <= 767
                }
            }
        }

        window.addEventListener('resize', updateOffset);

        updateOffset(); // Initial offset update

        return () => {
            window.removeEventListener('resize', updateOffset);
        };
    }, []);




    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupId, setPopupId] = useState()
    const handleClose = () => setShow(false);

    const [selectedbodTitle, setSelectedbodTitle] = useState('');
    const [selectedboddesination, setselectedboddesination] = useState('');
    const [selectedbodimg, setselectedbodimg] = useState('');
    const [selectedbodtext, setselectedbodtext] = useState('');
    const handleShow = () => {
        setShow(true)
        setPopupId()
    }

    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    return (
        <>


            <StyledBox data-scroll-container offset={offset}
                       className={`showroom slider_component parallax-img horizontal-scroll ${padding ? padding : ''}`} data-speed-right={30} data-speed-left={-30}>

                <Container className={'version_one fcontainer'}>
                    <Row>
                        <Col>
                            <Title text={'Our Projects'} fontSize={'60'} lineHeight={'68'}/>
                        </Col>
                    </Row>
                    <Row>
                        <p className={'scroll-right bg-text-one'}>{textOne}</p>
                        <p className={'scroll-left bg-text-two'}>{textTwo}</p>

                        <Col md={6} ref={sourceDivRef}>
                            <div className="testimonial__head">
                                <div className="testimonial__head__text">
                                    {title && <h3 className={''}>{reactHtmlParser(title)}</h3>}
                                </div>
                                <div className={"arrow__mobile"}>
                                    <div className="testimonial__head__second">
                                        <div className="testimonial__head__navigation">
                                            <ul>
                                                <li id={'gallery-prev'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                         viewBox="0 0 40 40">
                                                        <circle id="Ellipse_604" data-name="Ellipse 604" cx="20" cy="20" r="20"
                                                                fill="#323232"/>
                                                        <circle id="Ellipse_605" data-name="Ellipse 604" cx="75" cy="20" r="20"
                                                                fill="#E50019"/>
                                                        <g id="Group_16558" data-name="Group 16558"
                                                           transform="translate(16 16)">
                                                            <line id="Line_3874" data-name="Line 3874" x1="5" y2="5" fill="none"
                                                                  stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                            <line id="Line_3875" data-name="Line 3875" x1="5" y1="5"
                                                                  transform="translate(0 5)" fill="none" stroke="#fff"
                                                                  stroke-linecap="round" stroke-width="2"/>
                                                        </g>
                                                    </svg>


                                                </li>
                                                <li id={'gallery-next'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                         viewBox="0 0 40 40">
                                                        <g id="Banner_Nav_-_Right" data-name="Banner Nav - Right"
                                                           transform="translate(-28 -349)">
                                                            <circle id="Ellipse_606" data-name="Ellipse 604" cx="20" cy="20"
                                                                    r="20" transform="translate(28 349)" fill="#323232"/>
                                                            <circle id="Ellipse_607" data-name="Ellipse 604" cx="-20" cy="20"
                                                                    r="20" transform="translate(28 349)" fill="#E50019"/>

                                                            <g id="Group_16558" data-name="Group 16558"
                                                               transform="translate(6 7)">
                                                                <line id="Line_3874" data-name="Line 3874" x2="5" y2="5"
                                                                      transform="translate(40.5 356.5)" fill="none"
                                                                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                                <line id="Line_3875" data-name="Line 3875" y1="5" x2="5"
                                                                      transform="translate(40.5 361.5)" fill="none"
                                                                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                            </g>
                                                        </g>
                                                    </svg>


                                                </li>
                                            </ul>
                                        </div>
                                        <Button text={'Explore All'} src={'/projects'}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className={'slider-position'}>
                            <div className="testimonial__head__second">
                                <div className="testimonial__head__navigation">
                                    {
                                        data &&
                                    <ul>
                                        <li id={'gallery-prev'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                 viewBox="0 0 50 50">
                                                <circle id="Ellipse_604" data-name="Ellipse 604" cx="25" cy="25" r="25"
                                                        fill="#323232"/>
                                                <circle id="Ellipse_605" data-name="Ellipse 604" cx="75" cy="25" r="25"
                                                        fill="#D05110"/>
                                                <g id="Group_16558" data-name="Group 16558"
                                                   transform="translate(22.5 20)">
                                                    <line id="Line_3874" data-name="Line 3874" x1="5" y2="5" fill="none"
                                                          stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_3875" data-name="Line 3875" x1="5" y1="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li id={'gallery-next'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                                                 viewBox="0 0 50 50">
                                                <g id="Banner_Nav_-_Right" data-name="Banner Nav - Right"
                                                   transform="translate(-28 -349)">
                                                    <circle id="Ellipse_606" data-name="Ellipse 604" cx="25" cy="25"
                                                            r="25" transform="translate(28 349)" fill="#323232"/>
                                                    <circle id="Ellipse_607" data-name="Ellipse 604" cx="-25" cy="25"
                                                            r="25" transform="translate(28 349)" fill="#D05110"/>

                                                    <g id="Group_16558" data-name="Group 16558"
                                                       transform="translate(10 12.5)">
                                                        <line id="Line_3874" data-name="Line 3874" x2="5" y2="5"
                                                              transform="translate(40.5 356.5)" fill="none"
                                                              stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3875" data-name="Line 3875" y1="5" x2="5"
                                                              transform="translate(40.5 361.5)" fill="none"
                                                              stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul>
                                    }
                                </div>
                                {
                                    data &&
                                    <Button text={'Explore All'} src={'/projects'}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid  className={'p-0'} md={12}>

                    {
                        data?.list && data?.list?.length>0 &&
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            speed='500'
                            navigation={{
                                prevEl: '#gallery-prev',
                                nextEl: '#gallery-next',
                            }}
                            initialSlide={1}
                            loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1025: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                            className="mySwiper"
                        >
                            {
                                data?.list && data?.list?.length>0 &&
                                data?.list?.map((element)=>{
                                    return(
                                        <SwiperSlide>
                                            <div className="testimonial__single">
                                                <div className={'testimonial__single__height'}>
                                                    <div sm={5} className={'testimonial__single__position'}>
                                                        <div className={'gallery-text'}>
                                                            <div className="gallery-text__title">
                                                                <h3>{element?.product_data?.title}</h3>
                                                            </div>
                                                            <div className="gallery-text__desc">
                                                                <p>{element?.product_data?.type}</p>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                                                    <circle id="Ellipse_60" data-name="Ellipse 60" cx="3" cy="3" r="3" fill="#3c4348"/>
                                                                </svg>
                                                                <p>{element?.product_data?.location}</p>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial__single__image video">
                                                            <Link to={`/project/${element?.product_data?.slug}`}>
                                                                <Img radius={'21px'} src={element?.images?.list?.[0]?.full_path} alt={'feature image'}/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    )
                                })
                            }
                        </Swiper>

                    }



                </Container>
            </StyledBox>

        </>
    )

};


const StyledBox = styled.section`
  background-color: ${props => props.background ? props.background : '#FFFFFF'};
  position: relative;
  min-height: 100vh;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 10%;
    left: 0;
    bottom: 0;
    background-color: #3C4348;
    @media(max-width: 767px){
      height: 35%;
    }
  }
  .title{
    margin-bottom: 60px;
    h3{
      font-family: Century;
    }
  }
  .bg-text-one, .bg-text-two {
    font-size: 200px;
    line-height: 200px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;
    font-family: Gilroy;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -10%;
    top: 0;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -10%;
    bottom: 55%;

    @media (max-width: 767px) {
      bottom: 72%;
    }
  }
  
  .slider-position{
    position: absolute;
    bottom: 56%;
    left: 50%;
    z-index: 100;
  }
  .mySwiper{
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '100px'};
    height: var(--target-height)!important;;

    @media (max-width: 1024px){
      padding-left: unset;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
  .gallery-text{
    h3,p,svg{
      opacity: 0;
      @media(max-width: 1024px){
        opacity: 1;
      }
    }
  }

  .swiper-slide {
    margin-top: auto;
    transition: 0.7s all ease;
    @media(max-width: 1024px){
      margin-top: unset;
    }
  }

  
@media (min-width: 1025px){
  .arrow__mobile{
    display: none;
  }
}
  
 
  .gallery-text__second{
    display: none;
  }
  
 
  

  .swiper-slide-active {
    height: auto;
    width: var(--target-width)!important;


    .testimonial__single__image {
      padding-top: calc(580 / 470 * 100%);
      position: relative;
      .global-image{
        overflow: hidden;
        img{
          transition: all 0.7s ease;

          //transform: scale(1.06);
          transform: unset !important;
        }
      }
      &:hover{
        .global-image img{
          transform: scale(1.06) !important;
        }
      }
    }
    .gallery-text{
      position: absolute;
      z-index: 100;
      display: flex;
      flex-direction: column;
      width: 100%;
      left: var(--target-height-right);
      @media(max-width: 1024px){
        position: unset;
        margin-bottom: 20px;
      }
    }
    .gallery-text{
      
      h3{
        opacity: 1;
        transition: opacity 0.7s ease;
        transition-delay: 0.6s;
        font-family: Ubuntu;
        color: #D05110;
      }
      &__desc{
        display: flex;
        align-items: center;
        gap: 10px;
        svg{
          opacity: 1;
          transition: opacity 0.7s ease;
          transition-delay: 0.6s;
        }
        p{
          opacity: 1;
          transition: opacity 0.7s ease;
          transition-delay: 0.6s;
          font-family: Ubuntu;
          text-transform: capitalize;

        }
      }
      
    }
  }

  .testimonial__head {
    display: flex;
    justify-content: space-between;

    &__text {
        h3{
          span{
            color: #FC671A;
          }
        }
    }
    
    &__second{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dc-btn{
        margin-right: 45px;
      }
    }

    &__navigation {
      ul {
        display: inline-flex;

        li {
          cursor: pointer;

          svg {
            border-radius: 50px;
            #Ellipse_605 {
              transition: 0.7s all ease;
            }

            #Ellipse_607 {
              transition: 0.7s all ease;
            }
          }

          &:first-child {
            margin-right: 20px;
          }

          &:hover {
            svg {
              #Ellipse_605, #Ellipse_607 {
                r: 25px;
                cx: 25px;
                fill: #FC671A ;
              }
            }
          }


        }
      }
    }
  }

  .testimonial__single {
    &__image {
      position: relative;
      padding-top: calc(330 / 270 * 100%) !important;

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          transform: unset !important;
        }

        .react_lightgallery_item {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 9;
        }

        &__icon {
          position: absolute;
          top: -20px;
          right: -25px;
        }

      }

      &__position {
        position: relative;

      }

      &__content {
        .text {
          color: #32355D;
          line-height: 36px;
          font-weight: 700;
        }

        .description {
          margin: 40px 0 40px 0;
          color: #32355D;
        }

        .name {
          color: #18AEAE;
          line-height: 24px;
          font-weight: 500;

        }

        .designation {
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }

    .global-image {
      img {
        cursor: pointer;
        transform: none;
      }
    }
  }
    

  
  @media (max-width: 1023px) {
    //padding-bottom: 150px!important;
    //padding-top: 150px!important;
    min-height: auto;

   

    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    
    .gallery-text{
      display: none!important;
    }
    .slider-position{
      display: none;
    }
    .arrow__mobile{
      display: block;
      padding-bottom: 40px;
    }
    //.swiper-slide-active{
    //  width: unset;
    //}
    .testimonial__head {
      flex-direction: column;
      padding-bottom: unset;
      &__text{
        padding-bottom: 40px;
      }
    }

    .testimonial__single__image__icon {
      position: absolute;
      top: -20px;
      left: 0;
    }

    .testimonial__single {
      &__content {
        margin-top: 20px;

        .description {
          margin: 20px 0 20px 0;
        }
      }
    }

    .swiper-initialized {
      padding-left: 15px;
      padding-right: 15px;
      height: auto !important;
    }


    .swiper-slide-active {
      height: 100%;
      width: inherit !important;

      .testimonial__single__image {
        padding-top: calc(580 / 470 * 100%);
        width: 100%;
        height: 100%;

      }
    }
    .gallery-text__second{
      display: block;
      padding-top: 20px;
      display: flex;
      flex-direction: column-reverse;
    }
    
    .swiper-slide{
      width: inherit!important;
      height:100%;
    }
    .testimonial__head__second{
      justify-content: space-between;
      .dc-btn{
        margin-right: 0 !important;
      }
    }
    .slider-position{
      top:20%;
    }
    .testimonial__single {
      height: unset;
      &__height {
        align-content: flex-end;
        height: 100%;
      }
    }
    
  }

.swiper-button-lock {
  display: block !important;
}



`;


export default React.memo(SliderShowRoom);

