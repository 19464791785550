export const ApiParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    get_section : 'get_section',
    gallery : 'gallery',
    specification : 'specification',
    page_id : 'page_id',
    project_status:'category',
    project_type:'type',
    project_location:'location'

};
