import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {BiChair} from "react-icons/bi";
import Chairman from "../../components/leaders/Chairman";
import Team from "../../components/leaders/Team";
import InnerBanner from "../../components/InnerBanner";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {useDispatch, useSelector} from "react-redux";
import {fetchLeader} from "../../api/redux/leader";

const MyComponent = () => {
    const dispath = useDispatch()
    const location=useLocation();

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'leaders',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }
        setTimeout(() => {
            dispath(fetchLeader([api_url , api_params]))
        }, 500);


    }, [location?.pathname])

    const getData = useSelector((state) => state.leader);

    const leaderBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'leader-banner');
    const chairmanMessage = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'all-leaders');
    const team = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'management-team');

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Leaders | Climax Holdings Ltd.</title>
                <meta name="description" content="Meet the driving force behind our organization – a team of visionary leaders dedicated to shaping our future. Our leaders bring diverse expertise, unwavering commitment, and a shared passion for innovation. Learn more about the individuals who lead us toward our mission and inspire us to achieve greatness."/>
            </Helmet>

            <StyledComponent>
                {
                    leaderBanner?.images?.list?.[0]?.full_path && leaderBanner?.section_data?.subtitle &&
                    <InnerBanner
                        img={leaderBanner?.images?.list?.[0]?.full_path}
                        title={leaderBanner?.section_data?.subtitle}
                    />
                }
                {
                    chairmanMessage?.posts?.list && chairmanMessage?.posts?.list?.length>0 &&
                    chairmanMessage?.posts?.list?.map((element,index)=>{
                        if(index%2==0){
                            return(
                                <Chairman data={element}
                                          textOne={chairmanMessage?.section_data?.background_text}
                                          textTwo={chairmanMessage?.section_data?.background_text_two}
                                />
                            )
                        }
                        else {
                            return (
                                <Chairman data={element} reverse
                                          textOne={chairmanMessage?.section_data?.background_text}
                                          textTwo={chairmanMessage?.section_data?.background_text_two}
                                />
                            )
                        }
                    })
                }


                {
                    team?.section_data?.title && team?.images?.list &&
                    <Team title={team?.section_data?.title} data={team?.images?.list}/>
                }

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
