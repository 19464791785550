import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import leaderReducer from '../redux/leader'
import csrReducer from '../redux/csr'
import contactReducer from '../redux/contact'
import careerReducer from '../redux/career'
import buyerReducer from '../redux/buyer'
import landownerReducer from '../redux/landowner'
import customerReducer from '../redux/customer'
import projectReducer from '../redux/project'
import projectDetailReducer from '../redux/project'
import mediaReducer from '../redux/media'
import mediaDetailReducer from '../redux/media'



const store = configureStore({
    reducer: {

        globalReducer,
        home : homeReducer,
        about : aboutReducer,
        leader : leaderReducer,
        csr : csrReducer,
        contact: contactReducer,
        career:careerReducer,
        buyer: buyerReducer,
        landowner: landownerReducer,
        customer: customerReducer,
        projects: projectReducer,
        projectDetails: projectDetailReducer,
        media: mediaReducer,
        mediaDetails: mediaDetailReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

