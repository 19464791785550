import React from 'react';
import styled from 'styled-components';
import {hover} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor,
                    download,
                    svgColor,
                    onClick,
                }) => {


    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   svgColor={svgColor}
                   onClick={onClick}
        >
            {src ? (
                <Link to={src || '/'}>
                    <span> {text}   <svg xmlns="http://www.w3.org/2000/svg" width="10.207" height="7.414" viewBox="0 0 10.207 7.414">
                                  <g id="Group_22054" data-name="Group 22054" transform="translate(0.5 0.707)">
                                    <line id="Line_3604" data-name="Line 3604" x2="9" transform="translate(0 3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3605" data-name="Line 3605" x2="3" y2="3" transform="translate(6)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3606" data-name="Line 3606" y1="3" x2="3" transform="translate(6 3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                  </g>
                                </svg>

 </span>
                </Link>
            ) : (
                <a href={download} download target={target || '_self'}>
                    <span>{text}
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.207" height="7.414" viewBox="0 0 10.207 7.414">
                                  <g id="Group_22054" data-name="Group 22054" transform="translate(0.5 0.707)">
                                    <line id="Line_3604" data-name="Line 3604" x2="9" transform="translate(0 3)" fill="none" stroke={svgColor} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3605" data-name="Line 3605" x2="3" y2="3" transform="translate(6)" fill="none" stroke={svgColor} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3606" data-name="Line 3606" y1="3" x2="3" transform="translate(6 3)" fill="none" stroke={svgColor} stroke-linecap="round" stroke-width="1"/>
                                  </g>
                                </svg>
                    </span>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || 'content-width'};
    height: ${props => props.height || '40'}px;
    cursor: pointer;



    a {
      letter-spacing: 2px;
      text-transform: uppercase;
      display: flex;
      width: fit-content;
      height: 100%;
      font-family: Ubuntu;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '14'}px;
      font-weight: ${props => props.fontWeight || 700};
      margin: 0;
      line-height: ${props => props.lineHeight || '20'}px;
      background-color: ${props => props.background || `#3C4348`};
    
      position: relative;
      border-radius: ${props => props.borderRadius || '22'}px;
      overflow: hidden;
      z-index: 0;
      transition: border .3s ease;
      padding: 12px 36px;
      box-sizing: border-box;
      border: 1px solid ${props => props.borderColor || '#FFFFFF'};
      
      svg{
        margin-left: 10px;
        transition: .2s ease-in-out;
        line{
          stroke: ${props => props.svgColor || '#FFF'};
        }
      }

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#FFFFFF`};
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
      }


      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        background-color: ${p => p.hoverBackground || hover};
        height: 0%;
        width: 100%;
        margin: auto;
        transition: all .3s ease;
        border-radius: 22px;
      }

      &:hover {

        border: 1px solid ${hover};

        span {
          color: ${props => props.hoverColor || `#FFF`};
        }
        
        svg{
          transform: translateX(10px);
          transition: .2s ease-in-out;
        }

        svg {
          line {
            stroke: ${props => props.hoverColor || '#FFF'};
          }
        }

        &:before {
          height: 100%;
          //width: 100%;
        }
      }

      &:focus {
        color: #222222;
      }
    }


  }

`;


export default Button;
