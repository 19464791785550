import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import ProjectGallery from "../../components/csr/CsrGallery";
import CsrOverview from "../../components/csr/CsrOverview";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {fetchCsr} from "../../api/redux/csr";

const MyComponent = () => {
    const dispath = useDispatch()
    const location=useLocation();

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'csr',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }
        setTimeout(() => {
            dispath(fetchCsr([api_url , api_params]))
        }, 500);


    }, [location?.pathname])

    const getData = useSelector((state) => state.csr);

    const csrBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr-banner');
    const csrOverview= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr-overview');
    const csrGallery = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr-gallery');


    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>CSR | Climax Holdings Ltd.</title>
                <meta name="description" content="At Climax Holdings Ltd., we don't see CSR as a mere obligation; it's a commitment
rooted in our moral duty. Our approach encompasses corporate governance, ethics,
and transparency, reflecting a comprehensive CSR program."/>
            </Helmet>

            <StyledComponent>
                {
                    csrBanner?.images?.list?.[0]?.full_path && csrBanner?.section_data?.subtitle ?
                        <InnerBanner
                            img={csrBanner?.images?.list?.[0]?.full_path}
                            title={csrBanner?.section_data?.subtitle}
                        />: ''
                }

                    <CsrOverview
                        title={csrOverview?.section_data?.short_desc}
                        parallax_text={csrOverview?.section_data?.background_text}
                        desc={csrOverview?.section_data?.description}
                    />

                <ProjectGallery data={csrGallery}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
