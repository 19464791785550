import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img,link,address,contactList}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [animatePopup, setAnimatePopup] = useState(false); // New state for triggering the animation
    const popupRef = useRef(null);

    const handleShowPopup = (e) => {
        e.stopPropagation();
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setAnimatePopup(false); // Reset the animatePopup state before hiding the popup
        setTimeout(() => {
            setShowPopup(false);
        }, 300); // Wait for the transition to complete (300ms) before hiding the popup
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleClosePopup();
            }
        };

        if (showPopup) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showPopup]);

    useEffect(() => {
        if (showPopup) {
            setAnimatePopup(true); // Trigger the animation when the popup is shown
        }
    }, [showPopup]);
    return (
        <StyledComponent className={'pt-100 pb-200'}>
            <Container>
                <Row className={'address'}>
                    <Col md={3} className={'address_part'}>
                        <h5 className={'split-up'}>Climax Holdings Ltd.</h5>
                    </Col>
                    <Col md={3} className={'address_part'}>
                        {reactHtmlParser(address?.description)}
                    </Col>
                    <Col md={3} className={'address_part '}>
                        <div className={'d-flex justify-content-center align-items-center phone'}>
                            <div>
                                {
                                    contactList && contactList?.map((element)=>{
                                        return(
                                            <a  href={`tel:${element?.data?.subtitle}`}>
                                                <p className={'split-up'}>{element?.data?.subtitle}</p>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className={'address_part'}>
                        <a href={`mailto:${address?.short_desc}`}>
                            <p className={'split-up'}>{address?.short_desc}</p>
                        </a>

                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="map__image">
                            <Img src={img? img : '/images/dynamic/project/map.jpg'} alt={'climax-map'}/>
                            <div className="svgPoint" onClick={handleShowPopup}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
                                    <defs>
                                        <filter id="Ellipse_418" x="0" y="0" width="90" height="90" filterUnits="userSpaceOnUse">
                                            <feOffset dx="1" dy="2" input="SourceAlpha"/>
                                            <feGaussianBlur stdDeviation="5" result="blur"/>
                                            <feFlood flood-opacity="0.161"/>
                                            <feComposite operator="in" in2="blur"/>
                                            <feComposite in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                    <g id="Group_19959" data-name="Group 19959" transform="translate(14 13)">
                                        <g transform="matrix(1, 0, 0, 1, -14, -13)" filter="url(#Ellipse_418)">
                                            <circle id="Ellipse_418-2" data-name="Ellipse 418" cx="30" cy="30" r="30" transform="translate(14 13)" fill="#fff"/>
                                        </g>
                                        <g id="Group_19944" data-name="Group 19944" transform="translate(15.21 14.962)">
                                            <g id="Group_19950" data-name="Group 19950">
                                                <path id="Path_9964" data-name="Path 9964" d="M199.752,0V29.977H198.5V1Z" transform="translate(-185.356)" fill="#636466"/>
                                                <path id="Path_9965" data-name="Path 9965" d="M171.593,25.5V53.8l-1.093-.649V26.189Z" transform="translate(-159.21 -23.811)" fill="#f58220"/>
                                                <g id="Group_19948" data-name="Group 19948" transform="translate(8.171 12.866)">
                                                    <path id="Path_9966" data-name="Path 9966" d="M125.943,194.3v16.1l-.814-.444V196.5l-.814.629v12.323l-.914-.636V196.558Z" transform="translate(-123.4 -194.3)" fill="#939598"/>
                                                    <rect id="Rectangle_5894" data-name="Rectangle 5894" width="1.364" height="0.391" transform="translate(1.858 8.282) rotate(180)" fill="#939598"/>
                                                    <rect id="Rectangle_5895" data-name="Rectangle 5895" width="1.364" height="0.391" transform="translate(1.954 5.428) rotate(180)" fill="#939598"/>
                                                    <rect id="Rectangle_5896" data-name="Rectangle 5896" width="1.364" height="0.391" transform="translate(1.858 11.165) rotate(180)" fill="#939598"/>
                                                </g>
                                                <g id="Group_19949" data-name="Group 19949" transform="translate(18.872 12.787)">
                                                    <path id="Path_9967" data-name="Path 9967" d="M285,193.1v15.939l.815-.364V195.206l.814.742v12.3l.914-.629V195.351Z" transform="translate(-285 -193.1)" fill="#939598"/>
                                                    <rect id="Rectangle_5897" data-name="Rectangle 5897" width="1.364" height="0.391" transform="translate(0.682 7.887)" fill="#939598"/>
                                                    <rect id="Rectangle_5898" data-name="Rectangle 5898" width="1.364" height="0.391" transform="translate(0.589 5.033)" fill="#939598"/>
                                                    <rect id="Rectangle_5899" data-name="Rectangle 5899" width="1.364" height="0.391" transform="translate(0.682 10.774)" fill="#939598"/>
                                                </g>
                                                <path id="Path_9968" data-name="Path 9968" d="M233.292,1.437V28.964L230.2,29.99V0Z" transform="translate(-214.957)" fill="#f58220"/>
                                                <path id="Path_9969" data-name="Path 9969" d="M12.383,118.606a11.61,11.61,0,0,1-9.449-11.025,10.829,10.829,0,0,1,1.41-5.284c.046-.079.232-.384.318-.53.02-.033.04-.066.06-.093a5.005,5.005,0,0,1,1.146-1.146,7.243,7.243,0,0,1,3.006-1.351.123.123,0,0,0,.046-.013c.02-.007.033-.007.053-.013h0a2.164,2.164,0,0,0-.5-4.251,2.022,2.022,0,0,0-.914.218.024.024,0,0,0-.02.007q-.338.159-.656.338h0A12.181,12.181,0,0,0,0,106.27v.026a.888.888,0,0,0,.007.132C.079,113.17,5.2,118.454,12.383,118.606Z" transform="translate(0 -88.616)" fill="#636466"/>
                                                <path id="Path_9970" data-name="Path 9970" d="M244.443,105.99a12,12,0,0,0-5.152-9.761c-.033-.026-.073-.046-.106-.073l-.2-.139c-.073-.053-.152-.1-.232-.152a.868.868,0,0,1-.1-.066c-.106-.066-.205-.132-.311-.192l-.04-.02-.06-.04h0c-.225-.132-.45-.258-.689-.384h0q-.328-.179-.656-.338a.024.024,0,0,0-.02-.007,2.022,2.022,0,0,0-.914-.219,2.164,2.164,0,0,0-.5,4.251h0c.02.007.033.007.053.013s.033.007.046.013a7.147,7.147,0,0,1,3.006,1.351,5.462,5.462,0,0,1,1.146,1.146c.02.033.04.066.06.093.086.139.272.45.318.53a10.777,10.777,0,0,1,1.41,5.284,11.344,11.344,0,0,1-5.556,9.449,15.548,15.548,0,0,1-5.847,1.636,8.519,8.519,0,0,0,1.013.046,14.439,14.439,0,0,0,3.41-.411,12.008,12.008,0,0,0,9.886-11.3c.007-.086.007-.166.007-.252.007-.106.013-.212.013-.324,0-.046.007-.086.007-.132v-.013C244.443,106,244.443,106,244.443,105.99Z" transform="translate(-214.863 -88.336)" fill="#f58220"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            {showPopup && (
                                <div className={`popupBox ${showPopup ? 'show' : ''} ${animatePopup ? 'animate' : ''}`} ref={popupRef}>
                                    <a href={link} target="_blank">
                                        <h4>Climax Holding Ltd.</h4>
                                        <p>
                                            House # 413 (4th Floor), Road # 13,
                                            Mohakhali DOHS, Dhaka-1206.

                                        </p>
                                    </a>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;
  position: relative;

  .address {
    margin-bottom: 60px;

    h5 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      color: ${hover};
      font-family: Century;
      @media (max-width: 767px) {
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: #3C4348;
  }

  .map__image {
    position: relative;
    padding-top: calc(550 / 1168 * 100%);
    z-index: 2;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));


    .svgPoint {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      cursor: pointer;

      svg {
        pointer-events: none;
      }
    }

    .popupBox {
      position: absolute;
      top: 59%;
      left: 49%;
      background-color: #3C4348;
      padding: 30px 45px 30px 30px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 999;
      width: 0;
      height: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      :before {
        content: '';
        position: absolute;
        top: -25px;
        left: 10px;
        border-width: 15px;
        border-style: solid;
        border-color: #3C4348 transparent transparent transparent;
        transform: rotate(180deg);
      }
      .dot {
        display: flex;
        justify-content: end;
        align-items: end;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #FFF;
        margin-right: 10px;
        margin-left: 10px;
      }

      h4 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        margin-top: 10px;
        color: #FFF;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .popupBox.show.animate {
      width: 354px;
      height: 186px;
      opacity: 1;
    }

  }

  @media (max-width: 767px) {
    padding-bottom: 0;
    &:after {
      display: none;
    }

    .address_part {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      .phone{
        justify-content: start !important;
        align-items: start !important;
      }
    }

    .map__image {
      padding-top: calc(300 / 375 * 100%);

    }

    .col-md-12 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .address_part {
    p {
      &:hover {
        color: ${hover};
      }
    }
  }
`;

export default React.memo(MyComponent);
