import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {title} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const FeatureAmenities = ({data,padding}) => {
    const fList = data?.data?.description;

    let [limit, setLimit] = useState(0);



    useEffect(() => {
        const ul_list = document.querySelectorAll('.features__content ul li').length/2 ;
        const limit_ul = Math.ceil(ul_list);

        setLimit(limit_ul)
        window.addEventListener('load',function () {
            setLimit(limit_ul)
        })
        window.addEventListener('resize',function () {
            setLimit(limit_ul)
        })

    }, []);

    return (
        <StyledFeatureAmenities limit={limit} className={`features ${padding ? padding : 'pt-200 pb-200'}`}>
            <Container>
                <Row>
                  <Col className={'features__col'} md={12}>
                      <Title text={'Feature & Amenities'}/>

                          <div className="features__content ">
                              <ul>
                                  {
                                      fList ?
                                          reactHtmlParser(fList) : ''
                                  }
                              </ul>


                          </div>

                  </Col>
                </Row>
            </Container>
        </StyledFeatureAmenities>
    );
};
const StyledFeatureAmenities = styled.section`
  background-color: #FFFDFC;
  overflow: hidden;
  .title{
    h3{
      margin-bottom: 60px;
      font-family: ${title};
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }
  }

  .features {
    &__content {
      ul {
        display: grid;
        grid-template-rows: repeat(${props => props.limit ? props.limit: 5}, auto) ;
        grid-auto-flow: column;
        gap: 0px;
        grid-template-columns: 50% calc(50% - 30px);
        justify-content: space-between;

        li {
          //flex: 0 0 50%;
          display: grid;
          padding: 15px 0;
          grid-template-rows: repeat(${props => props.limit ? props.limit: 5}, auto) ;
          grid-auto-flow: column;
          font-weight: 400;
          font-size: 16px;
          color: #222222;
          border-bottom: 1px solid rgba(37, 37, 37, 0.5);
          //&:nth-of-type(2n + 1){
          //  background: white;
          //}
        }
      }
    }
  }

  
  @media(min-width: 1550px){
    .title{
      h2{
        font-size: 45px;
        line-height: 55px;
      }
    }
  }
  @media(max-width: 767px){
    .features{

      &__content {
        ul {
          display: flex;
          grid-template-rows:unset;
          grid-auto-flow: column;
          gap: unset;
          grid-template-columns: unset;
          flex-wrap: wrap;

          li {
            flex: 0 0 100%;
            display: block;
            padding: 15px 0;
            grid-template-rows: repeat(4, auto);
            grid-auto-flow: column;
            font-weight: 400;
            font-size: 16px;
            color: #222222;
            border-bottom: 1px solid rgba(37, 37, 37, 0.5);
            //&:nth-of-type(2n + 1){
            //  background: white;
            //}
          }
        }
      }

    }
  }
`;

export default React.memo(FeatureAmenities);

