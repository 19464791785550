import React, { Suspense, lazy} from "react";
import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import 'react-toastify/dist/ReactToastify.css';

// page imports
import About from './pages/about';
import Components from './pages/components';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Leaders from "./pages/leaders";
import Csr from "./pages/csr";
import Career from "./pages/career";
import News from "./pages/news";
import Contact from "./pages/contact";
import Landowners from "./pages/landowners";
import Buyer from "./pages/buyer";
import Customer from "./pages/customer";
import {Horizontal} from "./components/animations/horizontal";
import SocialRight from "./components/SocialRight";
import Footer from "./components/Footer";
import ContactButton from "./components/ContactButton";
import Menu from "./components/Menu";
import OpeningModal from "./components/OpeningModal";
import MediaDetails from "./pages/news/single";
import {ToastContainer} from "react-toastify";
import {ClimaxReveal} from "./components/animations/ClimaxReveal";
import Home from "./pages/home";


function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);



    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            speed: 1
        });
        return () => {
            smoother.kill();
        };
    }, []);

    // route change scroll top & page class
    useEffect(() => {
        if (location?.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location?.pathname])




    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()
    Horizontal()
    ClimaxReveal()
    // page transition
    PageTransition()
    return (
        <React.Fragment>
            <GlobalStyle/>
            <ContactButton/>
            <SocialRight/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <OpeningModal/>
            <Menu/>
            {/*page transition*/}
            <div className="page-transition">
                <svg xmlns="http://www.w3.org/2000/svg" width="155.038" height="100" viewBox="0 0 155.038 50">
                    <g id="Group_19909" data-name="Group 19909" transform="translate(1482.204 -721.856)">
                        <g id="Group_19905" data-name="Group 19905" transform="translate(-1431.932 735.95)">
                            <path id="Path_9774" data-name="Path 9774" d="M-240.432,877.336h-3.648a2.73,2.73,0,0,1,.455-1.491,1.518,1.518,0,0,0,.321-.787.27.27,0,0,0-.086-.189l-4.651-7.292q-3.15,5.964-4.655,8.623a67.391,67.391,0,0,1-4.574,7.289,24.355,24.355,0,0,1-4.982,5.181c-.316,0-.754-.277-1.3-.823a2.352,2.352,0,0,1-.8-1.612c0-.156.188-.235.572-.235q2.26,0,5.823-4.979a117.443,117.443,0,0,0,8.7-15.369l-5.829-9.283a4.386,4.386,0,0,1-.483-1.1,4.111,4.111,0,0,1-.2-1.33h3.676a2.128,2.128,0,0,1-.447,1.33,2.022,2.022,0,0,0-.446.747.916.916,0,0,0,.129.352l4.657,7.446a44.421,44.421,0,0,0,4.041-7.255,8.559,8.559,0,0,0,.7-3.176v-.619a8.649,8.649,0,0,0,.848,1.571,10.605,10.605,0,0,0,1.042,1.326,5.729,5.729,0,0,1-.8,2.4q-1.02,1.959-4.615,7.753l5.7,9.058c.355.549.558.886.619,1.015a3.859,3.859,0,0,1,.234,1.451" transform="translate(345.198 -852.765)" fill="#fff"/>
                            <g id="Group_19902" data-name="Group 19902" transform="translate(0.486 1.432)">
                                <path id="Path_9775" data-name="Path 9775" d="M-1008.153,880.234a3.179,3.179,0,0,1-2.135-.605,1.4,1.4,0,0,1-.369-1.613l2.943-9.736a2.859,2.859,0,0,1,1.385-1.616,4.939,4.939,0,0,1,2.472-.6h11.209l-.526,1.766h-7.624l-3.413,10.636h7.8l-.533,1.77Z" transform="translate(1010.761 -866.062)" fill="#fff"/>
                                <path id="Path_9776" data-name="Path 9776" d="M-848.589,880.234h-14.4l4.3-14.171h6.766l-3.765,12.4h7.624Z" transform="translate(878.895 -866.062)" fill="#fff"/>
                                <path id="Path_9777" data-name="Path 9777" d="M-702.881,880.234h-6.771l4.3-14.171h6.772Z" transform="translate(742.071 -866.062)" fill="#fff"/>
                                <path id="Path_9778" data-name="Path 9778" d="M-600.54,880.234h-6.744l2.5-8.218-6.8,8.218h-2.768l-1.942-8.218-2.5,8.218h-2.532l4.3-14.171h6.122l1.857,7.744,6.414-7.744h6.387Z" transform="translate(663.253 -866.062)" fill="#fff"/>
                                <path id="Path_9779" data-name="Path 9779" d="M-385.362,880.234h-6.775l1.36-4.442h-4.242l-1.349,4.442h-6.774l3.627-11.954a2.813,2.813,0,0,1,1.379-1.616,4.95,4.95,0,0,1,2.47-.6h11.418a3.213,3.213,0,0,1,2.14.6,1.409,1.409,0,0,1,.366,1.616Zm-7.241-12.406-1.886,6.2h4.239l1.887-6.2Z" transform="translate(468.563 -866.062)" fill="#fff"/>
                            </g>
                            <g id="Group_19903" data-name="Group 19903" transform="translate(0 17.389)">
                                <path id="Path_9780" data-name="Path 9780" d="M-1014.988,1016.19h3.243l-.946.74v2.894a7.271,7.271,0,0,0,1.839.2,7.349,7.349,0,0,0,1.132-.078v-3.015l-.947-.74h3.239l-.951.74v6.687l.951.737h-3.239l.947-.737v-3.1a10.353,10.353,0,0,1-1.288.079,7.1,7.1,0,0,1-1.683-.162v3.18l.946.737h-3.243l.953-.737v-3.185a1.808,1.808,0,0,0-.711.143,1.308,1.308,0,0,0-.53.4,1.6,1.6,0,0,1,1.24-1.142v-2.9Z" transform="translate(1015.275 -1015.984)" fill="#fff"/>
                                <path id="Path_9781" data-name="Path 9781" d="M-931.471,1014.341a3.387,3.387,0,0,1,2.646,1.057,4.722,4.722,0,0,1,1.022,3.289,4.555,4.555,0,0,1-1.022,3.16,3.361,3.361,0,0,1-2.643,1.061,3.367,3.367,0,0,1-2.645-1.061,4.682,4.682,0,0,1-1.018-3.229,4.665,4.665,0,0,1,1.018-3.22,3.422,3.422,0,0,1,2.641-1.057m-.064.531a1.79,1.79,0,0,0-1.238.481,2.3,2.3,0,0,0-.677,1.184,8.151,8.151,0,0,0-.216,2.071,5.921,5.921,0,0,0,.495,2.733,1.962,1.962,0,0,0,.736.748,1.987,1.987,0,0,0,1.024.283,1.822,1.822,0,0,0,1.25-.476c.594-.516.893-1.591.893-3.218a9.847,9.847,0,0,0-.119-1.651,3.584,3.584,0,0,0-.376-1.122,1.981,1.981,0,0,0-.743-.747,2.02,2.02,0,0,0-1.029-.287" transform="translate(943.759 -1014.334)" fill="#fff"/>
                                <path id="Path_9782" data-name="Path 9782" d="M-859.829,1016.19h3.243l-.95.74v6.858c.445.025.819.041,1.114.041a2.577,2.577,0,0,0,1.613-.428,2.3,2.3,0,0,0,.736-1.412,11.3,11.3,0,0,0,.645,1.427,4.031,4.031,0,0,0,.657.938h-7.057l.947-.725v-6.7Z" transform="translate(876.565 -1015.984)" fill="#fff"/>
                                <path id="Path_9783" data-name="Path 9783" d="M-792.689,1016.19h2.907a4.8,4.8,0,0,1,2.887.709,3.859,3.859,0,0,1,1.4,3.292,3.948,3.948,0,0,1-1.493,3.519,3.387,3.387,0,0,1-1.153.5,7.679,7.679,0,0,1-1.633.142h-2.91l.947-.737v-6.687Zm2.288.577v7.01c.377.03.645.046.793.046a2.2,2.2,0,0,0,1.942-.911,4.693,4.693,0,0,0,.695-2.743,4.57,4.57,0,0,0-.543-2.374,2.216,2.216,0,0,0-2.061-1.072c-.139,0-.41.015-.826.043" transform="translate(816.654 -1015.984)" fill="#fff"/>
                                <path id="Path_9784" data-name="Path 9784" d="M-718.818,1016.19h3.243l-.947.74v6.687l.947.737h-3.243l.95-.737v-6.687Z" transform="translate(750.736 -1015.984)" fill="#fff"/>
                                <path id="Path_9785" data-name="Path 9785" d="M-680.134,1022.8v-4.247a3.54,3.54,0,0,0-.289-1.631,1.812,1.812,0,0,0-1.092-.733h3.3a1.779,1.779,0,0,0-1.089.733,3.386,3.386,0,0,0-.291,1.631v5.8h-1.089l-3.722-6.811v4.448a3.42,3.42,0,0,0,.291,1.638,1.763,1.763,0,0,0,1.09.725h-3.3a1.822,1.822,0,0,0,1.092-.737,3.492,3.492,0,0,0,.287-1.627v-5.06l-.953-.74h3.5l-.946.74Z" transform="translate(721.74 -1015.984)" fill="#fff"/>
                                <path id="Path_9786" data-name="Path 9786" d="M-600.524,1014.576l-1.258,2.333a2.808,2.808,0,0,0-.425-1.265,1.715,1.715,0,0,0-.656-.584,2.234,2.234,0,0,0-1.063-.249,2.371,2.371,0,0,0-1.791.74,2.6,2.6,0,0,0-.65,1.067,5.959,5.959,0,0,0-.254,1.811q0,3.684,3.221,3.687c.206,0,.579-.016,1.124-.046v-3.135h-1.873q-1.39,0-1.39-.865a.662.662,0,0,1,.188-.473.6.6,0,0,1,.448-.192.47.47,0,0,1,.332.118.37.37,0,0,1,.128.291.283.283,0,0,1-.075.232.6.6,0,0,1-.3.117.1.1,0,0,0-.094.1c0,.1.153.141.456.141h4.469l-.949.736v2.773l.949.737h-3.18c-.738,0-1.279-.018-1.609-.056a3.717,3.717,0,0,1-.919-.225,3.66,3.66,0,0,1-1.879-1.7,4.575,4.575,0,0,1-.519-2.177,4.76,4.76,0,0,1,.473-2.125,3.548,3.548,0,0,1,1.673-1.658,3.96,3.96,0,0,1,1.783-.43,5.848,5.848,0,0,1,1.588.253c.43.12.709.2.815.214a1.755,1.755,0,0,0,.378.035,1.936,1.936,0,0,0,.857-.2" transform="translate(651.93 -1014.276)" fill="#fff"/>
                                <path id="Path_9787" data-name="Path 9787" d="M-524.746,1014.615a7.471,7.471,0,0,0-.549.908c-.224.42-.42.832-.586,1.233a3.806,3.806,0,0,0-.31-.88,1.885,1.885,0,0,0-.737-.716,2.185,2.185,0,0,0-1.092-.287,1.852,1.852,0,0,0-1.224.417,1.231,1.231,0,0,0-.423.953,1.231,1.231,0,0,0,.84,1.175,4.351,4.351,0,0,0,.559.2c.166.046.647.161,1.448.338a3.609,3.609,0,0,1,1.79.855,2.188,2.188,0,0,1,.638,1.554,2.237,2.237,0,0,1-.769,1.714,3.21,3.21,0,0,1-2.289.829,4.756,4.756,0,0,1-.925-.09c-.306-.06-.794-.186-1.452-.37a2.637,2.637,0,0,0-.717-.125,2.24,2.24,0,0,0-1.016.38,10.761,10.761,0,0,0,.74-1.26,7.927,7.927,0,0,0,.519-1.22,4,4,0,0,0,.377,1.039,2.13,2.13,0,0,0,.911.783,3.525,3.525,0,0,0,1.548.326,2.246,2.246,0,0,0,1.386-.373,1.338,1.338,0,0,0,.567-1.1,1.344,1.344,0,0,0-.245-.77,1.8,1.8,0,0,0-.66-.6,3.606,3.606,0,0,0-.721-.288c-.259-.075-.7-.168-1.3-.282a3.413,3.413,0,0,1-1.8-.736,2.06,2.06,0,0,1-.626-1.538,2.124,2.124,0,0,1,.875-1.786,2.967,2.967,0,0,1,1.862-.559,6.424,6.424,0,0,1,1.861.313,2.99,2.99,0,0,0,.712.146,2.653,2.653,0,0,0,.8-.186" transform="translate(583.64 -1014.334)" fill="#fff"/>
                                <path id="Path_9788" data-name="Path 9788" d="M-429.666,1016.19h3.239l-.947.74v6.858c.448.025.818.041,1.115.041a2.592,2.592,0,0,0,1.615-.428,2.235,2.235,0,0,0,.729-1.412,11.414,11.414,0,0,0,.649,1.427,3.971,3.971,0,0,0,.655.938h-7.055l.948-.725v-6.7Z" transform="translate(492.716 -1015.984)" fill="#fff"/>
                                <path id="Path_9789" data-name="Path 9789" d="M-365.381,1016.19a9.122,9.122,0,0,0-1,1.969,4.085,4.085,0,0,0-.349-.808,1.454,1.454,0,0,0-1.35-.627c-.137,0-.382.015-.731.043v6.85l.943.737H-371.1l.953-.737v-6.85c-.348-.028-.594-.043-.739-.043a1.449,1.449,0,0,0-1.346.627,4.894,4.894,0,0,0-.349.808,9.242,9.242,0,0,0-.993-1.969Z" transform="translate(442.662 -1015.984)" fill="#fff"/>
                                <path id="Path_9790" data-name="Path 9790" d="M-295.992,1016.19h2.907a4.809,4.809,0,0,1,2.887.709,3.854,3.854,0,0,1,1.392,3.292,3.931,3.931,0,0,1-1.487,3.519,3.463,3.463,0,0,1-1.151.5,7.735,7.735,0,0,1-1.633.142h-2.915l.954-.737v-6.687Zm2.292.577v7.01c.377.03.636.046.791.046a2.205,2.205,0,0,0,1.942-.911,4.7,4.7,0,0,0,.691-2.743,4.56,4.56,0,0,0-.542-2.374,2.21,2.21,0,0,0-2.059-1.072q-.2,0-.823.043" transform="translate(373.434 -1015.984)" fill="#fff"/>
                                <path id="Path_9791" data-name="Path 9791" d="M-218.786,1077.444a.835.835,0,0,1,.609.246.783.783,0,0,1,.256.579.777.777,0,0,1-.262.589.866.866,0,0,1-.613.238.886.886,0,0,1-.62-.238.775.775,0,0,1-.256-.589.756.756,0,0,1,.256-.583.89.89,0,0,1,.63-.242" transform="translate(305.331 -1070.643)" fill="#fff"/>
                            </g>
                            <g id="Group_19904" data-name="Group 19904" transform="translate(0.276 27.098)">
                                <path id="Path_9792" data-name="Path 9792" d="M-1010.422,1116.087l-.573.517h-1.149c-.378,0-.567-.172-.567-.506v-1.537l.574-.523h2.285l-.57.516h-1.715v1.532Z" transform="translate(1012.71 -1113.006)" fill="#fff"/>
                                <path id="Path_9793" data-name="Path 9793" d="M-985.384,1114.038h.9v2.049h.277l-.616.517H-986.2l.569-.517h.574v-1.532h-.9Zm2.044,0-.578.516h-.562l.563-.516Z" transform="translate(989.052 -1113.006)" fill="#fff"/>
                                <path id="Path_9794" data-name="Path 9794" d="M-956.433,1115.56v.526h1.708l-.573.517h-1.165c-.362,0-.543-.172-.543-.506v-1.537l.543-.523h1.154c.388,0,.583.168.583.5v.514l-.573.508Zm0-.5h1.135v-.5h-1.135Z" transform="translate(963.003 -1113.006)" fill="#fff"/>
                                <path id="Path_9795" data-name="Path 9795" d="M-928.823,1114.554h-1.712l.574-.516h1.127c.393,0,.584.168.584.5v1.548h.569l-.569.517h-.326c-.165,0-.247-.143-.247-.428v-.616h-1.138v.526h1.138l-.569.517h-.591c-.367,0-.552-.172-.552-.506v-.537l.574-.5h1.138Z" transform="translate(939.382 -1113.006)" fill="#fff"/>
                                <path id="Path_9796" data-name="Path 9796" d="M-901.705,1111.81l-.57.517h-.573a.627.627,0,0,1-.453-.126.534.534,0,0,1-.118-.38v-1.553h-.573l1.143-1.023v.511h1.143l-.57.512h-.573v1.542Z" transform="translate(915.697 -1108.729)" fill="#fff"/>
                                <path id="Path_9797" data-name="Path 9797" d="M-882.016,1108.1h.9v2.048h.277l-.615.517h-1.381l.573-.517h.569v-1.532h-.9Zm.891-.717h.576l-.576.516h-.563Z" transform="translate(896.813 -1107.066)" fill="#fff"/>
                                <path id="Path_9798" data-name="Path 9798" d="M-860.859,1114.038h.9v2.049h.278l-.617.517h-1.375l.569-.517h.571v-1.532h-.9Zm2.626.516c0-.346-.2-.516-.59-.516h-.559l-.574.516h1.146v1.543c0,.337.136.506.4.506h.178l.57-.517h-.57Z" transform="translate(877.932 -1113.006)" fill="#fff"/>
                                <path id="Path_9799" data-name="Path 9799" d="M-821.058,1117.115l-.573.516h-2.31c-.366,0-.547-.171-.547-.516v-.511l.573-.517h1.228l.33-.281h-.987a.716.716,0,0,1-.43-.11.424.424,0,0,1-.141-.36v-.775l.551-.523h2.305l-.573.516v1.252l-.313.281h.313a.6.6,0,0,1,.436.132.515.515,0,0,1,.137.385Zm-2.856,0h2.284v-.511h-2.284Zm.571-1.779h1.14v-.782h-1.14Z" transform="translate(844.752 -1113.006)" fill="#fff"/>
                                <path id="Path_9800" data-name="Path 9800" d="M-768.766,1104.45h.862v3.081h.277l-.616.517h-1.4c-.367,0-.549-.172-.549-.506v-1.537l.549-.523h1.16v-.516h-.843Zm-.85,3.081h1.135V1106h-1.135Z" transform="translate(796.301 -1104.45)" fill="#fff"/>
                                <path id="Path_9801" data-name="Path 9801" d="M-745.607,1108.1h.9v2.048h.279l-.612.517h-1.382l.569-.517h.574v-1.532h-.9Zm.888-.717h.573l-.573.516h-.561Z" transform="translate(775.093 -1107.066)" fill="#fff"/>
                                <path id="Path_9802" data-name="Path 9802" d="M-724.689,1105.482h.149v-.516h.014l.537-.516h1.145c.4,0,.588.167.588.506v.526h-.572v-.516h-1.136v.516h.719l-.58.516h-.139v1.532h.569l-.569.517h-1.266l.526-.517h.164V1106h-.69Z" transform="translate(756.182 -1104.45)" fill="#fff"/>
                                <path id="Path_9803" data-name="Path 9803" d="M-694.4,1105.482h.152v-.516h.014l.533-.516h1.15c.392,0,.586.167.586.506v.526h-.575v-.516h-1.135v.516h.722l-.581.516h-.141v1.532h.572l-.572.517h-1.267l.529-.517h.165V1106h-.694Z" transform="translate(729.156 -1104.45)" fill="#fff"/>
                                <path id="Path_9804" data-name="Path 9804" d="M-661.456,1115.56v.526h1.707l-.573.517h-1.159c-.368,0-.549-.172-.549-.506v-1.537l.549-.523h1.149c.392,0,.583.168.583.5v.514l-.573.508Zm0-.5h1.134v-.5h-1.134Z" transform="translate(699.786 -1113.006)" fill="#fff"/>
                                <path id="Path_9805" data-name="Path 9805" d="M-637.384,1114.038h.9v2.049h.282l-.617.517h-1.38l.572-.517h.57v-1.532h-.9Zm2.042,0-.58.516h-.563l.563-.516Z" transform="translate(678.522 -1113.006)" fill="#fff"/>
                                <path id="Path_9806" data-name="Path 9806" d="M-608.444,1115.56v.526h1.713l-.574.517h-1.164c-.367,0-.549-.172-.549-.506v-1.537l.549-.523h1.149c.392,0,.589.168.589.5v.514l-.574.508Zm0-.5h1.14v-.5h-1.14Z" transform="translate(652.481 -1113.006)" fill="#fff"/>
                                <path id="Path_9807" data-name="Path 9807" d="M-584.361,1114.038h.9v2.049h.278l-.611.517h-1.381l.569-.517h.573v-1.532h-.9Zm2.623.516c0-.346-.2-.516-.587-.516h-.563l-.574.516h1.151v1.543c0,.337.131.506.4.506h.177l.569-.517h-.569Z" transform="translate(631.206 -1113.006)" fill="#fff"/>
                                <path id="Path_9808" data-name="Path 9808" d="M-545.707,1111.81l-.574.517h-.57a.622.622,0,0,1-.455-.126.529.529,0,0,1-.118-.38v-1.553h-.569l1.142-1.023v.511h1.143l-.574.512h-.57v1.542Z" transform="translate(598.026 -1108.729)" fill="#fff"/>
                                <path id="Path_9809" data-name="Path 9809" d="M-503.753,1107h-.936l.192.526h1.1l.081-.071h.246l-.665.588h-1.142l-.193-.527-.373.527h-.971l-.5-1.085,1.163-1.256-.271-.74.573-.516h1.713l-.574.516h-1.139l.649,1.779.148-.245h1.208l.111-.085h.237Zm-1.6.526.191-.26-.511-1.365-.772.875.43.75Z" transform="translate(561.367 -1104.45)" fill="#fff"/>
                                <path id="Path_9810" data-name="Path 9810" d="M-449.1,1114.038h1.116l-.559.516h-.271l-.859,2.05h-.572l-.833-2.05h-.3l.57-.516h1.137l-.572.516h-.247l.548,1.18.587-1.18h-.317Z" transform="translate(511.82 -1113.005)" fill="#fff"/>
                                <path id="Path_9811" data-name="Path 9811" d="M-415.519,1114.554h-1.711l.573-.516h1.128c.392,0,.583.168.583.5v1.548h.569l-.569.517h-.326c-.165,0-.247-.143-.247-.428v-.616h-1.139v.526h1.139l-.569.517h-.591c-.367,0-.551-.172-.551-.506v-.537l.573-.5h1.139Z" transform="translate(481.343 -1113.006)" fill="#fff"/>
                                <path id="Path_9812" data-name="Path 9812" d="M-389.869,1104.45h.858v3.081h.285l-.619.517h-1.382l.569-.517h.574v-2.564h-.843Z" transform="translate(457.693 -1104.45)" fill="#fff"/>
                                <path id="Path_9813" data-name="Path 9813" d="M-370.194,1114.038h.9v2.049l-.572.517h-1.159c-.368,0-.552-.175-.552-.517v-1.532h-.569l.569-.516H-371v2.049h1.139v-1.532h-.9Z" transform="translate(441.111 -1113.006)" fill="#fff"/>
                                <path id="Path_9814" data-name="Path 9814" d="M-338.708,1114.554h-1.709l.573-.516h1.13c.387,0,.582.168.582.5v1.548h.569l-.569.517h-.326c-.168,0-.249-.143-.249-.428v-.616h-1.136v.526h1.136l-.567.517h-.59c-.367,0-.552-.172-.552-.506v-.537l.573-.5h1.136Z" transform="translate(412.8 -1113.006)" fill="#fff"/>
                                <path id="Path_9815" data-name="Path 9815" d="M-312.86,1108.048c-.3,0-.451-.172-.451-.506v-2.576h-.5l.556-.516h.519v1.028h1.124c.392,0,.588.169.588.5v1.552l-.569.517Zm.126-.517h1.139v-1.537h-1.139Z" transform="translate(389.057 -1104.45)" fill="#fff"/>
                                <path id="Path_9816" data-name="Path 9816" d="M-284.557,1104.45h.858v3.081h.287l-.621.517h-1.382l.569-.517h.574v-2.564h-.844Z" transform="translate(363.719 -1104.45)" fill="#fff"/>
                                <path id="Path_9817" data-name="Path 9817" d="M-263.682,1115.56v.526h1.711l-.576.517H-263.7c-.367,0-.551-.172-.551-.506v-1.537l.551-.523h1.147c.39,0,.586.168.586.5v.514l-.576.508Zm0-.5h1.135v-.5h-1.135Z" transform="translate(344.838 -1113.006)" fill="#fff"/>
                            </g>
                        </g>
                        <g id="Group_19908" data-name="Group 19908" transform="translate(-1482.204 721.856)">
                            <path id="Path_9818" data-name="Path 9818" d="M-1281.715,721.856v48.737h-2.035V723.484Z" transform="translate(1305.117 -721.856)" fill="#fff"/>
                            <path id="Path_9819" data-name="Path 9819" d="M-1309.967,747.371v46.012l-1.778-1.048V748.491Z" transform="translate(1330.097 -744.624)" fill="#fff"/>
                            <g id="Group_19906" data-name="Group 19906" transform="translate(13.289 20.92)">
                                <path id="Path_9820" data-name="Path 9820" d="M-1354.645,916.161V942.33l-1.321-.718V919.737l-1.321,1.017v20.037l-1.487-1.026V919.827Z" transform="translate(1358.773 -916.161)" fill="#fff"/>
                                <rect id="Rectangle_5885" data-name="Rectangle 5885" width="2.218" height="0.634" transform="translate(3.02 13.461) rotate(180)" fill="#fff"/>
                                <rect id="Rectangle_5886" data-name="Rectangle 5886" width="2.218" height="0.634" transform="translate(3.174 8.82) rotate(180)" fill="#fff"/>
                                <rect id="Rectangle_5887" data-name="Rectangle 5887" width="2.218" height="0.634" transform="translate(3.02 18.152) rotate(180)" fill="#fff"/>
                            </g>
                            <g id="Group_19907" data-name="Group 19907" transform="translate(30.686 20.788)">
                                <path id="Path_9821" data-name="Path 9821" d="M-1197.2,914.935v25.919l1.321-.588v-21.9l1.321,1.2v20l1.487-1.026V918.6Z" transform="translate(1197.195 -914.935)" fill="#fff"/>
                                <rect id="Rectangle_5888" data-name="Rectangle 5888" width="2.219" height="0.634" transform="translate(1.109 12.827)" fill="#fff"/>
                                <rect id="Rectangle_5889" data-name="Rectangle 5889" width="2.218" height="0.634" transform="translate(0.955 8.187)" fill="#fff"/>
                                <rect id="Rectangle_5890" data-name="Rectangle 5890" width="2.219" height="0.634" transform="translate(1.109 17.518)" fill="#fff"/>
                            </g>
                            <path id="Path_9822" data-name="Path 9822" d="M-1246.952,724.2v44.749l-5.026,1.67V721.856Z" transform="translate(1276.765 -721.856)" fill="#fff"/>
                            <path id="Path_9823" data-name="Path 9823" d="M-1462.073,855.188c-9.139-2.055-14.985-9.111-15.365-17.931a17.584,17.584,0,0,1,2.3-8.594c.077-.129.376-.629.517-.859l.1-.155a8.421,8.421,0,0,1,1.862-1.868,11.732,11.732,0,0,1,4.884-2.193l.076-.016.086-.019v0a3.491,3.491,0,0,0,2.593-3.406,3.461,3.461,0,0,0-3.408-3.511,3.309,3.309,0,0,0-1.486.355.188.188,0,0,0-.034.009c-.363.173-.719.357-1.07.547v0c-6.667,3.471-11.18,9.94-11.18,17.6,0,.007,0,.013,0,.02s0,.016,0,.023c0,.071,0,.141.006.212C-1482.07,846.351-1473.739,854.948-1462.073,855.188Z" transform="translate(1482.204 -806.429)" fill="#fff"/>
                            <path id="Path_9824" data-name="Path 9824" d="M-1228.675,835.137a19.482,19.482,0,0,0-8.376-15.866l-.17-.121c-.108-.076-.215-.153-.325-.227s-.247-.166-.372-.247c-.053-.035-.1-.07-.158-.1q-.251-.162-.508-.317l-.06-.036-.1-.059v0q-.547-.325-1.115-.622v0c-.351-.19-.707-.374-1.07-.547a.163.163,0,0,0-.035-.009,3.308,3.308,0,0,0-1.485-.355,3.461,3.461,0,0,0-3.408,3.511,3.49,3.49,0,0,0,2.593,3.406v0l.086.019.076.016a11.736,11.736,0,0,1,4.884,2.193,8.415,8.415,0,0,1,1.861,1.868c.034.051.066.1.1.155.14.23.439.73.516.859a17.584,17.584,0,0,1,2.3,8.594,18.448,18.448,0,0,1-9.031,15.365,25.38,25.38,0,0,1-9.506,2.661,13.611,13.611,0,0,0,1.645.072,23.29,23.29,0,0,0,5.543-.667,19.524,19.524,0,0,0,16.076-18.364c.008-.135.009-.271.014-.407.006-.175.016-.349.018-.526,0-.071.006-.141.006-.212,0-.008,0-.016,0-.023S-1228.675,835.143-1228.675,835.137Z" transform="translate(1276.765 -806.429)" fill="#fff"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Switch location={location} key={'location.pathname'}>
                        {/*<Route exact path="/" component={Landing}/>*/}
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about-us" component={About}/>
                        <Route exact path="/about/leaders" component={Leaders}/>
                        <Route exact path="/about/csr" component={Csr}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path="/media" component={News}/>
                        <Route exact path="/media/:slug" component={MediaDetails}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/landowner" component={Landowners}/>
                        <Route exact path="/buyer" component={Buyer}/>
                        <Route exact path="/customer" component={Customer}/>
                        <Route exact path={`/projects`} component={Project}/>
                        <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer />
                </div>
            </div>
        </React.Fragment>


    );
}

export default App;
