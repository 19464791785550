import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({desc,parallax_text,title}) => {
    return (
        <StyledComponent className={'overview pt-100 pb-200 parallax-img horizontal-scroll'}  data-speed-right={30}>
            <Container>
                <Row>
                    <p className={'scroll-right bg-text-one'}>{parallax_text}</p>
                    <Col md={9} className={'overview__title'}>
                        <h3 className={'split-up'}>{title}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:6,offset:6}} className={'overview__description'}>
                        <p>{ReactHtmlParser(desc)}</p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 100px;
  position: relative;
  
  .overview{

    &__title{
      h3{
        font-size: 40px;
        line-height: 48px;
        font-weight: 400;
        margin-bottom: 60px;
        @media(max-width: 767px){
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 40px;

        }
      }
    }
    &__description{
      p{
        color:#D05110;
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
  }

  .bg-text-one {
    font-size: 200px;
    line-height: 286px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;
    font-family: Gilroy;
    left: -5%;
    top: 50%;
    
    @media (max-width: 767px) {
      bottom: 120px;
      font-size: 120px;
      line-height: 120px;
    }
  }

`;
export default React.memo(MyComponent);

