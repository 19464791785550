import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import CvDrop from "../../components/career/CvDrop";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {fetchLandowner} from "../../api/redux/landowner";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import LandownerForm from "../../components/landowner/LandownerForm";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'landowner',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
            [ApiParamKey.image]: 'yes',

        }

        setTimeout(() => {
            dispath(fetchLandowner([api_url , api_params]))
        }, 500);


    }, [])

    const getData = useSelector((state) => state.landowner);
    const landownerBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'landowner-banner');
    const landownerOverview= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'landowner-overview');
    const landownerForm= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'landowner-form');

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Landowners | Climax Holdings Ltd.</title>
                <meta name="description" content="As a landowner, your vision blossoms into reality with us. We don't just develop land; we nurture
dreams, collaborating hand in hand to create masterpieces that stand the test of time. Trust is the
cornerstone of our relationship, and when you partner with Climax Holdings Ltd., you're not just
investing in a project; you're crafting a legacy.

"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={landownerBanner?.images?.list?.[0]?.full_path}
                             title={landownerBanner?.section_data?.subtitle}/>

                <CvDrop title={landownerOverview?.section_data?.short_desc}
                            backtext={landownerOverview?.section_data?.background_text}
                            description={landownerOverview?.section_data?.description}/>
                <LandownerForm data={landownerForm}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
