import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Link from "react-router-dom"
import Title from "../Title";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import ResponsiveMasonry from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry/lib/Masonry";

const GalleryV1 = ({background, topBg, titleColor, img, data}) => {

const gallery = data?.image;

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img alt='gallery image' src={image} style={{width: "100%"}}/>
        </LightgalleryItem>

    );

    return (
        <StyledComponent id={'gallery'} className={'pb-200'}>

            <Container>
                <div className="gallery-title ">
                    {
                        data?.section_data?.subtitle &&
                        <Title text={data?.section_data?.subtitle}/>
                    }
                </div>
            </Container>


            <LightgalleryProvider
                lightgallerySettings={
                    {
                        download: false,
                        thumbnail: false,
                        fullScreen: true,
                        share: false
                    }
                }
            >
                <ResponsiveMasonry
                    columnsCountBreakPoints={{750: 2, 900: 4}}
                >

                    <Masonry>
                        {
                            data?.images?.list && data?.images?.list?.length>0 &&
                            data?.images?.list?.map((element)=>{
                                return(
                                    <PhotoItem image={element?.full_path} group={'1'} key={element?.id}/>
                                )
                            })
                        }
                    </Masonry>

                </ResponsiveMasonry>
            </LightgalleryProvider>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  background-color: #FFFDFC;

  .title {
    margin-bottom: 60px;
  }

  .gallery-title {
    width: 100%;
  }
  div{
    gap: 6px !important;
  }

  .react_lightgallery_item {
    cursor: pointer;
    //border-top: 1px solid rgba(255,253,251,0.5);
    //border-right: 1px solid rgba(255,253,251,0.5);

  }

  .db-btn {
    background-color: #736640;
  }

  .shadow-bottom {
    position: absolute;
    left: 100px;
    bottom: 250px;
  }

  .shadow-right {
    position: absolute;
    right: 50px;
    bottom: -50px;
  }

  @media (max-width: 767px) {
    .shadow-right {
      bottom: 40px;

      svg {
        height: 40px;
      }
    }
  }

`;
export default React.memo(GalleryV1);

