import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";


import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VisibilitySensor from "react-visibility-sensor";
import ReactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import Modal from "react-bootstrap/Modal";
import SimpleBar from "simplebar-react";

const MyComponent = ({background, data, id}) => {

    const nextRef = useRef()
    const prevRef = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handleOpenTest = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };
    return (
        <StyledComponent className={'pt-200'}>

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="testimonial-title">
                            {
                                data?.section_data?.subtitle &&
                                <h2 className={'split-up'}>
                                    {ReactHtmlParser(data?.section_data?.subtitle)}
                                </h2>
                            }


                        </div>
                    </Col>
                </Row>
                {
                    data?.posts?.list && data?.posts?.list?.length>0 &&
                    <Swiper
                        Swiper modules={[Autoplay, Pagination, Navigation, EffectFade]}
                        allowTouchMove={true}
                        longSwipesMs={500}
                        slidesPerView={1}
                        pagination={{
                            // el: '.swiper-pagination',
                            type: "fraction",
                        }}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        //effect={"fade"}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                        }}
                        shouldSwiperUpdate={(prev, next) => {
                            if (prev.activeIndex === next.slides.length - 1) {
                                setButtonDisabled(true);
                            } else {
                                setButtonDisabled(false);
                            }
                        }}
                        loop={true}
                        speed={900}
                        onSlideChange={(s) => handleSliderCurrent()}
                    >
                        {
                            data?.posts?.list && data?.posts?.list?.length>0 &&
                            data?.posts?.list?.map((element)=>{
                                return(
                                    <SwiperSlide>

                                        <div className="row testimonials__slider d-flex">

                                            <Col sm={{span:4, offset:1}} className="slider-img">
                                                <div className="testimonials__slider__img">
                                                    <div className="testmonials__slider__img__wrapper">
                                                        <Img src={element?.images?.[0]?.full_path} alt={'testimonial image'}/>
                                                    </div>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="74.485" viewBox="0 0 100 74.485">
                                                        <g id="Group_22053" data-name="Group 22053" transform="translate(0 0)">
                                                            <path id="Path_9479" data-name="Path 9479" d="M292.8,96.99h21.368c1.1-9.915-1.4-22.582-21.368-22.582V65.318c41.537,0,42.813,20.608,42.813,31.673V139.8H292.8Z" transform="translate(-292.799 -65.318)" fill="#d05110"/>
                                                            <path id="Path_9480" data-name="Path 9480" d="M0,96.99H21.367c1.1-9.915-1.4-22.582-21.367-22.582V65.317c41.536,0,42.813,20.608,42.813,31.673V139.8H0Z" transform="translate(57.187 -65.317)" fill="#d05110"/>
                                                        </g>
                                                    </svg>


                                                </div>
                                            </Col>

                                            <Col sm={{span:5,offset:1}} className={'testimonials__slider__content'}>
                                                {ReactHtmlParser(element?.data?.description)}
                                                {/*<p className={'split-up'}>The quality of the property developed by Climax Holdings surpassed all our expectations. The attention to detail, impeccable design, and the use of sustainable technologies showcased their commitment to excellence and environmental responsibility. We couldn't be happier with the final result.</p>*/}
                                                <h5 className={'split-up'}>{element?.data?.subtitle}</h5>
                                                <p className={'split-up'}>{element?.data?.short_desc}</p>
                                            </Col>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>
                }



                <Row className={'slider--nav fade-up'}>
                    <Col sm={{span: 6, offset: 6}}>
                        <ul>
                            <li className={'prev_swipper prevhover slider-prev'} ref={prevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                    <g id="Group_22053" data-name="Group 22053" transform="translate(1.414 1.414)">
                                        <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.266) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </li>
                            <li className={'next_swipper nexthover slider-next'} ref={nextRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                    <g id="Group_22053" data-name="Group 22053" transform="translate(1.414 1.414)">
                                        <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.266) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section` {
  overflow: hidden;
  background-color: #3C4348;
  margin-top: -10px !important;
  //padding-bottom: 200px;
  @media(max-width: 767px){
    padding-bottom: 120px;
  }

  .container {
    position: relative;
  }
  .testimonial-title{
    h2 {
      color: white;
      font-size: 60px;
      line-height: 68px;
      margin-bottom: 60px;
      font-family: Century;

      span {
        padding-left: 7%;
      }

      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 40px;
        span {
          padding-left: unset;
        }
      }
    }
  }

  .testimonials__slider {
    .modal-video{
      //position: relative;
      //z-index: 99999;
    }
    height: 100%;
    .slider-img {
      padding: 0;
      .modal-video{
        position: absolute;
        .modal-video-inner{
          //width: 100% !important;
          //padding: 0 !important;
        }
      }
    }

    &__img {
      
      margin-right: 50px;
      min-height: 100%;
      padding-top: calc(400 / 370 * 100%);
      &__wrapper{
        position: relative;
        padding-top: calc(400 / 370 * 100%);
        min-height: 100%;
        cursor: pointer;
        //margin-right: 50px;
      }

      svg {
        position: absolute;
        right: -50px;
        top: -50px;
      }
      .svg-container{
        svg{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          #Ellipse_451 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          }
          #Ellipse_450 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          }

          &:hover {
            #Ellipse_451 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 0;

            }
            #Ellipse_450 {
            }

          }
        }
      }

    }
  }

  .testimonials__slider__content {
    //padding-bottom: 70px;
    padding-left: 30px;
    margin-bottom: 146px;
    @media(max-width: 767px){
      margin-bottom: 0px;
      padding-left: 0;
      padding-right: 0;
    }
    //padding-left: 50px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #FFFDFB;
      &:first-child{
        margin-bottom: 20px;
      }
    }

    h5 {
      margin-top: 60px;
      margin-bottom: 0px;
      font-size: 24px;
      line-height: 32px;
      color: #FFFDFB;
    }


  }

  ul {
    position: absolute;
    bottom: 0;
    left: 15px;
    z-index: 2;
    display: flex;
    gap: 15px;
    @media(max-width: 767px){
      //left: 0;
    }


      .slider-prev{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          transform: rotate(180deg);
          z-index: 5;
        }
      }
      .slider-next{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          z-index: 5;
        }
      }
    }
}

  .swiper {
    padding-top: 60px;
    //z-index: 0;
  }
  .swiper-wrapper{
    //z-index: 0;
  }

  .swiper-button-lock{
    display: block;
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-pagination {

    position: absolute;
    width: fit-content;
    right: 90px;
    left: auto;
    color: #FFFDFB;
    //bottom: 50px;

    font-size: 16px;
    font-weight: 500;

    span {
      color: #FFFDFB;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .slider--nav {
    position: absolute;
    width: 100%;
    bottom: 0px;
    //bottom: 50px;
    //z-index: 99;

  }

  @media (max-width: 767px) {

    .d-flex {
      flex-wrap: wrap;
    }

    .testimonials__slider {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
      .col-sm-6 {
        //padding-left: 0;
        min-width: 100%;
        margin-left: 1px;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .testimonials__slider__img {
      margin-right: 0;
      padding-top: calc(343 / 345 * 100%);
      //img{
      //  height: 100% !important;
      //}
      svg {
        height: 78px;
        right: 0px;
        width: 100px;
        top: -40px;
      }
    }

    .testimonials__slider__content {
      margin-top: 40px !important;
    }

    .slider--nav {
      //position: relative;
      //bottom: 0;
      display: block;
      padding: 0 15px;
      margin-top: 16px;
      top: 110px;
      
      ul{
        bottom: unset;
        top: 60px;
      }

      .col-sm-6 {
        min-width: 100%;
        margin: 0;
      }
    }

    .swiper-pagination {
      right: 15px;
      top: 0;
    }

    .swiper {
      //padding-top: 10px;
      padding-bottom: 10px;
    }

    .slider--nav {
      padding: 0;

    }

  }
  
  .swiper-pagination-current{
    font-size: 36px !important;
    line-height: 40px;
  }
  .swiper-pagination-total{
    font-size: 20px !important;
    line-height: 40px;
    opacity: 20%;
  }




`;


export default React.memo(MyComponent);

