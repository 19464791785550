import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Container} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import ImgParallax from "./ImgParallax";
import {useLocation} from "react-router-dom";
// import a from '../../public/images/static/default-inner-banner.jpg'
// import a from '../../public/images/static/default-inner-banner-2.jpg'
const InnerBanner = ({imgList, text, title,type,location,detail}) => {

    //get the query
    const locations = useLocation();
    const project_status = new URLSearchParams(locations?.search).get("status");
    let filteredImg= project_status===null ? imgList?.filter(element=>element?.short_title==='ongoing') : imgList?.filter(element=>element?.short_title===project_status);

    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img banner={true} src={filteredImg?.[0]?.full_path ? filteredImg?.[0]?.full_path : ''} alt={'project banner'}/>
            <Container>
                <h2 className={''}>{project_status===null ? 'Projects' : filteredImg?.[0]?.short_desc }</h2>
                {
                    detail ?
                        <div className="InnerBanner__desc">
                            <p>{type}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
                                <circle id="Ellipse_60" data-name="Ellipse 60" cx="3" cy="3" r="3" fill="#FFFFFF"/>
                            </svg>
                            <p>{location}</p>
                        </div> : ''
                }

            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(500 / 1366 * 100%);
  position: relative;
  background-color: #DDD;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 94px;
    z-index: 2;
  }

  h2 {
    //position: absolute;
    left: 15px;
    //bottom: 94px;
    color: #ffffff;
    font-size: 60px;
    font-weight: 400;
    line-height: 68px;
    font-family: Century;
    text-transform: uppercase;
    z-index: 2;

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }
  .InnerBanner__desc{
    display: flex;
    align-items: center;
    gap:10px;
    margin-top: 10px;

    p{
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(400 / 375 * 100%);
    .container {
      bottom: 69px;
    }

    .title {
      margin-bottom: 40px !important;
    }

    h2 {
      font-size: 40px;
      line-height: 45px;
    }
  }
`;

export default InnerBanner;
