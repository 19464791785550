import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <div className="back_img">
                <img src="/images/static/concernback.jpg" alt="concernback"/>
            </div>
            <Container className={'container'}>
                <Row className={'management-team'}>
                    <Col md={10}>
                        <h2 className={'split-up'}>{data?.section_data?.subtitle}</h2>
                    </Col>
                    <Col md={2}>
                        <div className="testimonial__head__navigation">
                            {
                                data?.images?.list?.length>3 ?
                                    <ul className={'team_nav'}>
                                        <li id={'gallery-prev33'} className={'prevhover slider-prev'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#3C4348" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#3C4348" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                        <li id={'gallery-next33'} className={'nexthover slider-next'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                                <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                                    <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#3C4348" stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#3C4348" stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>
                                        </li>
                                    </ul> : ''
                            }

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            data?.images?.list && data?.images?.list?.length>0 ?
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={15}
                                    speed='500'
                                    navigation={{
                                        prevEl: '#gallery-prev33',
                                        nextEl: '#gallery-next33',
                                    }}
                                    // initialSlide={1}
                                    loop={true}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,

                                        },
                                        1024: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,

                                        },
                                        1025: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}

                                    className="mySwiper"
                                >
                                    {
                                        data?.images?.list && data?.images?.list?.length>0 &&
                                        data?.images?.list?.map((element)=>{
                                            return(
                                                <SwiperSlide>
                                                    <div className="testimonial__single">
                                                        <div className="testimonial__single__img">
                                                            <Img src={element?.full_path} alt={'concern image'}/>
                                                        </div>
                                                        <div className="testimonial__single__desc">
                                                            <h4 className={'split-up'}>{element?.short_title}</h4>
                                                            <p className={'split-up'}>{element?.short_desc}</p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }



                                </Swiper> : ''

                        }

                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  position: relative;
  .back_img{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .management-team{
    margin-bottom: 60px;
    h2{
      font-family: Century;
      font-size: 60px;
      line-height: 68px;
      color: #ffffff;
      @media(max-width: 767px){
        font-size: 40px;
        line-height: 48px;
      }
    }
    .col-md-2{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    @media(max-width: 767px){
      margin-bottom: 40px;
      .col-md-10{
        margin-bottom: 40px;
      }
    }
  }
  .testimonial__single{
    &__img{
      position: relative;
      padding-top: calc(270 / 370 * 100%);
      margin-bottom: 20px;
    }
    &__desc{
      h4{
        margin-bottom: 10px;
        font-family: Ubuntu;
        color: #ffffff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }
      p{
        color: #ffffff;
      }
    }
  }

  .testimonial__head__navigation{
    .team_nav{
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      @media(max-width: 767px){
        justify-content: flex-start;
      }
      .slider-prev{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          transform: rotate(180deg);
          z-index: 5;
        }
      }
      .slider-next{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          z-index: 5;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
