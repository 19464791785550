import React, {useEffect, useRef, useState} from "react";
import Select, {components} from "react-select";
import { hover} from "../../styles/globalStyleVars";
import {BsChevronDown, BsX} from "react-icons/bs";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
// import iDropdown from "../../public/images/static/i-dropdown.svg";
import Button from "../Button";
import {Link} from 'react-router-dom';
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : '#221F1F',
        backgroundColor: state.isSelected ? '#D05110' : '#FFF',
        margin: 0,
        fontSize: 12,
        cursor: 'pointer',
        paddingLeft: 25,
        letterSpacing: 2,
        textTransform : 'capitalize',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,
        borderRadius: 23,
        fontSize: 12,
        zIndex:100

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '600',
        color: '#FFF',
        fontSize: 12,
        letterSpacing: 2,
        textTransform : 'uppercase',

    }),

};


const IndustrialLube = ({queryParam,filterOption,data}) => {

    const [show, setShow] = useState(false);
    const [popupCategory, setPopupCategory] = useState('')
    const [popupTitle, setPopupTitle] = useState('')
    const [filteredData, setFilteredData] = useState(data);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedImageName, setSelectedImageName] = useState('');
    const [cv, setCv] = useState(null)
    const [photo, setPhoto] = useState(null)

    //dynamic options
    const options = filterOption?.map(element => ({value: element?.value, label:element?.value}));

    //Filter the joblist
    //If it gets the filtered data then it will show the filtered data otherwise it will show the full joblist
    let joblist=filteredData?.length ? filteredData : (data?.filter((element)=>element?.data?.category_title===filterOption?.[0]?.value) );

    const handleClose = () => setShow(false);

    const handleShow = (element,title) =>{
        setShow(true);
        setPopupCategory(element)
        setPopupTitle(title)
    }

    const handleInputcv = (e) => {
        setCv(e.target.files[0])
        setSelectedFileName(e.target.files?.[0]?.name);

    }
    const handleInputPhoto = (e) => {
        setPhoto(e.target.files[0])
        setSelectedImageName(e.target.files?.[0]?.name);
    }

    let handleMobileSelect = (e) => {
        let filteredItems=data?.filter((element)=>element?.data?.category_title===e);
        setFilteredData(filteredItems);
    }
    const getInfo=(filterItem,index)=>{

        let filteredItems=data?.filter((element)=>element?.data?.category_title===filterItem);
        setFilteredData(filteredItems);

        let navLinks=document.querySelectorAll('.job-lists__sidebar ul li');
        for(let j=0;j<=navLinks.length;j++)
        {
            if(j===index){
                navLinks[j]?.classList?.add('active')
            }
            else navLinks[j]?.classList?.remove('active')
        }
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    //Form submission

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);
    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('file', cv);
        formData.append('photo',photo);
        formData.append('form_id', 'career-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '' && cv!='' && photo!='') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedFileName('');
        setSelectedImageName('');
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };


    return (
        <StyledFaq className="job-lists pb-160">

            <Container>

                <Row>
                    <Col sm={4} className='job-lists__sidebar'>
                        <div className="job-lists__sidebar__mobile-dropdown">
                            <Select onChange={op => handleMobileSelect(op.value)} styles={customStyles}
                                    components={{DropdownIndicator}}
                                    className='select-here files'
                                    placeholder={options?.[0]?.value}
                                    options={options}/>
                        </div>


                        <ul className='desktop-lists'>
                            {
                                filterOption && filterOption?.length>0 &&
                                filterOption?.map((element,index)=>{
                                    const isActive = !filteredData?.length && index === 0;
                                    return(
                                        <li onClick={() => getInfo(element?.value,index)}
                                            className={isActive ? 'active' : ''}
                                        ><a>{element?.value}</a></li>
                                    )

                                })
                            }


                        </ul>
                    </Col>

                    <Col sm={8} className='job-lists__content'>
                        <Accordion defaultActiveKey="0">
                            {
                                joblist && joblist?.length>0 &&
                                joblist?.map((element,index)=>{
                                    return(
                                        <Accordion.Item eventKey={`${index}`}>
                                            <Accordion.Header>
                                                {reactHtmlParser(element?.data?.title)}
                                                <span className={'prevhover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.22" height="6.01" viewBox="0 0 11.22 6.01">
                                            <path id="Path_8257" data-name="Path 8257" d="M0,0,4.2,3.6,8.4,0" transform="translate(9.81 4.6) rotate(180)" fill="none" stroke="#fffdfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {/*<ul>*/}
                                                {/*    <h4>Job Description</h4>*/}
                                                {/*    <li>Schedule and coordinate completion/Well Work activities</li>*/}
                                                {/*    <li>This position is a designated as safety sensitive.</li>*/}
                                                {/*</ul>*/}

                                                {/*<ul>*/}
                                                {/*    <h4>Requirements & Skills</h4>*/}
                                                {/*    <li>Schedule and coordinate completion/Well Work activities such as frac,*/}
                                                {/*        workover, coil tubing, e-line.*/}
                                                {/*    </li>*/}
                                                {/*    <li>Ensure all work is executed onsite in a safe, efficient, and environmentally*/}
                                                {/*        sound manner.*/}
                                                {/*    </li>*/}
                                                {/*</ul>*/}

                                                {ReactHtmlParser(element?.data?.description)}
                                                <div className="button-group d-flex" onClick={() => handleShow(element?.data?.category_title,element?.data?.title)}>
                                                    <Button text={'Apply'} background={'#D05110'} color={'#ffffff'} hoverBackground={'#3C4348'} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )

                                })
                            }
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} className='job-modal' onHide={handleClose}>
                <Modal.Body>
                    <div className="modal-data">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="modal-title">
                                        <h5>{popupCategory}</h5>
                                        <div onClick={handleClose} className="modal-close dc-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g id="Group_1979" data-name="Group 1979" transform="translate(-1242 -47)">
                                                    <circle id="Ellipse_85" data-name="Ellipse 85" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#D05110"/>
                                                    <circle id="Ellipse_86" data-name="Ellipse 86" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#fffdfc"/>
                                                    <g id="Group_1821" data-name="Group 1821" transform="translate(1260 65)">
                                                        <path id="Path_1745" data-name="Path 1745" d="M1097.785,2831.737l13.742,13.742" transform="translate(-1097.785 -2831.737)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                        <path id="Path_1746" data-name="Path 1746" d="M0,0,13.742,13.742" transform="translate(0 13.742) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Title text={popupTitle} color={'#FFFDFB'}/>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Col md={{span:10,offset:1}} className='modal-data__content'>
                                <Form onSubmit={handleSubmit(onSubmit,onError)}>
                                    <input name={'spam_protector'} type='hidden'/>
                                    <input name={'form_id'}  value={'career-form'} type='hidden'/>
                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("name",{
                                            required: 'Name is required',
                                            pattern: {
                                                value: /([A-Z])\w+/,
                                                message: 'Name must contain only letters',
                                            },

                                        })}
                                        type="text" placeholder="Full Name"/>
                                    <div className="form__phoneEmail">
                                        <Form.Control
                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("phone",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your phone first'
                                                },
                                                pattern:{
                                                    value:/^01[0-9]{9}$/,
                                                    message:'please enter a valid 11 digit phone number'
                                                }
                                            })}

                                            type="number" placeholder="Phone Number*"/>
                                        <Form.Control
                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("email",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your email'
                                                },
                                                pattern:{
                                                    value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message:'please enter a valid email address'
                                                }
                                            })}

                                            type="email" placeholder="Email*"/>
                                    </div>
                                    <Form.Control
                                        className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("message",{
                                            required:{
                                                // value:true,
                                                message:'please enter your Message'
                                            },

                                        })}
                                        type="textarea" placeholder="Message"/>

                                    <div className="button-group">
                                        <div className="attachCvName">
                                            <div className="attach-cv">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" style={{ display: selectedImageName ? "none" : "block" }}>
                                                    <path id="avatar" d="M15,5A10,10,0,1,0,25,15,10.011,10.011,0,0,0,15,5Zm4.211,17.891a8.916,8.916,0,0,1-8.421,0V18.684a2.1,2.1,0,0,1,1.1-1.839l2.737,2.737a.526.526,0,0,0,.744,0l2.737-2.737a2.1,2.1,0,0,1,1.1,1.839ZM15,15.526a3.158,3.158,0,1,1,3.158-3.158A3.162,3.162,0,0,1,15,15.526Zm1.887,1.053L15,18.466l-1.887-1.887Zm3.376,5.641V18.684a3.163,3.163,0,0,0-2.553-3.1,4.211,4.211,0,1,0-5.421,0,3.162,3.162,0,0,0-2.553,3.1v3.535a8.947,8.947,0,1,1,10.526,0Z" transform="translate(-5 -5)" fill="#fffdfc"/>
                                                </svg>

                                                <Form.Control
                                                    {...register('photo')}
                                                    type="file"
                                                    accept=".jpg"
                                                    id="image-upload"
                                                    style={{ display: "none" }}
                                                    onChange={handleInputPhoto}
                                                />
                                                <Form.Label htmlFor="image-upload" className="image-upload-label" style={{ display: selectedImageName ? "none" : "block" }}>
                                                    Upload Photo
                                                </Form.Label>
                                                {selectedImageName && (
                                                    <div className="file-name">
                                                        {/*{selectedImageName}*/}
                                                        {selectedImageName.length > 10 ? (
                                                            <>
                                                                {selectedImageName.substring(0, 10)}...{selectedImageName.split('.').pop()}
                                                            </>
                                                        ) : (
                                                            selectedImageName
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="attach-cv">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24" viewBox="0 0 26.268 24" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    <g id="Group_15866" data-name="Group 15866" transform="translate(-12252 -863)">
                                                        <path id="Path_6994" data-name="Path 6994" d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z" transform="translate(12147.724 840.902)" fill="#fffdfb"/>
                                                        <path id="Path_6995" data-name="Path 6995" d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z" transform="translate(12252 801.07)" fill="#fffdfb"/>
                                                    </g>
                                                </svg>
                                                <Form.Control
                                                    {...register('file')}
                                                    type="file"
                                                    accept=".pdf"
                                                    id="resume-upload"
                                                    style={{ display: "none" }}
                                                    onChange={handleInputcv}
                                                />
                                                <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                    Attach CV
                                                </Form.Label>
                                                {selectedFileName && (
                                                    <div className="file-name">
                                                        {/*{selectedFileName}*/}
                                                        {selectedFileName.length > 10 ? (
                                                            <>
                                                                {selectedFileName.substring(0, 10)}...{selectedFileName.split('.').pop()}
                                                            </>
                                                        ) : (
                                                            selectedFileName
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={2}>
                                                <div onClick={handleSubmit(onSubmit,onError)}>
                                                    <Button text={'Submit'} fontWeight={'700'} background={'#ffffff'} color={'#D05110'} svgColor={'#D05110'}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  min-height: 70vh;
  background: #F5F5F5;
  .job-modal .modal-title{
    margin-top: 15px !important;
  }

  .modal-body{
    padding: 0px !important; ;
  } 


  .top-title{
    display: flex;
    .col-sm-8{
      padding: 0; 
    }
    margin-bottom: 60px;
    h4{
      font-size: 36px;
      line-height: 40px;
      font-weight: 600;
    }
  }
  
  
  .job-lists__sidebar {

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }

      img {
        margin: auto;
      }
    }

    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
    }

    ul {
      @media (max-width: 550px) {
        display: none;
      }

      li {
        margin-bottom: 10px;

        a {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          position: relative;
          font-family: Century;
          cursor: pointer;
 
          &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 7px;
            bottom: 0;
            right: -15px;
            margin: auto;
            height: 10px;
            width: 10px;
            background-color: ${hover};
            display: none;
          }
        }

        &.active {
          a {
            color: ${hover};

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .accordion-item {
    margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #3C4348;
      font-size: 24px;
      font-family: Century;
      font-weight: 400;
      line-height: 32px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid #3C4348;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      &.collapsed {
        color: #1C1718;
        border-color: #3C4348;

        span {
          background-color: #1C1718;

          svg {
            transform: rotate(180deg);
          }

          &:after {
            background-color: ${hover} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      color: #ffffff;
      background-color: ${hover};
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        //height: 0;
        //width: 0;
        background-color: #252525;
        //border-radius: 50%;
        //opacity: 0;
        //transition: all .4s ease;
        //content: '';
        //position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(0deg);
      }

    }

    &:hover {
      span {
        //&:after {
        //  height: 100%;
        //  width: 100%;
        //  opacity: 1;
        //}
      }

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    margin-bottom: 80px;
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      padding-top: 40px;
      color: rgba(28, 23, 24, 0.8);
    ;
    }

    ul {
      margin-top: 40px;

      h4 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 40px;
      }

      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;

        &:after {
          position: absolute;
          content: '';
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: ${hover};
          left: 0px;
          top: 8px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  
  
  
  .button-group {
    margin-top: 45px;
  }




  .css-1u9des2-indicatorSeparator{
    background-color: transparent;
  }
  .css-1dimb5e-singleValue {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #3C4348 !important;
  }
  .css-1jqq78o-placeholder{
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #3C4348 !important;
    padding-left: 36px;
  }



  .css-13cymwt-control{
     border-radius: 23px;
     border: 1px solid ${hover} !important;
   }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 23px !important;
    border-color: 1px solid ${hover};
    height: 40px;
    background-color: ${hover};
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 2px !important;
    text-transform: capitalize !important;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
    margin-top: 2px;
  }


  @media (max-width: 767px) {
    &.dc-btn a{
      width: 100% !important;
    }
    overflow: visible;
    .container {
      overflow: visible;
    }

    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        max-width: 100%;
      }
    }
  }

`;

export default React.memo(IndustrialLube);


