import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../styles/globalStyleVars";

const MyComponent = () => {
    return (
        <StyledComponent className="social-icons">
                <a target='_blank' href="https://www.facebook.com/climaxholdingsltd/" className="social-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="15.022" viewBox="0 0 7 15.022">
                        <path id="Path_26" data-name="Path 26" d="M1013.773,3919.917v-1.295a2.834,2.834,0,0,1,.027-.451.876.876,0,0,1,.123-.314.5.5,0,0,1,.309-.214,2.2,2.2,0,0,1,.57-.06h1.294V3915h-2.069a3.365,3.365,0,0,0-2.58.853,3.588,3.588,0,0,0-.784,2.511v1.559h-1.549v2.589h1.549v7.511h3.108v-7.511h2.07l.273-2.589Z" transform="translate(-1009.116 -3914.995)" fill="#fffdfc"/>
                    </svg>
                </a>
                <a target='_blank' href="https://www.instagram.com/climaxholdingsltd/" className="social-icon">
                    <svg id="Group_22053" data-name="Group 22053" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                        <path id="Path_5912" data-name="Path 5912" d="M6.5,1.171c1.736,0,1.941.007,2.627.038a3.6,3.6,0,0,1,1.207.224,2.014,2.014,0,0,1,.747.486,2.014,2.014,0,0,1,.486.747,3.6,3.6,0,0,1,.224,1.207c.031.685.038.891.038,2.627s-.007,1.941-.038,2.627a3.6,3.6,0,0,1-.224,1.207,2.152,2.152,0,0,1-1.234,1.234,3.6,3.6,0,0,1-1.207.224c-.685.031-.891.038-2.627.038s-1.941-.007-2.627-.038a3.6,3.6,0,0,1-1.207-.224,2.014,2.014,0,0,1-.747-.486,2.014,2.014,0,0,1-.486-.747,3.6,3.6,0,0,1-.224-1.207c-.031-.685-.038-.891-.038-2.627s.007-1.941.038-2.627a3.6,3.6,0,0,1,.224-1.207,2.014,2.014,0,0,1,.486-.747,2.014,2.014,0,0,1,.747-.486,3.6,3.6,0,0,1,1.207-.224c.685-.031.891-.038,2.627-.038M6.5,0C4.735,0,4.513.007,3.82.039a4.772,4.772,0,0,0-1.578.3,3.186,3.186,0,0,0-1.151.75,3.186,3.186,0,0,0-.75,1.151,4.772,4.772,0,0,0-.3,1.578C.007,4.513,0,4.735,0,6.5S.007,8.487.039,9.18a4.772,4.772,0,0,0,.3,1.578,3.186,3.186,0,0,0,.75,1.151,3.188,3.188,0,0,0,1.151.75,4.772,4.772,0,0,0,1.578.3c.693.032.915.039,2.68.039s1.987-.007,2.68-.039a4.772,4.772,0,0,0,1.578-.3,3.323,3.323,0,0,0,1.9-1.9,4.772,4.772,0,0,0,.3-1.578c.032-.693.039-.915.039-2.68s-.007-1.987-.039-2.68a4.772,4.772,0,0,0-.3-1.578,3.188,3.188,0,0,0-.75-1.151,3.186,3.186,0,0,0-1.151-.75A4.772,4.772,0,0,0,9.18.039C8.487.007,8.265,0,6.5,0" fill="#fff"/>
                        <path id="Path_5913" data-name="Path 5913" d="M24.014,20.676a3.338,3.338,0,1,0,3.338,3.338,3.338,3.338,0,0,0-3.338-3.338m0,5.5a2.167,2.167,0,1,1,2.167-2.167,2.167,2.167,0,0,1-2.167,2.167" transform="translate(-17.514 -17.514)" fill="#fff"/>
                        <path id="Path_5914" data-name="Path 5914" d="M61.647,15.493a.78.78,0,1,1-.78-.78.78.78,0,0,1,.78.78" transform="translate(-50.897 -12.462)" fill="#fff"/>
                    </svg>
                </a>
                <a target='_blank' href="https://www.linkedin.com/company/chl-climax-holdings-limited/" className="social-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10.391" viewBox="0 0 11 10.391">
                        <path id="LinkedIn" d="M1098.435,3922.677v4.02h-2.358v-3.751c0-.942-.341-1.585-1.2-1.585a1.289,1.289,0,0,0-1.209.853,1.57,1.57,0,0,0-.078.568v3.915h-2.359s.031-6.353,0-7.011h2.358v.994l-.015.022h.015v-.022a2.344,2.344,0,0,1,2.125-1.159C1097.271,3919.521,1098.435,3920.523,1098.435,3922.677Zm-9.666-6.371a1.216,1.216,0,1,0-.031,2.423h.016a1.216,1.216,0,1,0,.016-2.423Zm-1.194,10.391h2.358v-7.011h-2.358Z" transform="translate(-1087.435 -3916.306)" fill="#fffdfc"/>
                    </svg>
                </a>
                <a target='_blank' href="https://www.youtube.com/@ClimaxHoldingsLtd" className="social-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7.7" viewBox="0 0 11 7.7">
                        <path id="Path_29" data-name="Path 29" d="M1177.059,3919.484c-.4-.469-1.131-.661-2.531-.661h-5.082c-1.432,0-2.178.2-2.573.7a3.573,3.573,0,0,0-.386,2.2v1.894c0,1.925.458,2.9,2.959,2.9h5.082a3.213,3.213,0,0,0,2.322-.583,3.043,3.043,0,0,0,.637-2.32v-1.894A3.486,3.486,0,0,0,1177.059,3919.484Zm-3.509,3.451-2.308,1.2a.355.355,0,0,1-.519-.313v-2.392a.354.354,0,0,1,.17-.3.36.36,0,0,1,.348-.012l2.308,1.192a.352.352,0,0,1,0,.626Z" transform="translate(-1166.487 -3918.824)" fill="#fffdfc"/>
                    </svg>
                </a>
                {/* Add other social media icons similarly */}
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: fixed;
  z-index: 5;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(max-width: 767px){
    display: none;
  }

  .social-icon {
    margin-bottom: 10px;
    height: 50px;
    width: 50px;
    background-color: ${hover};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  






`;

export default MyComponent;
