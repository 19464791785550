import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "./Button";
import {title} from "../styles/globalStyleVars";

const MyComponent = ({close}) => {
    return (
        <StyledComponent>
            <div className="back_img">
                <img src={"./images/dynamic/landing.jpg"} alt="back-image"/>
            </div>
            <Container>

                <div className="logo">
                    {/*<img src={'images/static/menu-logo.png'} alt=""/>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="64.5" viewBox="0 0 200 64.5">
                        <g id="Group_19909" data-name="Group 19909" transform="translate(1482.204 -721.856)">
                            <g id="Group_19905" data-name="Group 19905" transform="translate(-1417.353 740.038)">
                                <path id="Path_9774" data-name="Path 9774" d="M-233.5,884.461H-238.2a3.522,3.522,0,0,1,.587-1.923,1.959,1.959,0,0,0,.415-1.015.348.348,0,0,0-.111-.244l-6-9.407q-4.063,7.694-6.005,11.124a86.939,86.939,0,0,1-5.9,9.4,31.418,31.418,0,0,1-6.427,6.684c-.407,0-.972-.358-1.671-1.062a3.034,3.034,0,0,1-1.035-2.079c0-.2.242-.3.737-.3q2.915,0,7.512-6.423t11.219-19.826l-7.519-11.975a5.662,5.662,0,0,1-.623-1.417,5.3,5.3,0,0,1-.254-1.716h4.742a2.746,2.746,0,0,1-.577,1.716,2.608,2.608,0,0,0-.575.964,1.182,1.182,0,0,0,.166.454l6.008,9.6a57.3,57.3,0,0,0,5.212-9.359,11.043,11.043,0,0,0,.9-4.1v-.8a11.161,11.161,0,0,0,1.094,2.026,13.705,13.705,0,0,0,1.345,1.71,7.391,7.391,0,0,1-1.028,3.092q-1.316,2.527-5.954,10l7.35,11.685c.458.708.72,1.143.8,1.31a4.981,4.981,0,0,1,.3,1.872" transform="translate(368.644 -852.765)" fill="#fff"/>
                                <g id="Group_19902" data-name="Group 19902" transform="translate(0.627 1.847)">
                                    <path id="Path_9775" data-name="Path 9775" d="M-1007.4,884.343a4.1,4.1,0,0,1-2.754-.781,1.8,1.8,0,0,1-.475-2.081l3.8-12.559a3.69,3.69,0,0,1,1.787-2.085,6.371,6.371,0,0,1,3.189-.775h14.46l-.679,2.278h-9.835l-4.4,13.72h10.063l-.688,2.284Z" transform="translate(1010.761 -866.062)" fill="#fff"/>
                                    <path id="Path_9776" data-name="Path 9776" d="M-844.415,884.343h-18.57l5.544-18.281h8.729l-4.857,16h9.835Z" transform="translate(883.509 -866.062)" fill="#fff"/>
                                    <path id="Path_9777" data-name="Path 9777" d="M-700.917,884.343h-8.735l5.552-18.281h8.736Z" transform="translate(751.473 -866.062)" fill="#fff"/>
                                    <path id="Path_9778" data-name="Path 9778" d="M-594.512,884.343h-8.7l3.23-10.6-8.772,10.6h-3.571l-2.506-10.6-3.228,10.6h-3.266l5.547-18.281h7.9l2.4,9.99,8.274-9.99h8.239Z" transform="translate(675.413 -866.062)" fill="#fff"/>
                                    <path id="Path_9779" data-name="Path 9779" d="M-380.205,884.343h-8.74l1.754-5.731h-5.473l-1.741,5.731h-8.739l4.679-15.421a3.628,3.628,0,0,1,1.779-2.085,6.385,6.385,0,0,1,3.186-.775h14.729a4.145,4.145,0,0,1,2.76.775,1.817,1.817,0,0,1,.472,2.085Zm-9.341-16-2.433,8h5.468l2.434-8Z" transform="translate(487.535 -866.062)" fill="#fff"/>
                                </g>
                                <g id="Group_19903" data-name="Group 19903" transform="translate(0 22.432)">
                                    <path id="Path_9780" data-name="Path 9780" d="M-1014.9,1016.19h4.183l-1.221.955v3.734a9.38,9.38,0,0,0,2.373.257,9.473,9.473,0,0,0,1.46-.1v-3.89l-1.222-.955h4.179l-1.226.955v8.626l1.226.95h-4.179l1.222-.95v-4a13.371,13.371,0,0,1-1.662.1,9.16,9.16,0,0,1-2.171-.208v4.1l1.221.95h-4.183l1.229-.95v-4.109a2.331,2.331,0,0,0-.917.184,1.687,1.687,0,0,0-.683.518,2.06,2.06,0,0,1,1.6-1.473v-3.747Z" transform="translate(1015.275 -1015.924)" fill="#fff"/>
                                    <path id="Path_9781" data-name="Path 9781" d="M-930.41,1014.341A4.37,4.37,0,0,1-927,1015.7a6.092,6.092,0,0,1,1.318,4.243,5.877,5.877,0,0,1-1.318,4.076,4.335,4.335,0,0,1-3.409,1.368,4.343,4.343,0,0,1-3.412-1.368,6.039,6.039,0,0,1-1.313-4.165,6.018,6.018,0,0,1,1.313-4.154,4.415,4.415,0,0,1,3.407-1.364m-.083.685a2.31,2.31,0,0,0-1.6.621,2.97,2.97,0,0,0-.873,1.527,10.524,10.524,0,0,0-.279,2.672,7.638,7.638,0,0,0,.638,3.526,2.529,2.529,0,0,0,.95.965,2.562,2.562,0,0,0,1.321.365,2.351,2.351,0,0,0,1.612-.613c.767-.666,1.152-2.053,1.152-4.151a12.7,12.7,0,0,0-.153-2.13,4.621,4.621,0,0,0-.485-1.447,2.554,2.554,0,0,0-.959-.964,2.6,2.6,0,0,0-1.327-.371" transform="translate(946.261 -1014.332)" fill="#fff"/>
                                    <path id="Path_9782" data-name="Path 9782" d="M-859.829,1016.19h4.183l-1.226.955v8.846c.574.032,1.057.052,1.437.052a3.323,3.323,0,0,0,2.081-.552,2.965,2.965,0,0,0,.949-1.822,14.592,14.592,0,0,0,.832,1.841,5.207,5.207,0,0,0,.848,1.21h-9.1l1.222-.936v-8.641Z" transform="translate(881.419 -1015.924)" fill="#fff"/>
                                    <path id="Path_9783" data-name="Path 9783" d="M-792.689,1016.19h3.75a6.2,6.2,0,0,1,3.725.914,4.979,4.979,0,0,1,1.8,4.247c0,2.161-.65,3.672-1.926,4.539a4.37,4.37,0,0,1-1.488.648,9.9,9.9,0,0,1-2.107.183h-3.754l1.221-.95v-8.626Zm2.951.745v9.044c.486.039.832.06,1.023.06a2.84,2.84,0,0,0,2.505-1.175,6.054,6.054,0,0,0,.9-3.539,5.9,5.9,0,0,0-.7-3.062,2.859,2.859,0,0,0-2.659-1.383c-.179,0-.528.019-1.066.055" transform="translate(823.604 -1015.924)" fill="#fff"/>
                                    <path id="Path_9784" data-name="Path 9784" d="M-718.818,1016.19h4.183l-1.222.955v8.626l1.222.95h-4.183l1.225-.95v-8.626Z" transform="translate(759.993 -1015.924)" fill="#fff"/>
                                    <path id="Path_9785" data-name="Path 9785" d="M-678.34,1024.719v-5.479a4.567,4.567,0,0,0-.372-2.1,2.338,2.338,0,0,0-1.409-.946h4.263a2.3,2.3,0,0,0-1.4.946,4.368,4.368,0,0,0-.375,2.1v7.481h-1.4l-4.8-8.786v5.738a4.413,4.413,0,0,0,.375,2.113,2.275,2.275,0,0,0,1.406.936h-4.26a2.351,2.351,0,0,0,1.409-.95,4.507,4.507,0,0,0,.371-2.1v-6.528l-1.229-.955h4.509l-1.221.955Z" transform="translate(732.011 -1015.924)" fill="#fff"/>
                                    <path id="Path_9786" data-name="Path 9786" d="M-598.33,1014.663l-1.622,3.009a3.623,3.623,0,0,0-.549-1.632,2.21,2.21,0,0,0-.846-.754,2.879,2.879,0,0,0-1.372-.321,3.058,3.058,0,0,0-2.31.955,3.359,3.359,0,0,0-.838,1.377,7.686,7.686,0,0,0-.327,2.336q0,4.753,4.155,4.757c.266,0,.748-.02,1.45-.06v-4.044h-2.417q-1.794,0-1.793-1.116a.855.855,0,0,1,.243-.61.769.769,0,0,1,.578-.248.607.607,0,0,1,.428.152.477.477,0,0,1,.165.375.365.365,0,0,1-.1.3.775.775,0,0,1-.384.15c-.075.013-.121.055-.121.125,0,.123.2.182.588.182h5.766l-1.225.949v3.577l1.225.95h-4.1c-.952,0-1.65-.024-2.076-.072a4.8,4.8,0,0,1-1.186-.291,4.722,4.722,0,0,1-2.424-2.19,5.9,5.9,0,0,1-.67-2.809,6.141,6.141,0,0,1,.61-2.742,4.578,4.578,0,0,1,2.158-2.139,5.11,5.11,0,0,1,2.3-.555,7.543,7.543,0,0,1,2.048.326c.555.155.914.254,1.051.276a2.263,2.263,0,0,0,.488.045,2.5,2.5,0,0,0,1.105-.26" transform="translate(664.643 -1014.276)" fill="#fff"/>
                                    <path id="Path_9787" data-name="Path 9787" d="M-522.77,1014.694a9.609,9.609,0,0,0-.708,1.171c-.289.542-.542,1.073-.756,1.59a4.913,4.913,0,0,0-.4-1.136,2.432,2.432,0,0,0-.951-.923,2.817,2.817,0,0,0-1.409-.371,2.388,2.388,0,0,0-1.58.537,1.589,1.589,0,0,0-.546,1.229,1.588,1.588,0,0,0,1.083,1.515,5.6,5.6,0,0,0,.721.261c.215.06.835.207,1.868.437a4.654,4.654,0,0,1,2.309,1.1,2.822,2.822,0,0,1,.823,2,2.886,2.886,0,0,1-.993,2.211,4.141,4.141,0,0,1-2.952,1.069,6.133,6.133,0,0,1-1.193-.116c-.4-.077-1.025-.239-1.873-.477a3.405,3.405,0,0,0-.924-.161,2.888,2.888,0,0,0-1.311.491,13.877,13.877,0,0,0,.955-1.625,10.22,10.22,0,0,0,.67-1.574,5.158,5.158,0,0,0,.486,1.34,2.751,2.751,0,0,0,1.175,1.01,4.548,4.548,0,0,0,2,.421,2.9,2.9,0,0,0,1.789-.48,1.726,1.726,0,0,0,.732-1.423,1.734,1.734,0,0,0-.317-.993,2.319,2.319,0,0,0-.851-.769,4.651,4.651,0,0,0-.93-.372c-.335-.1-.9-.216-1.676-.364a4.4,4.4,0,0,1-2.319-.949,2.657,2.657,0,0,1-.807-1.984,2.74,2.74,0,0,1,1.129-2.3,3.828,3.828,0,0,1,2.4-.721,8.289,8.289,0,0,1,2.4.4,3.853,3.853,0,0,0,.918.188,3.426,3.426,0,0,0,1.038-.24" transform="translate(598.743 -1014.332)" fill="#fff"/>
                                    <path id="Path_9788" data-name="Path 9788" d="M-429.666,1016.19h4.179l-1.222.955v8.846c.578.032,1.056.052,1.438.052a3.342,3.342,0,0,0,2.083-.552,2.882,2.882,0,0,0,.94-1.822,14.739,14.739,0,0,0,.837,1.841,5.126,5.126,0,0,0,.845,1.21h-9.1l1.222-.936v-8.641Z" transform="translate(511.001 -1015.924)" fill="#fff"/>
                                    <path id="Path_9789" data-name="Path 9789" d="M-363.005,1016.19a11.77,11.77,0,0,0-1.285,2.54,5.265,5.265,0,0,0-.45-1.042,1.875,1.875,0,0,0-1.742-.809c-.177,0-.493.019-.943.055v8.836l1.216.95h-4.173l1.229-.95v-8.836c-.449-.036-.766-.055-.953-.055a1.869,1.869,0,0,0-1.736.809,6.313,6.313,0,0,0-.451,1.042,11.92,11.92,0,0,0-1.281-2.54Z" transform="translate(462.698 -1015.924)" fill="#fff"/>
                                    <path id="Path_9790" data-name="Path 9790" d="M-295.992,1016.19h3.75a6.2,6.2,0,0,1,3.725.914,4.972,4.972,0,0,1,1.8,4.247c0,2.161-.635,3.672-1.918,4.539a4.468,4.468,0,0,1-1.485.648,9.97,9.97,0,0,1-2.107.183h-3.76l1.231-.95v-8.626Zm2.957.745v9.044c.486.039.82.06,1.02.06a2.845,2.845,0,0,0,2.505-1.175,6.067,6.067,0,0,0,.891-3.539,5.883,5.883,0,0,0-.7-3.062,2.851,2.851,0,0,0-2.656-1.383q-.256,0-1.062.055" transform="translate(395.892 -1015.924)" fill="#fff"/>
                                    <path id="Path_9791" data-name="Path 9791" d="M-218.529,1077.444a1.078,1.078,0,0,1,.785.317,1.01,1.01,0,0,1,.33.747,1,1,0,0,1-.338.759,1.116,1.116,0,0,1-.79.307,1.142,1.142,0,0,1-.8-.307,1,1,0,0,1-.33-.759.975.975,0,0,1,.33-.752,1.149,1.149,0,0,1,.813-.313" transform="translate(330.172 -1068.671)" fill="#fff"/>
                                </g>
                                <g id="Group_19904" data-name="Group 19904" transform="translate(0.356 34.956)">
                                    <path id="Path_9792" data-name="Path 9792" d="M-1009.758,1116.681l-.739.667h-1.482c-.487,0-.731-.222-.731-.652v-1.983l.74-.675h2.948l-.736.666h-2.212v1.977Z" transform="translate(1012.71 -1112.706)" fill="#fff"/>
                                    <path id="Path_9793" data-name="Path 9793" d="M-985.149,1114.038h1.165v2.643h.358l-.794.667H-986.2l.734-.667h.74V1114.7h-1.163Zm2.637,0-.746.666h-.726l.726-.666Z" transform="translate(989.879 -1112.706)" fill="#fff"/>
                                    <path id="Path_9794" data-name="Path 9794" d="M-956.267,1116v.679h2.2l-.739.667h-1.5c-.467,0-.7-.222-.7-.652v-1.983l.7-.675h1.489c.5,0,.752.216.752.645v.663l-.739.656Zm0-.651h1.464v-.647h-1.464Z" transform="translate(964.743 -1112.706)" fill="#fff"/>
                                    <path id="Path_9795" data-name="Path 9795" d="M-928.326,1114.7h-2.209l.74-.666h1.454c.507,0,.754.216.754.645v2h.734l-.734.667h-.421c-.213,0-.318-.184-.318-.552V1116h-1.469v.679h1.469l-.734.667h-.762c-.473,0-.713-.222-.713-.652V1116l.74-.651h1.469Z" transform="translate(941.948 -1112.706)" fill="#fff"/>
                                    <path id="Path_9796" data-name="Path 9796" d="M-901.042,1112.553l-.735.667h-.74a.809.809,0,0,1-.584-.162.689.689,0,0,1-.152-.49v-2h-.739l1.475-1.32v.66h1.475l-.735.66h-.74v1.988Z" transform="translate(919.092 -1108.579)" fill="#fff"/>
                                    <path id="Path_9797" data-name="Path 9797" d="M-881.78,1108.306h1.167v2.643h.358l-.793.667h-1.781l.739-.667h.734v-1.977h-1.156Zm1.149-.924h.743l-.743.666h-.726Z" transform="translate(900.869 -1106.975)" fill="#fff"/>
                                    <path id="Path_9798" data-name="Path 9798" d="M-860.624,1114.038h1.165v2.643h.359l-.8.667h-1.774l.735-.667h.737V1114.7h-1.161Zm3.387.666c0-.447-.253-.666-.761-.666h-.721l-.74.666h1.479v1.991c0,.435.175.652.514.652h.229l.736-.667h-.736Z" transform="translate(882.648 -1112.706)" fill="#fff"/>
                                    <path id="Path_9799" data-name="Path 9799" d="M-820.063,1118.008l-.739.666h-2.98c-.472,0-.705-.221-.705-.666v-.66l.739-.667h1.584l.426-.362h-1.273a.924.924,0,0,1-.555-.142.548.548,0,0,1-.183-.464v-1l.711-.675h2.974l-.739.666v1.615l-.4.362h.4a.774.774,0,0,1,.562.171.663.663,0,0,1,.177.5Zm-3.685,0h2.946v-.66h-2.946Zm.737-2.295h1.47V1114.7h-1.47Z" transform="translate(850.629 -1112.706)" fill="#fff"/>
                                    <path id="Path_9800" data-name="Path 9800" d="M-768.353,1104.45h1.112v3.974h.358l-.794.667h-1.8c-.473,0-.708-.222-.708-.652v-1.983l.708-.675h1.5v-.666h-1.088Zm-1.1,3.974h1.464v-1.977h-1.464Z" transform="translate(803.873 -1104.45)" fill="#fff"/>
                                    <path id="Path_9801" data-name="Path 9801" d="M-745.37,1108.306h1.159v2.643h.361l-.79.667h-1.783l.734-.667h.74v-1.977h-1.16Zm1.146-.924h.739l-.739.666h-.724Z" transform="translate(783.407 -1106.975)" fill="#fff"/>
                                    <path id="Path_9802" data-name="Path 9802" d="M-724.532,1105.782h.193v-.666h.018l.693-.666h1.478c.511,0,.758.216.758.653v.679h-.737v-.666H-723.6v.666h.927l-.748.666h-.18v1.977h.734l-.734.667h-1.634l.679-.667h.211v-1.977h-.891Z" transform="translate(765.158 -1104.45)" fill="#fff"/>
                                    <path id="Path_9803" data-name="Path 9803" d="M-694.244,1105.782h.2v-.666h.018l.688-.666h1.483c.505,0,.756.216.756.653v.679h-.742v-.666h-1.464v.666h.932l-.749.666h-.182v1.977h.738l-.738.667h-1.634l.682-.667h.213v-1.977h-.9Z" transform="translate(739.078 -1104.45)" fill="#fff"/>
                                    <path id="Path_9804" data-name="Path 9804" d="M-661.29,1116v.679h2.2l-.739.667h-1.5c-.475,0-.708-.222-.708-.652v-1.983l.708-.675h1.482c.505,0,.752.216.752.645v.663l-.739.656Zm0-.651h1.463v-.647h-1.463Z" transform="translate(710.736 -1112.706)" fill="#fff"/>
                                    <path id="Path_9805" data-name="Path 9805" d="M-637.147,1114.038h1.159v2.643h.364l-.8.667h-1.78l.737-.667h.736V1114.7h-1.161Zm2.634,0-.749.666h-.726l.726-.666Z" transform="translate(690.215 -1112.706)" fill="#fff"/>
                                    <path id="Path_9806" data-name="Path 9806" d="M-608.278,1116v.679h2.21l-.74.667h-1.5c-.473,0-.708-.222-.708-.652v-1.983l.708-.675h1.482c.505,0,.759.216.759.645v.663l-.74.656Zm0-.651h1.47v-.647h-1.47Z" transform="translate(665.086 -1112.706)" fill="#fff"/>
                                    <path id="Path_9807" data-name="Path 9807" d="M-584.125,1114.038h1.161v2.643h.359l-.789.667h-1.781l.734-.667h.739V1114.7h-1.162Zm3.384.666c0-.447-.254-.666-.758-.666h-.726l-.74.666h1.485v1.991c0,.435.169.652.51.652h.229l.734-.667h-.734Z" transform="translate(644.555 -1112.706)" fill="#fff"/>
                                    <path id="Path_9808" data-name="Path 9808" d="M-545.044,1112.553l-.74.667h-.735a.8.8,0,0,1-.586-.162.682.682,0,0,1-.152-.49v-2h-.734l1.473-1.32v.66h1.475l-.74.66h-.735v1.988Z" transform="translate(612.536 -1108.579)" fill="#fff"/>
                                    <path id="Path_9809" data-name="Path 9809" d="M-502.838,1107.745h-1.207l.248.679h1.424l.1-.092h.317l-.858.759h-1.473l-.248-.68-.482.68h-1.253l-.644-1.4,1.5-1.621-.349-.955.739-.666h2.209l-.74.666h-1.469l.837,2.295.191-.317h1.558l.143-.109h.306Zm-2.066.679.247-.336-.66-1.761-1,1.129.555.968Z" transform="translate(577.16 -1104.45)" fill="#fff"/>
                                    <path id="Path_9810" data-name="Path 9810" d="M-448.442,1114.038H-447l-.721.666h-.349l-1.108,2.644h-.737l-1.075-2.644h-.391l.736-.666h1.467l-.737.666h-.318l.707,1.522.758-1.522h-.409Z" transform="translate(529.346 -1112.706)" fill="#fff"/>
                                    <path id="Path_9811" data-name="Path 9811" d="M-415.022,1114.7h-2.208l.739-.666h1.456c.505,0,.752.216.752.645v2h.734l-.734.667h-.421c-.213,0-.318-.184-.318-.552V1116h-1.469v.679h1.469l-.734.667h-.762c-.473,0-.711-.222-.711-.652V1116l.739-.651h1.469Z" transform="translate(499.936 -1112.706)" fill="#fff"/>
                                    <path id="Path_9812" data-name="Path 9812" d="M-389.621,1104.45h1.107v3.974h.368l-.8.667h-1.783l.734-.667h.74v-3.308h-1.087Z" transform="translate(477.114 -1104.45)" fill="#fff"/>
                                    <path id="Path_9813" data-name="Path 9813" d="M-369.628,1114.038h1.164v2.643l-.737.667h-1.5c-.475,0-.713-.226-.713-.667V1114.7h-.734l.734-.666h.739v2.643h1.469V1114.7h-1.161Z" transform="translate(461.112 -1112.706)" fill="#fff"/>
                                    <path id="Path_9814" data-name="Path 9814" d="M-338.212,1114.7h-2.2l.739-.666h1.457c.5,0,.751.216.751.645v2h.734l-.734.667h-.42c-.216,0-.321-.184-.321-.552V1116h-1.466v.679h1.466l-.732.667h-.761c-.473,0-.713-.222-.713-.652V1116l.739-.651h1.466Z" transform="translate(433.791 -1112.706)" fill="#fff"/>
                                    <path id="Path_9815" data-name="Path 9815" d="M-312.585,1109.091c-.39,0-.581-.222-.581-.652v-3.323h-.644l.717-.666h.67v1.326h1.45c.505,0,.758.218.758.647v2l-.734.667Zm.162-.667h1.469v-1.983h-1.469Z" transform="translate(410.879 -1104.45)" fill="#fff"/>
                                    <path id="Path_9816" data-name="Path 9816" d="M-284.308,1104.45h1.107v3.974h.371l-.8.667h-1.783l.734-.667h.74v-3.308h-1.089Z" transform="translate(386.428 -1104.45)" fill="#fff"/>
                                    <path id="Path_9817" data-name="Path 9817" d="M-263.516,1116v.679h2.208l-.743.667h-1.492c-.473,0-.711-.222-.711-.652v-1.983l.711-.675h1.479c.5,0,.756.216.756.645v.663l-.743.656Zm0-.651h1.465v-.647h-1.465Z" transform="translate(368.207 -1112.706)" fill="#fff"/>
                                </g>
                            </g>
                            <g id="Group_19908" data-name="Group 19908" transform="translate(-1482.204 721.856)">
                                <path id="Path_9818" data-name="Path 9818" d="M-1281.125,721.856v62.871h-2.625V723.956Z" transform="translate(1311.313 -721.856)" fill="#fff"/>
                                <path id="Path_9819" data-name="Path 9819" d="M-1309.451,747.371v59.356l-2.293-1.352v-56.56Z" transform="translate(1335.419 -743.828)" fill="#fff"/>
                                <g id="Group_19906" data-name="Group 19906" transform="translate(17.143 26.987)">
                                    <path id="Path_9820" data-name="Path 9820" d="M-1353.447,916.161v33.759l-1.7-.927V920.774l-1.7,1.312v25.848l-1.918-1.324V920.89Z" transform="translate(1358.773 -916.161)" fill="#fff"/>
                                    <rect id="Rectangle_5885" data-name="Rectangle 5885" width="2.862" height="0.818" transform="translate(3.896 17.365) rotate(180)" fill="#fff"/>
                                    <rect id="Rectangle_5886" data-name="Rectangle 5886" width="2.862" height="0.818" transform="translate(4.094 11.378) rotate(180)" fill="#fff"/>
                                    <rect id="Rectangle_5887" data-name="Rectangle 5887" width="2.862" height="0.818" transform="translate(3.896 23.416) rotate(180)" fill="#fff"/>
                                </g>
                                <g id="Group_19907" data-name="Group 19907" transform="translate(39.585 26.816)">
                                    <path id="Path_9821" data-name="Path 9821" d="M-1197.2,914.935V948.37l1.7-.759V919.358l1.7,1.547v25.8l1.918-1.324V919.664Z" transform="translate(1197.195 -914.935)" fill="#fff"/>
                                    <rect id="Rectangle_5888" data-name="Rectangle 5888" width="2.862" height="0.818" transform="translate(1.431 16.547)" fill="#fff"/>
                                    <rect id="Rectangle_5889" data-name="Rectangle 5889" width="2.862" height="0.818" transform="translate(1.232 10.561)" fill="#fff"/>
                                    <rect id="Rectangle_5890" data-name="Rectangle 5890" width="2.862" height="0.818" transform="translate(1.431 22.598)" fill="#fff"/>
                                </g>
                                <path id="Path_9822" data-name="Path 9822" d="M-1245.494,724.875V782.6l-6.484,2.154v-62.9Z" transform="translate(1283.954 -721.856)" fill="#fff"/>
                                <path id="Path_9823" data-name="Path 9823" d="M-1456.236,866.369c-11.789-2.651-19.331-11.754-19.82-23.131a22.682,22.682,0,0,1,2.964-11.086c.1-.166.485-.811.666-1.107.043-.067.085-.134.128-.2a10.86,10.86,0,0,1,2.4-2.41,15.138,15.138,0,0,1,6.3-2.829l.1-.021c.038-.008.073-.017.11-.024v-.005a4.5,4.5,0,0,0,3.345-4.394,4.464,4.464,0,0,0-4.4-4.53,4.268,4.268,0,0,0-1.916.458.232.232,0,0,0-.045.011c-.468.223-.927.461-1.38.706v0c-8.6,4.477-14.423,12.822-14.423,22.7,0,.009,0,.017,0,.026s0,.02,0,.03c0,.091.006.182.008.273C-1482.031,854.97-1471.284,866.059-1456.236,866.369Z" transform="translate(1482.204 -803.469)" fill="#fff"/>
                                <path id="Path_9824" data-name="Path 9824" d="M-1221.917,840.5a25.132,25.132,0,0,0-10.805-20.468c-.073-.053-.146-.1-.219-.156-.139-.1-.278-.2-.419-.293-.158-.108-.319-.214-.48-.319-.068-.045-.135-.09-.2-.134q-.324-.209-.656-.408l-.077-.047-.125-.077v0q-.706-.419-1.439-.8v0c-.453-.245-.912-.482-1.38-.706a.214.214,0,0,0-.044-.011,4.269,4.269,0,0,0-1.916-.458,4.464,4.464,0,0,0-4.4,4.53,4.5,4.5,0,0,0,3.345,4.394v.005c.038.007.073.016.11.024l.1.021a15.141,15.141,0,0,1,6.3,2.829,10.854,10.854,0,0,1,2.4,2.41c.043.066.085.133.128.2.181.3.567.942.666,1.107a22.681,22.681,0,0,1,2.965,11.086,23.8,23.8,0,0,1-11.651,19.821,32.738,32.738,0,0,1-12.263,3.432,17.558,17.558,0,0,0,2.122.093,30.045,30.045,0,0,0,7.151-.86,25.186,25.186,0,0,0,20.739-23.69c.01-.174.012-.35.018-.525.008-.226.021-.451.024-.678,0-.091.008-.182.008-.273,0-.01,0-.02,0-.03S-1221.917,840.512-1221.917,840.5Z" transform="translate(1283.954 -803.469)" fill="#fff"/>
                            </g>
                        </g>
                    </svg>

                </div>
                <div className="title">
                    <h2>Creating <br/> Different & <br/> Valuable</h2>
                </div>
                <Button text={'Explore'} onClick={close} svgColor={'#3C4348'} background={'#F5F5F5'} hoverBackground={'#D05110'} color={'#3C4348'} hoverColor={'#FFFFFF'}/>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  position: relative;
  height: 100vh;
  .back_img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .logo{
    position: relative;
    margin-bottom: 115px;
    padding-top: 20px;
    @media(max-width: 767px){
      margin-bottom: 220px;
    }
  }
  .title{
    position: relative;
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
    h2{
      font-size: 80px;
      line-height: 90px;
      font-family: ${title};
      color: #ffffff;
      text-transform: uppercase;
      @media(max-width: 767px){
        font-size: 54px;
        line-height: 64px;
        br{
          display: none;
        }
      }
    }
  }
`;

export default MyComponent;
