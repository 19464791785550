import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {hover} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent>
            <Container>
                <Row className={'mission-vision'}>
                    {
                        data?.posts?.list && data?.posts?.list?.length>0 &&
                        data?.posts?.list?.map((element)=>{
                            return(
                                <Col md={6}>
                                    <div className="mission">
                                        <Title text={element?.data?.title}/>
                                        <p>{ReactHtmlParser(element?.data?.description)}</p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  position: relative;
  margin-top: -100px;
  @media(max-width: 767px){
    margin-top: 0;
    .mission-vision{
      gap: 30px;
      .col-md-6{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: calc(100% - 50%);
    background-color: #3C4348;
    z-index: -1;
    @media(max-width: 767px) {
      display: none;
    }
    }
  
  

    .mission{
      padding: 40px 40px 80px 40px;
      background-color: ${hover};
      height: 100%;
      h3{
        color: #ffffff;
        margin-bottom: 50px;
      }
      p{
        color: #ffffff;
      }
    }
`;

export default React.memo(MyComponent);
