import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import CvDrop from "../../components/career/CvDrop";
import TabWithAccordion from "../../components/career/TabWithAccordion";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchContact} from "../../api/redux/contact";
import {fetchCareer, fetchCareerList} from "../../api/redux/career";
import {fetchProjectDetail} from "../../api/redux/project";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'career',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let api_career_url = apiEndPoints.JOB_LIST;

        setTimeout(() => {
            dispath(fetchCareer([api_url , api_params]))
            dispath(fetchCareerList([ api_career_url, params ]))
        }, 500);
    }, [])


    const getData = useSelector((state) => state.career);


    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])


    //Refactor the data
    const careerBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'career-banner');
    const careerCV= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'drop-cv-section');
    const jobListing= getData?.listPosts?.data;

    //Get the filter option lists
    const options = jobListing?.map(element => ({value: element?.data?.category_title}));

    // Use reduce to filter unique values
    const uniqueOptions = options?.reduce((unique, option) => {
        // Check if the value is already in the unique array
        if (!unique.some(item => item.value === option.value)) {
            unique.push(option);
        }
        return unique;
    }, []);


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Career | Climax Holdings Ltd.</title>
                <meta name="description" content="The meticulously crafted architecture of Silver Spring reflects the highest standards of design and construction. Every aspect, from the layout to the finishes, has been thoughtfully curated to create a truly exceptional living experience. With spacious and well-appointed apartments, residents can indulge in modern living spaces that exude elegance and style.
DROP YOUR CV"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={careerBanner?.images?.list?.[0]?.full_path}
                    title={careerBanner?.section_data?.subtitle}
                />
                <CvDrop modalButton title={careerCV?.section_data?.short_desc}
                        backtext={careerCV?.section_data?.background_text}
                        description={careerCV?.section_data?.description}
                />

                <TabWithAccordion filterOption={uniqueOptions} data={jobListing}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
