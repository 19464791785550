import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {text, title} from "../../styles/globalStyleVars";
import Select, {components} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/landowner";

const MyComponent = ({data}) => {



    const dispatch = useDispatch();
    const responseData = useSelector(state => state.landowner);

    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.LANDOWNER_FORM;

        var formData = new FormData();
        formData.append('location', e?.location);
        formData.append('address', e?.address);
        formData.append('size_of_land', e?.size);
        formData.append('plot_facing', e?.plot?.label);
        formData.append('select_catagory', e?.category?.label);
        formData.append('select_type', e?.type?.label);
        formData.append('Width_of_the_road_in_front', e?.width);
        formData.append('name_of_the_landowner', e?.name);
        formData.append('contact_person', e?.person);
        formData.append('contact_number',e?.phone);
        formData.append('email', e?.email);
        formData.append('form_id', 'landowner-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '' &&  e?.profession!=='' && e?.designation!='') {
            dispatch(postForm([api_services, formData]));
            reset();
            success('Successfully Submitted')

        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)

        }

    }, [responseData])


    const [type, setType] = useState('');


    const handlePlot = (e) => {
        setType(e?.label);
    }

    const option = [
        {value: 'South', label: 'South'},
        {value: 'West', label: 'West'},
        {value: 'North', label: 'North'},
        {value: 'East', label: 'East'},
        {value: 'South-West', label: 'South-West'},
        {value: 'West-North', label: 'West-North'},
        {value: 'North-East', label: 'North-East'},
        {value: 'South-East', label: 'South-East'},
    ]


    const category = [
        {value: 'Residential', label: 'Residential'},
        {value: 'Commercial', label: 'Commercial'},
        {value: 'Commercial cum residential', label: 'Commercial cum residential'},
    ]

    const typeOption = [
        {value: 'Joint Venture Development', label: 'Joint Venture Development'},
        {value: 'Construction & Consultancy', label: 'Construction & Consultancy'},
        {value: 'Construction work', label: 'Construction work'},
        {value: 'Design Consultancy', label: 'Design Consultancy'},
    ]

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };



    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };
    return (
        <StyledComponent className={'pb-200 pt-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        {
                            data?.section_data?.short_desc &&
                            <Title text={data?.section_data?.short_desc} nosplit/>
                        }
                    </Col>
                </Row>
            </Container>
            <Container>
                <Form className={'form'} onSubmit={handleSubmit(onSubmit,onError)}>
                <Row>
                    <Col md={{span:5,offset:1}} className='modal-data__content'>
                        <Title text={'A. Land Information'} fontSize={24} lineHeight={32} fontFamily={'Ubuntu'}/>



                            <Form.Control
                                className={formState?.errors?.location?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("location",{
                                    required: 'Location is required',
                                    pattern: {
                                        // value: /([A-Z])\w+/,
                                        message: 'Name must contain only letters',
                                    },

                                })}
                                type="text" placeholder="Location*"/>
                            <Form.Control
                                className={formState?.errors?.address?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("address",{
                                    required: 'Address is required',
                                    pattern: {
                                        // value: /([A-Z])\w+/,
                                        message: 'Name must contain only letters',
                                    },

                                })}

                                type="text" placeholder="Address*"/>
                            <Form.Control
                                className={formState?.errors?.size?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("size",{
                                    required: 'Size is required',
                                    pattern: {
                                        // value: /([A-Z])\w+/,
                                        message: 'Name must contain only letters',
                                    },

                                })}
                                type="text" placeholder="Size of the land"/>
                            <Controller
                                control={control}
                                name="plot"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        options={option}
                                        placeholder="Plot facing"
                                        styles={customStyles}

                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="category"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        options={category}
                                        placeholder="Select Category"
                                        styles={customStyles}

                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="type"
                                defaultValue={null}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isSearchable={false}
                                        classNamePrefix="filter"
                                        options={typeOption}
                                        placeholder="Offer Type"
                                        styles={customStyles}

                                    />
                                )}
                            />

                           <Form.Control
                               className={formState?.errors?.width?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                               {...register("width",{
                                   required: 'width is required',
                                   pattern: {
                                       // value: /([A-Z])\w+/,
                                       message: 'Name must contain only letters',
                                   },

                               })}
                               type="text" placeholder="Width of the road in front"/>
                    </Col>
                    <Col md={{span:5}} className='modal-data__content'>
                        <Title text={"B. Landowner's Profile"} fontSize={24} lineHeight={32} fontFamily={'Ubuntu'}/>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name",{
                                    // required: 'landowner is required',
                                    // pattern: {
                                    //     // value: /([A-Z])\w+/,
                                    //     message: 'Name must contain only letters',
                                    // },

                                })}
                                type="text" placeholder="Name of the landowner"/>
                            <Form.Control
                                className={formState?.errors?.person?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("person",{
                                    // required: 'person is required',
                                    // pattern: {
                                    //     // value: /([A-Z])\w+/,
                                    //     message: 'Name must contain only letters',
                                    // },

                                })}

                                type="text" placeholder="Contact person"/>
                            <Form.Control
                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("phone",{
                                    required:{
                                        value:true,
                                        message:'please enter your phone first'
                                    },
                                    pattern:{
                                        value:/^01[0-9]{9}$/,
                                        message:'please enter a valid 11 digit phone number'
                                    }
                                })}
                                type="number" placeholder="Contact number*"/>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("email",{
                                    required:{
                                        value:true,
                                        message:'please enter your email'
                                    },
                                    pattern:{
                                        value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message:'please enter a valid email address'
                                    }
                                })}

                                type="email" placeholder="Email"/>
                        <Row>
                            <Col md={2}>
                                <div className="button-group">
                                    <div onClick={handleSubmit(onSubmit,onError)}>
                                        <Button text={'Submit'} fontWeight={'700'} color={'#D05110'} svgColor={'#D05110'} background={'#ffffff'}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

        </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  .title{
    margin-bottom: 60px;
    h3{
      color: #ffffff;
      font-family: ${title};

    }
  }
  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;
    padding-bottom: 20px;
    &::placeholder{
      color: #FFFFFF;
      opacity: 0.5;
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .form__phoneEmail{
    display: flex;
    gap: 20px;
    @media(max-width: 767px){
      flex-direction: column;
      input{
        width: 100% !important;
      }
    }
    input{
      width: 49%;
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }
  .button-group{
    padding-top: 20px;
  }
  @media(max-width: 767px){
    padding-top: 120px;
    .button-group{
      display: flex;
      justify-content: center;
    }
  }  
  .css-t3ipsp-control{
    box-shadow: 0px 0px transparent;
    border-color: transparent;
    border-bottom: 1px solid #FFFFFF !important;
    border-radius: 0 !important;

    &:hover{
      border: transparent;
    }
  }
  .css-1u9des2-indicatorSeparator{
    background-color: transparent !important;
  }
  .form .filter__control {
    margin-top: 50px !important;
  }
  .css-13cymwt-control{
    border-color:#FFFFFF !important; 
    border-radius: 0px !important;
  }
`;

export default React.memo(MyComponent);
