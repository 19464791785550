import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {hover} from "../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {Img} from "./Img";
import {Link} from "react-router-dom";
// import a from '../../public/images/static/logo.svg'
const MyComponent = () => {



    return (
        <StyledComponent className={'footer'}>
            <Img className='bg' src={'/images/static/footer.jpg'}/>
            <Container className='footer-main' >
                <Row>
                    <Col sm={3}>
                        <div className='footer-logo'>
                            <img src={'/images/static/logo.svg'} />
                        </div>
                    </Col>

                    <Col sm={{span: 8, offset: 1}} className={'footer__content'}>
                        <Row>
                            <Col sm={4} className='contact'>
                                <h5>Climax Holdings Ltd.</h5>
                                <p><a target='_blank' href="https://goo.gl/maps/9TNBE5u9uokZypXk8">{reactHtmlParser('House # 213 (4th Floor), Road # 13,<br> Mohakhali DOHS, Dhaka-1206.')}</a></p>
                                <p>Telephone: <a href={'tel:88 02 48812032'} >88 02 48812032</a></p>
                            </Col>
                            <Col sm={{offset:1}} className='contact'>
                                <h5>Call</h5>
                                <p>
                                    <ul>
                                        <li><a href="tel:+01329 633 624">+01329 633 624</a></li>
                                        <li><a href="tel:+01329 633 623">+01329 633 623</a></li>
                                        <li><a href="tel:+01329 633 622">+01329 633 622</a></li>
                                    </ul>
                                </p>
                            </Col>
                            <Col sm={4} className='contact'>
                                <h5>Email</h5>
                                <p><a href="mailto:info@climaxholdingsltd.com">info@climaxholdingsltd.com</a></p>
                            </Col>

                            <Col sm={12}>
                                <div className="footer__content__menu">
                                    <ul>
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li><Link to={"about-us"}>About</Link></li>
                                        <li><Link to={"projects"}>Projects</Link></li>
                                        <li><Link to={"media"}>Updates</Link></li>
                                        <li><Link to={"career"}>Career</Link></li>
                                        <li><Link to={"contact"}>Contact</Link></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="footer-bottom">
                            <Row>
                                <Col sm={4}>
                                    <p>© 2023 Climax Holdings Ltd. All Rights Reserved.</p>
                                </Col>
                                <Col sm={{span: 6, offset: 1}} className='dc'>
                                    <a href='https://dcastalia.com/' target='_blank'>Designed & Developed by
                                        Dcastalia</a>
                                </Col>
                                <Col sm={2} className={'bottom'}>
                                    <ul className={'parent'}>
                                        <li className={'hover'}><a href="https://www.facebook.com/climaxholdingsltd/">
                                            <svg id="Animation_-_Social_Icon" data-name="Animation - Social Icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                <rect className={'back'} id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25" rx="12.5" fill="#fffdfc"/>
                                                <rect id="Rectangle_2008" data-name="Rectangle 2008" width="3" height="3" rx="1.5" transform="translate(11 11)" fill="#d05110" opacity="0"/>
                                                <path className={'soc-icon'} id="Path_26" data-name="Path 26" d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z" transform="translate(-999.116 -3907.731)" fill="#d05110"/>
                                            </svg>
                                        </a></li>
                                        <li className={'hover'}><a href="https://www.instagram.com/climaxholdingsltd/">
                                            <svg id="Group_18904" data-name="Group 18904" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                <rect className={'back'} id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25" rx="12.5" fill="#fffdfc"/>
                                                <g id="Group_13724" data-name="Group 13724" transform="translate(7 7)">
                                                    <path className={'soc-icon'} id="Path_5912" data-name="Path 5912" d="M5.593,1.008c1.493,0,1.67.006,2.26.033a3.093,3.093,0,0,1,1.038.193,1.733,1.733,0,0,1,.643.418,1.733,1.733,0,0,1,.418.643,3.1,3.1,0,0,1,.193,1.038c.027.59.033.767.033,2.26s-.006,1.67-.033,2.26a3.1,3.1,0,0,1-.193,1.038A1.852,1.852,0,0,1,8.891,9.953a3.093,3.093,0,0,1-1.038.193c-.59.027-.767.033-2.26.033s-1.67-.006-2.26-.033a3.093,3.093,0,0,1-1.038-.193,1.733,1.733,0,0,1-.643-.418,1.733,1.733,0,0,1-.418-.643A3.093,3.093,0,0,1,1.04,7.853c-.027-.59-.033-.767-.033-2.26s.006-1.67.033-2.26a3.093,3.093,0,0,1,.193-1.038,1.733,1.733,0,0,1,.418-.643,1.733,1.733,0,0,1,.643-.418A3.093,3.093,0,0,1,3.333,1.04c.59-.027.767-.033,2.26-.033M5.593,0C4.074,0,3.883.006,3.287.034a4.106,4.106,0,0,0-1.358.26A2.741,2.741,0,0,0,.939.939a2.741,2.741,0,0,0-.645.991,4.106,4.106,0,0,0-.26,1.358C.006,3.883,0,4.074,0,5.593S.006,7.3.034,7.9a4.106,4.106,0,0,0,.26,1.358,2.741,2.741,0,0,0,.645.991,2.743,2.743,0,0,0,.991.645,4.106,4.106,0,0,0,1.358.26c.6.027.787.034,2.306.034S7.3,11.179,7.9,11.152a4.106,4.106,0,0,0,1.358-.26,2.86,2.86,0,0,0,1.636-1.636,4.106,4.106,0,0,0,.26-1.358c.027-.6.034-.787.034-2.306s-.006-1.709-.034-2.306a4.106,4.106,0,0,0-.26-1.358,2.743,2.743,0,0,0-.645-.991A2.741,2.741,0,0,0,9.256.294,4.106,4.106,0,0,0,7.9.034C7.3.006,7.112,0,5.593,0" fill="#d05110"/>
                                                    <path className={'soc-icon'} id="Path_5913" data-name="Path 5913" d="M23.548,20.676a2.872,2.872,0,1,0,2.872,2.872,2.872,2.872,0,0,0-2.872-2.872m0,4.736a1.864,1.864,0,1,1,1.864-1.864,1.864,1.864,0,0,1-1.864,1.864" transform="translate(-17.955 -17.955)" fill="#d05110"/>
                                                    <path className={'soc-icon'} id="Path_5914" data-name="Path 5914" d="M61.429,15.384a.671.671,0,1,1-.671-.671.671.671,0,0,1,.671.671" transform="translate(-52.18 -12.776)" fill="#d05110"/>
                                                </g>
                                            </svg>

                                        </a></li>

                                        <li className={'hover'}><a href="https://www.linkedin.com/company/chl-climax-holdings-limited/">
                                            <svg id="Group_18905" data-name="Group 18905" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                <rect className={'back'} id="Rectangle_1998" data-name="Rectangle 1998" width="25" height="25" rx="12.5" fill="#fffdfc"/>
                                                <g id="Group_28" data-name="Group 28" transform="translate(7.801 7.756)">
                                                    <path className={'soc-icon'} id="LinkedIn" d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z" transform="translate(-1087.435 -3916.306)" fill="#d05110"/>
                                                </g>
                                            </svg>


                                        </a></li>

                                        <li className={'hover'}><a href="https://www.youtube.com/@ClimaxHoldingsLtd">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                <g id="Group_22054" data-name="Group 22054" transform="translate(-180)">
                                                    <path className={'back'} id="Path_10292" data-name="Path 10292" d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.5,12.5,0,0,1,12.5,0Z" transform="translate(180)" fill="#fffdfc"/>
                                                    <g id="Group_31" data-name="Group 31" transform="translate(187.515 8.71)">
                                                        <path className={'soc-icon'} id="Path_29" data-name="Path 29" d="M1176.069,3919.422c-.36-.425-1.025-.6-2.294-.6h-4.606c-1.3,0-1.974.185-2.333.638a3.24,3.24,0,0,0-.349,1.993v1.717c0,1.745.415,2.631,2.682,2.631h4.606a2.913,2.913,0,0,0,2.1-.529,2.759,2.759,0,0,0,.577-2.1v-1.717A3.158,3.158,0,0,0,1176.069,3919.422Zm-3.181,3.128-2.092,1.087a.322.322,0,0,1-.47-.284v-2.168a.32.32,0,0,1,.154-.273.325.325,0,0,1,.315-.011l2.092,1.081a.319.319,0,0,1,0,.568Z" transform="translate(-1166.487 -3918.823)" fill="#d05110"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a></li>
                                    </ul>
                                </Col>
                            </Row>

                        </div>

                    </Col>
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-bottom: 40px;
 
  a {
    display: block;
  }
  .footer-logo{
    img{
      width: 220px;
      height: 70px;
    }
  }
  .footer-main{
    position: relative;
    padding-top: 120px;
  }

  .call {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .mail {
    color: #f2f0f0;

  }

  .underline-btn {
    margin-top: 40px;
    text-transform: capitalize;
    color: #ffffff !important;
    position: relative;
    width: fit-content;
    padding-bottom: 5px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: #fff;
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      opacity: 0;
      background-color: ${hover};
      transition: all 1s cubic-bezier(0.4, 0, 0, 1);
    }

    &:hover:before {
      height: 4px;
    }
  }

  h5 {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 12px;
    text-transform: capitalize;
    font-family: Ubuntu;
  }

  p {
    color: #F0EDE3;
    margin-bottom: 60px;
    font-family: Ubuntu;
    a{
      color: #F0EDE3;
      font-weight: 400;
      font-family: Ubuntu;
    }
    
  }

  .footer__content {
    svg {
      margin-right: 20px;
    }

    .underline-btn {
      &:before, &:after {
        display: none;
      }

      span {
        position: relative;
        //display: block;
        color: #ffffff;
        padding-bottom: 5px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background-color: #fff;
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

        }

        &:after {
          //content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 0;
          background-color: ${hover};
          transition: all 1s cubic-bezier(0.4, 0, 0, 1);
        }
      }

      &:hover span {
        &:before {
          height: 4px;
        }
      }
    }

    &__menu {
      border-top: 1px solid #FFFFFF;
      padding-top: 20px;
      padding-bottom: 20px;

      ul {
        display: flex;

        li {
          a {
            //line-height: 20px;
            color: #F0EDE3;
            padding: 0 30px 0 0;
            font-family: Ubuntu;

            text-transform: capitalize;
            //letter-spacing: 2px;
            white-space: nowrap;
          }

          &:nth-last-of-type(1) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid rgba(184, 184, 184, 0.30);
    padding-top: 40px;

    p, a {
      font-size: 12px;
      line-height: 24px;
      color: rgba(240,237,227,0.8);
      margin-bottom: 0;
      font-family: Ubuntu;

    }
    //ul{
    //  display: flex;
    //  li{
    //    padding-right: 20px;
    //    svg{}
    //  }
    //  li:last-child {
    //    padding-right: 0;
    //  }
    //}
    ul {
      display: flex;
      margin-bottom: 20px;

      li {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #F0EDE3;
        position: relative;


        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          z-index: 2;
          position: relative;

          svg {
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            .back{
              transition: 0.3s ease;
            }
            .soc-icon{
              transition: 0.3s ease;
            }
          }
        }
        &:hover{
          svg{
            .back{
              fill: #D05110;
            }
            .soc-icon{
              fill: #ffffff;
            }
          }
        }

        &:not(:nth-last-child(1)) {
          margin-right: 20px;
        }
      }
    }
  }
  

  @media (max-width: 991px) {
    .col-sm-3 {
      min-width: 100%;
    }

    .footer__content {
      min-width: 100%;
      margin: 50px 0 0 0;
    }

    .footer-bottom {
      .col-sm-6{
        margin-bottom: 20px;
      }
    }
  }
  
  @media (min-width: 576px){
    .dc{
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    p{
      margin-bottom: 0;
    }
    .footer-bottom {
      .col-sm-3, .col-sm-6, .col-sm-2 {
        min-width: 100%;
        max-width: 100%;
      }
    }
    .footer__content{
      &__menu{
        ul{
          display: block;
          li{
            margin-bottom: 20px;
          }
          li:last-child{
            margin-bottom: 0;
          }
        }
      }
      .contact{
        padding-bottom: 40px;
        
        p:not(:last-child){
          margin-bottom: 30px !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .underline-btn {
      margin-top: 30px;
    }

    //p {
    //  margin-bottom: 20px;
    //}

    //.col-sm-5:not(:nth-last-of-type(2)) {
    //  margin-bottom: 40px;
    //}

    .footer__content__menu {
      //padding-bottom: 40px;

      ul {
        flex-wrap: wrap;
      }
    }
  }
  

`;


export default React.memo(MyComponent);
