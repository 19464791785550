import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import CvDrop from "../../components/career/CvDrop";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchBuyer} from "../../api/redux/buyer";
import {fetchCustomer} from "../../api/redux/customer";

const MyComponent = () => {

    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'customer',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        dispath(fetchCustomer([api_url , api_params]))
    }, [])

    const getData = useSelector((state) => state.customer);
    const customerBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'customer-banner');
    const customerOverview= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'customer-overview');
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Customer | Climax Holdings Ltd.</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={'Customer'} img={'/images/dynamic/customer/banner.jpg'}/>
                <CvDrop title={'Enriching experiences and unwavering support: elevating customer satisfaction with Climax Holdings Ltd.'}
                        backtext={customerOverview?.section_data?.background_text}
                        description={'The meticulously crafted architecture of Silver Spring reflects the highest standards of design and construction. Every aspect, from the layout to the finishes, has been thoughtfully curated to create a truly exceptional living experience. With spacious and well-appointed apartments, residents can indulge in modern living spaces that exude elegance and style.'}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
