import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import CvDrop from "../../components/career/CvDrop";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchLandowner} from "../../api/redux/landowner";
import {fetchBuyer} from "../../api/redux/buyer";
import BuyerForm from "../../components/buyer/BuyerForm";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'buyer',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let params = {
            [ApiParamKey.image]: 'yes',
        }

        setTimeout(() => {
            dispath(fetchBuyer([api_url , api_params]))
        }, 500);


    }, [])

    const getData = useSelector((state) => state.buyer);
    const buyerBanner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'buyer-banner');
    const buyerOverview= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'buyer-overview');
    const buyerForm= getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'buyer-form');


    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Buyer | Climax Holdings Ltd.</title>
                <meta name="description" content="
                Explore our diverse range of ready-to-move and ongoing projects, each a testament to innovation,
quality, and sustainability. Trust us to make your homeownership aspirations a reality, as we redefine
the art of living. Let's embark on this exciting journey together, crafting a future that resonates with
your desires.
"/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={buyerBanner?.images?.list?.[0]?.full_path}
                             title={buyerBanner?.section_data?.subtitle}/>
                        <CvDrop title={buyerOverview?.section_data?.short_desc}
                                backtext={buyerOverview?.section_data?.background_text}
                                description={buyerOverview?.section_data?.description}/>

                <BuyerForm data={buyerForm}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
