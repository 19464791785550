import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";

const MyComponent = ({title,data}) => {
    return (
        <StyledComponent className={'pt-200 pb-200'}>
            <Container>
                <Row className={'management-team'}>
                    <Col md={10}>
                        <Title text={title}/>
                    </Col>
                    <Col md={2}>
                        <div className="testimonial__head__navigation">
                            <ul className={'team_nav'}>
                                <li id={'gallery-prev'} className={'prevhover slider-prev'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                        <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                            <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </li>
                                <li id={'gallery-next'} className={'nexthover slider-next'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.094" height="9.359" viewBox="0 0 6.094 9.359">
                                        <g id="Group_4864" data-name="Group 4864" transform="translate(1.414 1.414)">
                                            <line id="Line_7" data-name="Line 7" x2="3.265" y2="3.265" transform="translate(0 6.531) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_8" data-name="Line 8" x1="3.265" y2="3.265" transform="translate(0 3.265) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={15}
                            speed='500'
                            navigation={{
                                prevEl: '#gallery-prev',
                                nextEl: '#gallery-next',
                            }}
                            loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1025: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                            className="mySwiper"
                        >
                            {
                                data && data.length>0 &&
                                data?.map((element)=>{
                                    return(
                                        <SwiperSlide key={element?.id}>
                                            <div className="testimonial__single">
                                                <div className="testimonial__single__img">
                                                    <Img src={element?.full_path} alt={'team'}/>
                                                </div>
                                                <div className="testimonial__single__desc">
                                                    <h4>{element?.short_title}</h4>
                                                    <p>{element?.short_desc}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    )
                                })
                            }

                        </Swiper>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;
  .management-team{
    margin-bottom: 60px;
    h3{
      font-family: Century;
    }
  }
    .testimonial__single{
      &__img{
        position: relative;
        padding-top: calc(450 / 370 * 100%);
        margin-bottom: 20px;
      }
      &__desc{
        h5{
          margin-bottom: 10px;
          font-family: Ubuntu;
        }
      }
    }
  
  .testimonial__head__navigation{
    .team_nav{
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      @media(max-width: 767px){
        justify-content: flex-start;
      }
      .slider-prev{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #323232;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          transform: rotate(180deg);
          z-index: 5;
        }
      }
      .slider-next{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #323232;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          z-index: 5;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
