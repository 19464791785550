import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ProjectSingle from "./ProjectSingle";
import Select, {components} from "react-select";
import Button from "../Button";
import {useHistory, useLocation} from "react-router-dom";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchProjects, fetchProjectsList} from "../../api/redux/project";
import {useDispatch} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";

const MyComponent = ({data,options}) => {

    const dispath = useDispatch();
    const selectTypeInputRef = useRef();
    const selectLocationInputRef = useRef();


    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(6)
    const initialData = data?.slice( 0, index);

    //Load More
    const handleLoadMore = () => {
        setIndex(index + 6)
        if (index >= data?.length) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    }

   const history = useHistory();
   const handleStatus = (e) => {
        setSelectStatus(e?.value);
        history.push(`/projects?status=${e?.value}`);
        selectTypeInputRef?.current?.select?.clearValue();
        selectLocationInputRef?.current?.select?.clearValue();
    }

    const handleType = (e) => {
        setSelectType(e?.value);
        selectLocationInputRef?.current?.select?.clearValue();

    }
    const handleLocation = (e) => {
        setSelectLocation(e?.value);
    }

    // get the query
    const locations = useLocation();
    const status = new URLSearchParams(locations?.search).get("status");


    // step -01

    const [selectStatus, setSelectStatus] = useState('')
    const [selectType, setSelectType] = useState('')
    const [selectLocation, setSelectLocation] = useState('')

    // step -02

    // api call

    useEffect(() => {

        if(status===statuss){
            let param = {
                [ApiParamKey.project_status]: statuss,
                [ApiParamKey.project_type]: selectType,
                [ApiParamKey.project_location]: selectLocation
            }
            let api_services = apiEndPoints.PROJECT_FILTER
            dispath(fetchProjects([api_services,param]))
        }
        else{
            let param = {
                [ApiParamKey.project_status]: status? status : statuss,
                [ApiParamKey.project_type]: selectType,
                [ApiParamKey.project_location]: selectLocation,
                [ApiParamKey.post]: 'yes',
                [ApiParamKey.image]: 'yes',
            }
            let api_services = apiEndPoints.PROJECT_FILTER
            dispath(fetchProjectsList([api_services,param]))
        }

    }, [selectStatus, selectType, selectLocation,status])




    // const category = options?.category?.map(element => ({value: element?.slug, label: element?.title}));
    const allLocation = options?.location_list?.map(element => ({value: element?.location, label: element?.location}));


    const statuss = [
        {value: 'ongoing', label: 'Ongoing'},
        {value: 'upcoming', label: 'Upcoming'},
        {value: 'completed', label: 'Completed'},
    ]
    const type = [
        {value: 'residential', label: 'Residential'},
        {value: 'commercial', label: 'Commercial'},
        {value: 'studio-apartment', label: 'Studio Apartment'},
    ]

    const location = [
        {value: 'dhaka', label: 'Dhaka'},
        {value: 'chittagong', label: 'Chittagong'},
    ]

    const category = [
        {value: 'ongoing', label: 'Ongoing'},
        {value: 'upcoming', label: 'Upcoming'},
        {value: 'completed', label: 'Completed'},
    ]



    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"

        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `#3C4348` : '#3C4348',
            backgroundColor: '#FFFDFB',
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            fontWeight: state.isSelected ? 500 : 400,
            "&:hover": {
                backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#181D24',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#3C4348',
            backgroundColor: state.isSelected ? `#FFF` : '#FFFDFB',
            margin: 0,
            fontSize: 16,
            zIndex:10,
            borderRadius: 0,

        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#FFF' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#3C4348',
            fontSize: 16,
            // paddingBottom:10,
        }),
        control:(provided,state)=>({
            ...provided,
            "&:hover": {
                // transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                // backgroundColor: `rgba(178, 168, 159, 0.2)`,
                color: '#FFFDFB',
            },
        })

    };



    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="5.414" viewBox="0 0 9.414 5.414">
            <g id="Group_22052" data-name="Group 22052" transform="translate(-454.793 -609.793)">
                <line id="Line_12374" data-name="Line 12374" x2="4" y2="4" transform="translate(455.5 610.5)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_12375" data-name="Line 12375" y1="4" x2="4" transform="translate(459.5 610.5)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>

            ;
    };

    return (
        <StyledComponent className={'pt-100 pb-200'}>
            <Container>
                <Row className={'filters'}>
                    <Col xs={12} md={4}>
                        <Select
                            classNamePrefix="filter"
                            onChange={handleStatus}
                            isSearchable={false}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            options={category}
                            placeholder={status ? status : 'Project Status'}
                            defaultValue={selectStatus}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <Select
                            classNamePrefix="filter"
                            onChange={handleType}
                            isSearchable={false}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            options={type}
                            placeholder={'Project Type'}
                            defaultValue={selectStatus}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <Select
                            classNamePrefix="filter"
                            onChange={handleLocation}
                            isSearchable={false}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            options={allLocation}
                            placeholder={'Project Location'}
                            defaultValue={selectStatus}
                        />
                    </Col>
                </Row>

                <Row>
                    {
                        initialData?.length>0 ?
                            initialData && initialData.length>0 &&
                            initialData.map((element,index)=>{

                                return(
                                    <Col md={4} key={index}>
                                        <ProjectSingle
                                            img={element?.images?.list?.[0]?.full_path}
                                            title={element?.product_data?.title}
                                            location={element?.product_data?.location}
                                            type={element?.product_data?.type}
                                            link={element?.product_data?.slug}
                                        />
                                    </Col>
                                )
                            }) : <Col><p>No data is found</p></Col>
                    }
                    <Col md={12} className={'project-btn justify-content-center'}>
                        {
                            index >= data?.length || initialData===undefined ? "" :
                                <div className={'load-more'} onClick={handleLoadMore}>
                                    <Button text={'Load More'} background={'#D05110'} hoverBackground={'#3C4348'}/>
                                </div>
                        }
                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background-color: #F5F5F5;
  @media(max-width: 767px){
    padding-top: 60px !important;
  }
  .load-more{
    display: flex;
    justify-content: center;
  }
  .filters {
    padding-bottom: 60px;
    background: #F5F5F5;
  }

  .project-btn {
    margin-top: 40px;
  }

  .filter {
    &__control {
      background-color: #F5F5F5;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #3C4348;
      box-shadow: none;
      border-radius: unset;


      &--is-focused {
        background-color: transparent !important;
        border: none;
        outline: none;
        border-bottom: 1px solid #3C4348;
      }

      &:hover {
        border-bottom: 1px solid #3C4348;
      }
    }

    &__placeholder {
      color: #3C4348;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      opacity: 50%;
      text-transform: capitalize;
    }

    &__value-container {
      padding: 10px 0px;
    }

    &__indicator {
      padding: 0;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__single-value {
      color: #3C4348 !important;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    &__menu {
      padding: 30px 20px;
    }

    &__option {
      border-bottom: 1px solid rgba(178, 168, 159, 0.2);
      :last-child{
        border-bottom: 0px;
        padding-bottom: 0px;
      }
      :first-child{
        padding-top: 0px;
      }

    }
  }

  @media (max-width: 767px) {
    .col-md-4 {
      :last-child {
        margin-bottom: -30px;

      }
    }
  }

`
export default React.memo(MyComponent);
