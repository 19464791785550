import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Link} from "react-router-dom";
const MyComponent = ({img,link,title,type,location}) => {

    return (
        <StyledComponent className={'project-single'}>
            <Link to={`/project/${link}`}>
                <div className={'project-single__img'}>
                    <Img src={img} alt={'project image'}/>
                </div>
                <h4>{title}</h4>
                <div className={'d-flex align-items-center'}>
                    <p>{type}</p>
                    <div className={'dot'}></div>
                    <p>{location}</p>
                </div>
            </Link>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .project-single{
    &__img{
      position: relative;
      padding-top: calc(510/370 *100%);
      .global-image{
        overflow: hidden;
        img{
          transform: scale(1.01);
          transition: 1.4s ease;
        }
      }
      &:hover {
        .global-image img{
          transform: scale(1.06) !important;
        }
      }
    }
  }
  .dot{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #3C4348;
    margin-right: 10px;
    margin-left: 10px;
  }
  margin-bottom: 60px !important;

  h4{
    font-size: 24px ;
    line-height: 32px;
    font-weight: 400;
    margin-top: 20px;
    color: #D05110;
  }
  p{
    font-size: 16px ;
    line-height: 24px ;
    font-weight: 400;
    color: #3C4348;
    text-transform: Capitalize;
  }
  @media(max-width: 767px){
    margin-bottom: 30px !important;
    h3{
      margin-top: 20px !important;
    }
  }
`
export default React.memo(MyComponent);
