import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Button from "../Button";
import {coloredText, text} from "../../styles/globalStyleVars";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data,textOne,textTwo,project_name}) => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.BROCHURE_FORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('contact_no', e?.phone);
        formData.append('project_name', project_name);
        formData.append('form_id', 'brochure-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)
        }

    }, [responseData])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <StyledComponent className={'parallax-img horizontal-scroll pt-100 pb-200'} data-speed-right={30} data-speed-left={-30}>
            <Container>
                <p className={'scroll-right bg-text-one'}>{textOne}</p>
                <p className={'scroll-left bg-text-two'}>{textTwo}</p>
                <Row>
                    <Col md={9}>
                        <h3 className={'split-up'}>{data?.data?.subtitle}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:6,offset:6}}>
                        <div className="overview__desc">
                            {ReactHtmlParser(data?.data?.description)}
                        </div>
                        <div className="overview__btn" onClick={handleShow}>
                            <Button text={'Get Brochure'} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} className='project-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">
                        <Container>
                            <Row>
                                <Col>
                                    <div onClick={handleClose} className="modal-close">
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="16.571" height="16.57" viewBox="0 0 16.571 16.57">*/}
                                        {/*    <g id="Group_1821" data-name="Group 1821" transform="translate(1.414 1.414)">*/}
                                        {/*        <path id="Path_1745" data-name="Path 1745" d="M1097.785,2831.737l13.742,13.742" transform="translate(-1097.785 -2831.737)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>*/}
                                        {/*        <path id="Path_1746" data-name="Path 1746" d="M0,0,13.742,13.742" transform="translate(0 13.742) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>*/}
                                        {/*    </g>*/}
                                        {/*</svg>*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                            <g id="Group_1979" data-name="Group 1979" transform="translate(-1242 -47)">
                                                <circle id="Ellipse_85" data-name="Ellipse 85" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#D05110"/>
                                                <circle id="Ellipse_86" data-name="Ellipse 86" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#fffdfc"/>
                                                <g id="Group_1821" data-name="Group 1821" transform="translate(1260 65)">
                                                    <path id="Path_1745" data-name="Path 1745" d="M1097.785,2831.737l13.742,13.742" transform="translate(-1097.785 -2831.737)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    <path id="Path_1746" data-name="Path 1746" d="M0,0,13.742,13.742" transform="translate(0 13.742) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>


                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="modal-title_project">
                                        <h3>Get brochure</h3>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Col md={{span:10,offset:1}} className='modal-data__content'>

                                <Form onSubmit={handleSubmit(onSubmit,onError)}>
                                    <input name={'spam_protector'} type='hidden'/>
                                    <input name={'form_id'}  value={'contact-form'} type='hidden'/>

                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("name",{
                                            required: 'Name is required',
                                            pattern: {
                                                value: /([A-Z])\w+/,
                                                message: 'Name must contain only letters',
                                            },

                                        })}
                                        type="text" placeholder="Full Name"/>
                                    <div className="form__phoneEmail">
                                        <Form.Control
                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("phone",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your phone first'
                                                },
                                                pattern:{
                                                    value:/^01[0-9]{9}$/,
                                                    message:'please enter a valid 11 digit phone number'
                                                }
                                            })}
                                            type="number" placeholder="Phone Number*"/>
                                        <Form.Control
                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                            {...register("email",{
                                                required:{
                                                    value:true,
                                                    message:'please enter your email'
                                                },
                                                pattern:{
                                                    value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message:'please enter a valid email address'
                                                }
                                            })}

                                            type="email" placeholder="Email*"/>
                                    </div>
                                    <Row>
                                        <Col md={2}>
                                            <div className="button-group">
                                                <div  onClick={handleSubmit(onSubmit,onError)}>
                                                    <Button text={'Submit'} background={'#ffffff'} color={'#D05110'}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  .bg-text-one, .bg-text-two {
    font-size: 200px;
    line-height: 286px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -15%;
    top: 40%;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -15%;
    bottom: 0;
  }
  
  h3{
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 60px;
    @media(max-width: 767px){
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
  .overview__desc{
    p{
      color: ${coloredText};
      margin-bottom: 24px;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
      &:last-child{
        margin-bottom: 60px !important; 
      }
    }
  }

`;

export default React.memo(MyComponent);
