import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import Modal from "react-bootstrap/Modal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";
// import PopupV1 from "../forms/PopupV1";


const MyComponent = ({
                         modalButton,
                         title,backtext,
                         description,buttonText,
                         buttonLink,linkButton,
                         buttonBack,buttonHover,img
}) => {
    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedImageName, setSelectedImageName] = useState('');


    const [cv, setCv] = useState(null)
    const cvRef = useRef()

    const [photo, setPhoto] = useState(null)
    const photoRef = useRef()

    const handleInputcv = (e) => {
        console.log(e);
        setCv(e.target.files[0])
        setSelectedFileName(e.target.files?.[0]?.name);

    }
    const handleInputPhoto = (e) => {
        setPhoto(e.target.files[0])
        setSelectedImageName(e.target.files?.[0]?.name);
    }

    //Form submission

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('file', cv);
        formData.append('photo', photo);
        formData.append('form_id', 'career-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '' && cv!='' && photo!='') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedImageName('')
        setSelectedFileName('');
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])

    return (
        <StyledComponent className={`service-overview pb-200 pt-100 parallax-img horizontal-scroll ${modalButton ? 'background' : ''}`} data-speed-right={30}>
            <Container>
                <Row>
                    <p className={'scroll-right bg-text-one'}>{backtext}</p>
                    <Col md={9} className="service-overview__header">
                        <div className="service-overview__header__bigText">
                            {title &&
                                <Title text={title} />
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:7,offset:5}}>
                        {
                            img ?
                                <div className="service-overview__img">
                                    <Img src={'/images/dynamic/home/climax-bg.svg'} alt={'cv drop image'}/>
                                </div> : ''

                        }

                        <div className="service-overview__desc">
                            {
                                description &&
                                <>{reactHtmlParser(description)}</>
                            }
                        </div>
                        {
                            modalButton ?
                                <div className="button " onClick={handleShow}>
                                    <Button text={'Drop Your CV'} fontWeight={'700'} background={'#D05110'} color={'#ffffff'} hoverBackground={'#3C4348'}/>
                                </div>:
                                linkButton ?
                                <div className="button">
                                    <Button text={buttonText} src={buttonLink} fontWeight={'700'} background={buttonBack} color={'#ffffff'} hoverBackground={buttonHover}/>
                                </div> : ''
                        }
                    </Col>
                </Row>
                {/*<ButtonRound text={'Read More'}/>*/}
            </Container>
            <Modal show={show} className='cv-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="modal-title">
                                    <h5>Drop your CV</h5>
                                    <div onClick={handleClose} className="modal-close dc-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                            <g id="Group_1979" data-name="Group 1979" transform="translate(-1242 -47)">
                                                <circle id="Ellipse_85" data-name="Ellipse 85" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#D05110"/>
                                                <circle id="Ellipse_86" data-name="Ellipse 86" cx="25" cy="25" r="25" transform="translate(1242 47)" fill="#fffdfc"/>
                                                <g id="Group_1821" data-name="Group 1821" transform="translate(1260 65)">
                                                    <path id="Path_1745" data-name="Path 1745" d="M1097.785,2831.737l13.742,13.742" transform="translate(-1097.785 -2831.737)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    <path id="Path_1746" data-name="Path 1746" d="M0,0,13.742,13.742" transform="translate(0 13.742) rotate(-90)" fill="none" stroke="#3c4348" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Col md={{span:10,offset:1}} className='modal-data__content'>

                            <Form onSubmit={handleSubmit(onSubmit,onError)}>
                                <input name={'spam_protector'} type='hidden'/>
                                <input name={'form_id'}  value={'career-form'} type='hidden'/>

                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name",{
                                        required: 'Name is required',
                                        pattern: {
                                            value: /([A-Z])\w+/,
                                            message: 'Name must contain only letters',
                                        },

                                    })}

                                    type="text" placeholder="Full Name"/>
                                <div className="form__phoneEmail">
                                    <Form.Control
                                        className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("phone",{
                                            required:{
                                                value:true,
                                                message:'please enter your phone first'
                                            },
                                            pattern:{
                                                value:/^01[0-9]{9}$/,
                                                message:'please enter a valid 11 digit phone number'
                                            }
                                        })}
                                        type="number" placeholder="Phone Number*"/>
                                    <Form.Control
                                        className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("email",{
                                            required:{
                                                value:true,
                                                message:'please enter your email'
                                            },
                                            pattern:{
                                                value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:'please enter a valid email address'
                                            }
                                        })}
                                        type="email" placeholder="Email*"/>
                                </div>
                                <Form.Control
                                    className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("message",{
                                        required:{
                                            // value:true,
                                            message:'please enter your Message'
                                        },

                                    })}
                                    type="textarea" placeholder="Message"/>

                                <div className="button-group">
                                    <div className="attachCvName">
                                        <div className="attach-cv">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" style={{ display: selectedImageName ? "none" : "block" }}>
                                                <path id="avatar" d="M15,5A10,10,0,1,0,25,15,10.011,10.011,0,0,0,15,5Zm4.211,17.891a8.916,8.916,0,0,1-8.421,0V18.684a2.1,2.1,0,0,1,1.1-1.839l2.737,2.737a.526.526,0,0,0,.744,0l2.737-2.737a2.1,2.1,0,0,1,1.1,1.839ZM15,15.526a3.158,3.158,0,1,1,3.158-3.158A3.162,3.162,0,0,1,15,15.526Zm1.887,1.053L15,18.466l-1.887-1.887Zm3.376,5.641V18.684a3.163,3.163,0,0,0-2.553-3.1,4.211,4.211,0,1,0-5.421,0,3.162,3.162,0,0,0-2.553,3.1v3.535a8.947,8.947,0,1,1,10.526,0Z" transform="translate(-5 -5)" fill="#fffdfc"/>
                                            </svg>

                                            <Form.Control

                                                {...register('photo')}
                                                type="file"
                                                accept=".jpg"
                                                id="image-upload"
                                                style={{ display: "none" }}
                                                onChange={handleInputPhoto}
                                            />
                                            <Form.Label htmlFor="image-upload" className="image-upload-label" style={{ display: selectedImageName ? "none" : "block" }}>
                                                Upload Photo
                                            </Form.Label>
                                            {selectedImageName && (
                                                <div className="file-name">
                                                    {selectedImageName.length > 10 ? (
                                                        <>
                                                            {selectedImageName.substring(0, 10)}...{selectedImageName.split('.').pop()}
                                                        </>
                                                    ) : (
                                                        selectedImageName
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="attach-cv">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.268" height="24" viewBox="0 0 26.268 24" style={{ display: selectedFileName ? "none" : "block" }}>
                                                <g id="Group_15866" data-name="Group 15866" transform="translate(-12252 -863)">
                                                    <path id="Path_6994" data-name="Path 6994" d="M129.092,23.561a4.6,4.6,0,0,0-3.3-1.463h-.057a4.7,4.7,0,0,0-3.2,1.384l-.009.008L110.8,35.42a2.822,2.822,0,0,0,0,4.1,2.878,2.878,0,0,0,2.044.892h0a2.9,2.9,0,0,0,2.061-.9l8.11-8.285a1.026,1.026,0,0,0-1.466-1.435l-8.106,8.281a.862.862,0,0,1-.6.283.828.828,0,0,1-.586-.283.791.791,0,0,1,0-1.212l11.718-11.914a2.444,2.444,0,0,1,3.658.058,2.571,2.571,0,0,1,.019,3.809,1.026,1.026,0,1,0,1.458,1.443,4.6,4.6,0,0,0-.019-6.695Z" transform="translate(12147.724 840.902)" fill="#fffdfb"/>
                                                    <path id="Path_6995" data-name="Path 6995" d="M21.594,71.964a1.026,1.026,0,0,0-1.45.023L10.019,82.444a4.609,4.609,0,0,1-3.266,1.435h0A4.56,4.56,0,0,1,3.49,82.418a4.374,4.374,0,0,1,0-6.476L13.6,65.836a1.026,1.026,0,0,0-1.45-1.451L2.037,74.493l0,0A6.745,6.745,0,0,0,0,79.046a6.537,6.537,0,0,0,2.029,4.814,6.653,6.653,0,0,0,4.721,2.07h0a6.644,6.644,0,0,0,4.728-2.048L21.617,73.414A1.026,1.026,0,0,0,21.594,71.964Z" transform="translate(12252 801.07)" fill="#fffdfb"/>
                                                </g>
                                            </svg>
                                            <Form.Control
                                                ref={cvRef}
                                                {...register('cv')}
                                                type="file"
                                                accept=".pdf"
                                                id="resume-upload"
                                                style={{ display: "none" }}
                                                onChange={handleInputcv}
                                            />
                                            <Form.Label htmlFor="resume-upload" className="resume-upload-label" style={{ display: selectedFileName ? "none" : "block" }}>
                                                Attach CV
                                            </Form.Label>
                                            {selectedFileName && (
                                                <div className="file-name">
                                                    {selectedFileName.length > 10 ? (
                                                        <>
                                                            {selectedFileName.substring(0, 10)}...{selectedFileName.split('.').pop()}
                                                        </>
                                                    ) : (
                                                        selectedFileName
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <Row>
                                        <Col md={2}>
                                            <div className={'cv-submit'} onClick={handleSubmit(onSubmit,onError)}>
                                                <Button text={'Submit'} fontWeight={'700'} background={'#ffffff'} color={'#D05110'} svgColor={"#D05110"} />
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Form>

                        </Col>

                    </Container>


                    </div>
                </Modal.Body>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #FFFDFC;
  //background-color: #ffffff;
  position: relative;
  
  img{
    opacity: 0.4;
  }

  &.background{
    background-color: #F5F5F5;
  }
  .service-overview__header{

    &__bigText{
      .title{
        margin-bottom: 60px;
        h3{
          font-family: 'Ubuntu', Arial, Helvetica, freesans, sans-serif !important;
        }
      }
      @media (max-width: 767px){
        h2{
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 0;
        }

      }
    }
  }

  .service-overview__body{
    margin-top: 60px;
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
      p{
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #FFFDFB;

    }
  }
  .service-overview__img{
    position: relative;
    padding-top: calc(270 / 670 * 100%);
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
  }
  .service-overview__desc{
    p{
      color: ${hover};
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }

  .bg-text-one {
    font-size: 200px;
    line-height: 286px;
    font-weight: 700;
    margin: 0;
    color: #252525;
    opacity: 0.05;
    position: absolute;
    white-space: nowrap;
    font-family: Gilroy;
    left: -5%;
    top: 50%;

    @media (max-width: 767px) {
      bottom: 120px;
      font-size: 120px;
      line-height: 120px;
    }
  }
  
  .button{
    margin-top: 60px;
    @media(max-width: 767px){
      margin-top: 40px;
    }
  }




`;

export default React.memo(MyComponent);

