import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";
// import {loaded} from "../global";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    featureLoading: false,
    featurePost: [],
    featureError: '',
    mediaLoading: false,
    mediaPost: [],
    mediaError: '',
}

export const fetchHome = createAsyncThunk("fetchHome", (params, {dispatch}) => {
    return get(params);
});
export const fetchFeatureProjects = createAsyncThunk("fetchFeature", (params) => {
    return get(params);
});


export const fetchFeatureMedia = createAsyncThunk("fetchFeatureMedia", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchHome.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchHome.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchHome.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })

        builder.addCase(fetchFeatureProjects.pending, (state, action) => {
            state.featureLoading = true
            state.featurePost = []
            state.featureError = ''
        })
        builder.addCase(fetchFeatureProjects.fulfilled, (state, action) => {
            state.featureLoading = false
            state.featurePost = action.payload
            state.featureError = ''
        })
        builder.addCase(fetchFeatureProjects.rejected, (state, action) => {
            state.featureLoading = false
            state.featurePost = []
            state.featureError = action.error
        })

        builder.addCase(fetchFeatureMedia.pending, (state, action) => {
            state.mediaLoading = true
            state.mediaPost = []
            state.mediaError = ''
        })
        builder.addCase(fetchFeatureMedia.fulfilled, (state, action) => {
            state.mediaLoading = false
            state.mediaPost = action.payload
            state.mediaError = ''
        })
        builder.addCase(fetchFeatureMedia.rejected, (state, action) => {
            state.mediaLoading = false
            state.mediaPost = []
            state.mediaError = action.error
        })
    }
})


export default postSlice.reducer
