import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: '',
    list:[]

}

export const fetchProjects = createAsyncThunk("fetchProject", (params) => {
    return get(params);
});
export const fetchProjectsList = createAsyncThunk("fetchProjectList", (params) => {
    return get(params);
});
export const fetchProjectDetail = createAsyncThunk("fetchProjectDetail", (params) => {
    return get(params);
});




const postSlice = createSlice({
    name: 'project',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchProjects.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchProjects.rejected, (state, action) => {
            state.loading = false
            state.list = []
            state.error = action.error
        })
        builder.addCase(fetchProjectsList.pending, (state) => {
            state.loading = true
            state.list = []
            state.error = ''
        })
        builder.addCase(fetchProjectsList.fulfilled, (state, action) => {
            state.loading = false
            state.list = action.payload
            state.error = ''
        })
        builder.addCase(fetchProjectsList.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchProjectDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchProjectDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchProjectDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
