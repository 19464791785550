import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import MediaDetails from "../../components/media/MediaDetails";
import NewsList from "../../components/media/NewsList";
import InnerBanner from "../../components/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";
import {fetchProjectDetail, fetchProjects} from "../../api/redux/project";
import {fetchMedia, fetchMediaList} from "../../api/redux/media";
import {fetchFeatureMedia} from "../../api/redux/home";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;

        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: 'media-center',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }
        let media_params = {
            [ApiParamKey.page_id]: '49',
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'no',
            [ApiParamKey.gallery]: 'no',
        }

        let api_for_media = apiEndPoints.CHILD_PAGE
        setTimeout(() => {
            dispath(fetchMedia([api_url , api_params]))
            dispath(fetchMediaList([ api_for_media, media_params]))

        }, 500);

    }, [])


    const getData = useSelector((state) => state.media);
    const Banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'media-banner');
    const newsList = getData?.mediaPosts?.data;
    const [click, setClick] = useState('All');

    //Filter projects according to its category
    let filteredMedia = Array.isArray(newsList?.list) ? click == 'All' ? newsList?.list : newsList?.list.filter(element => element?.page_data?.type=== click) : '';

    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Media | Climax Holdings Ltd.</title>
                <meta name="description" content="Explore the heart of our journey through captivating moments and impactful experiences. Dive into our media and events hub, where we showcase the latest news, captivating stories, and upcoming events that define our organization's dynamic spirit. Join us in celebrating our milestones and connecting with our vibrant community."/>
            </Helmet>

            <StyledComponent>
                {/*<MediaDetails/>*/}
                <InnerBanner img={Banner?.images?.list?.[0]?.full_path} title={Banner?.section_data?.subtitle}/>
                <NewsList data={filteredMedia} setClick={setClick}
                           />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
