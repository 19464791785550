import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {title} from "../../styles/globalStyleVars";

const MyComponent = () => {
    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <Title text={'Contact us to know more'}/>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={{span:10,offset:1}} className='modal-data__content'>

                        <Form>

                            <Form.Control type="text" placeholder="Name*"/>
                            <div className="form__phoneEmail">
                                <Form.Control type="number" placeholder="Phone*"/>
                                <Form.Control type="email" placeholder="Email*"/>
                            </div>
                            <Form.Control type="textarea" placeholder="Message"/>

                            <div className="button-group">
                                <Button text={'Submit'} fontWeight={'700'} color={'#D05110'} svgColor={'#D05110'} background={'#ffffff'}/>
                            </div>
                        </Form>

                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  .title{
    margin-bottom: 60px;
    h3{
      color: #ffffff;
      font-family: ${title};
      
    }
  }
  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;
    padding-bottom: 20px;
    &::placeholder{
      color: #FFFFFF;
      opacity: 0.5;
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .form__phoneEmail{
    display: flex;
    gap: 20px;
    @media(max-width: 767px){
      flex-direction: column;
      input{
        width: 100% !important;
      }
    }
    input{
      width: 49%;
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }
  .button-group{
    padding-top: 20px;
  }

  @media(max-width: 767px){
    padding-top: 120px;
    .button-group{
      display: flex;
      justify-content: center;
    }
  }
  
`;
export default React.memo(MyComponent);

