import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {text, title} from "../../styles/globalStyleVars";
import Select, {components} from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/buyer";

const MyComponent = ({data}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.buyer);

    const {register, handleSubmit ,formState,reset,control} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.BUYER_FORM;

        var formData = new FormData();
        formData.append('property_type', e?.type?.label);
        formData.append('preferred_condition', e?.condition?.label);
        formData.append('preferred_location', e?.location);
        formData.append('preferred_size', e?.size);
        formData.append('preferred_bedrooms', e?.bedrooms);
        formData.append('project_facing', e?.facing?.label);
        formData.append('expected_handover_time', e?.handover);
        formData.append('name', e?.name);
        formData.append('profession', e?.profession);
        formData.append('designation', e?.designation);
        formData.append('contact_number', e?.phone);
        formData.append('email', e?.email);
        formData.append('form_id', 'buyer-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '' &&  e?.profession!=='' && e?.designation!='') {
            dispatch(postForm([api_services, formData]));
            reset();
            success('Successfully Submitted')

        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)

        }

    }, [responseData])


    const [type, setType] = useState('');


    const handlePlot = (e) => {
        setType(e?.label);
    }

    const option = [
        {value: 'Residential Apartment', label: 'Residential Apartment'},
        {value: 'Commercial Space', label: 'Commercial Space'},
        {value: 'Commercial cum Residential Apartment', label: 'Commercial cum Residential Apartment'},
        {value: 'Studio Apartment', label: 'Studio Apartment'},
        {value: 'Condominium Projects Flat', label: 'Condominium Projects Flat'},
        {value: 'Duplex Apartment', label: 'Duplex Apartment'},
        {value: 'Lakeview Apartment', label: 'Lakeview Apartment'},
    ]

    const optionCategory = [
        {value: 'Ongoing', label: 'Ongoing'},
        {value: 'Ready', label: 'Ready'},
        {value: 'Upcoming', label: 'Upcoming'},
        {value: 'Used', label: 'Used'},

    ]

    const projectOption = [
        {value: 'South', label: 'South'},
        {value: 'West', label: 'West'},
        {value: 'East', label: 'East'},
        {value: 'Corner', label: 'Corner'},
        {value: 'Lakeview', label: 'Lakeview'},
    ]

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${text}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 700 : 400,
            "&:hover": {
                backgroundColor: `${text}`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${text}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 5,
            fontSize: 14,
            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#191818' : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            fontSize: 14,
        }),

    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };



    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };
    return (
        <StyledComponent className={'pb-200 pt-200'}>
            <Container>
                <Row>
                    <Col md={12}>
                        {
                            data?.section_data?.short_desc &&
                            <Title text={data?.section_data?.short_desc} nosplit/>
                        }
                    </Col>
                </Row>
            </Container>
            <Container>

                <Form className={'form'} onSubmit={handleSubmit(onSubmit,onError)}>
                    <Row>
                       <Col md={{span:5,offset:1}} className='modal-data__content'>
                        <Title text={'A. Your Valued Interest'} fontSize={24} lineHeight={32} fontFamily={'Ubuntu'}/>
                           <Controller
                               control={control}
                               name="type"
                               defaultValue={null}
                               className={'first'}
                               render={({ field }) => (
                                   <Select
                                       {...field}
                                       isSearchable={false}
                                       classNamePrefix="filter"
                                       options={option}
                                       placeholder="Property type"
                                       styles={customStyles}
                                   />
                               )}
                           />
                           <Controller
                               control={control}
                               name="condition"
                               defaultValue={null}
                               render={({ field }) => (
                                   <Select
                                       {...field}
                                       isSearchable={false}
                                       classNamePrefix="filter"
                                       options={optionCategory}
                                       placeholder="Preferred condition"
                                       styles={customStyles}
                                   />
                               )}
                           />
                            <Form.Control
                                className={formState?.errors?.location?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("location",{

                                })}
                                type="text" placeholder="Preferred location"/>
                            <Form.Control
                                className={formState?.errors?.size?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("size",{
                                    required: 'Size is required',
                                    pattern: {
                                        // value: /([A-Z])\w+/,
                                        message: 'Name must contain only letters',
                                    },

                                })}
                                type="text" placeholder="Preferred size"/>
                            <Form.Control
                                className={formState?.errors?.bedrooms?.message ? 'has-error form-control-lg' : 'form-control-lg'}

                                {...register("bedrooms",{
                                    required: 'Size is required',
                                    pattern: {
                                        // value: /([A-Z])\w+/,
                                        message: 'Name must contain only letters',
                                    },

                                })}


                                type="text" placeholder="Preferred bedrooms"/>
                           <Controller
                               control={control}
                               name="facing"
                               defaultValue={null}
                               render={({ field }) => (
                                   <Select
                                       {...field}
                                       isSearchable={false}
                                       classNamePrefix="filter"
                                       options={projectOption}
                                       placeholder="Project facing"
                                       styles={customStyles}
                                   />
                               )}
                           />


                            <Form.Control
                                className={formState?.errors?.handover?.message ? 'has-error form-control-lg' : 'form-control-lg'}

                                {...register("handover",{
                                    required: 'Size is required',

                                })}
                                type="text" placeholder="Expected handover time"/>
                    </Col>
                       <Col md={{span:5}} className='modal-data__content'>
                        <Title text={"B. Contact Information"} fontSize={24} lineHeight={32} fontFamily={'Ubuntu'}/>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name",{
                                    required: 'Name is required',

                                })}
                                type="text" placeholder="Name*"/>
                            <Form.Control
                                className={formState?.errors?.profession?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("profession",{
                                    required: 'Profession is required',
                                })}

                                type="text" placeholder="Profession*"/>
                            <Form.Control
                                className={formState?.errors?.designation?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("designation",{
                                    required: 'Designation is required',

                                })}
                                type="text" placeholder="Designation*"/>
                            <Form.Control
                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("phone",{
                                    required:{
                                        value:true,
                                        message:'please enter your phone first'
                                    },
                                    pattern:{
                                        value:/^01[0-9]{9}$/,
                                        message:'please enter a valid 11 digit phone number'
                                    }
                                })}
                                type="number" placeholder="Contact number*"/>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("email",{
                                    required:{
                                        value:true,
                                        message:'please enter your email'
                                    },
                                    pattern:{
                                        value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message:'please enter a valid email address'
                                    }
                                })}

                                type="email" placeholder="Email"/>
                           <Row>
                               <Col md={2}>
                                   <div className="button-group">
                                       <div onClick={handleSubmit(onSubmit,onError)}>
                                           <Button text={'Submit'} fontWeight={'700'} color={'#D05110'} svgColor={'#D05110'} background={'#ffffff'}/>
                                       </div>
                                   </div>
                               </Col>
                           </Row>
                    </Col>
                    </Row>
                </Form>



            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  .title{
    margin-bottom: 60px;
    h3{
      color: #ffffff;
      font-family: ${title};

    }
  }

  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 40px;
    color: #FFFDFB;
    padding-left: 0;
    padding-bottom: 20px;
    &::placeholder{
      color: #FFFFFF;
      opacity: 0.5;
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .css-1dimb5e-singleValue{
    color: white !important;
  }
  .form__phoneEmail{
    display: flex;
    gap: 20px;
    @media(max-width: 767px){
      flex-direction: column;
      input{
        width: 100% !important;
      }
    }
    input{
      width: 49%;
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }
  .button-group{
    padding-top: 20px;
  }
  @media(max-width: 767px){
    padding-top: 120px;
    .button-group{
      display: flex;
      justify-content: center;
    }
  }  
  .css-t3ipsp-control{
    box-shadow: 0px 0px transparent;
    border-color: transparent;
    border-bottom: 1px solid #FFFFFF !important;
    border-radius: 0 !important;
    &:hover{
      border: transparent;
    }
  }
  //.form {
  //  .filter__control{
  //    background-color: #61dafb !important;
  //  }
  //}
  .css-1u9des2-indicatorSeparator{
    background-color: transparent !important;
  }
  .css-13cymwt-control{
    border-color:#FFFFFF !important; 
    border-radius: 0px !important;
    //margin-bottom: 10px;
    //margin-bottom: 20px !important;
  }
  .form .filter__value-container{
    padding-bottom: 21px !important;
  }
`;

export default React.memo(MyComponent);
