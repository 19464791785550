import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';
import Button from "../Button";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import ModalVideo from "react-modal-video";
import ReactHtmlParser from "react-html-parser";
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import Title from "../Title";
import {text} from "../../styles/globalStyleVars";
const ProjectList = ({data}) => {


    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
        setShow(true)
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };




    return (
        <StyledProjectList>
            <Container fluid className={''}>
                <Row>
                    <Col md={9} className={'pl-0 pr-0'} onClick={() => handelOpen(true, data?.images?.[0]?.short_desc ? data?.images?.[0]?.short_desc : '' )} key={0}>
                        <div className='project fade-up'>
                            <div className='project__img'>
                                {
                                    data?.images?.[0]?.full_path &&
                                    <Img src={data?.images?.[0]?.full_path} alt={'project video'}/>
                                }
                                <div className="video__box__inner__click">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
                                        <defs>
                                            <filter id="Ellipse_386" x="0" y="0" width="130" height="130" filterUnits="userSpaceOnUse">
                                                <feOffset input="SourceAlpha"/>
                                                <feGaussianBlur stdDeviation="10" result="blur"/>
                                                <feFlood flood-opacity="0.2"/>
                                                <feComposite operator="in" in2="blur"/>
                                                <feComposite in="SourceGraphic"/>
                                            </filter>
                                        </defs>
                                        <g id="Group_22052" data-name="Group 22052" transform="translate(-429 -4106)">
                                            <g transform="matrix(1, 0, 0, 1, 429, 4106)" filter="url(#Ellipse_386)">
                                                <circle id="Ellipse_386-2" data-name="Ellipse 386" cx="35" cy="35" r="35" transform="translate(30 30)" fill="#d05110"/>
                                            </g>
                                            <path id="Polygon_2" data-name="Polygon 2" d="M10.829,2.54a2,2,0,0,1,3.342,0L22.961,15.9A2,2,0,0,1,21.29,19H3.71a2,2,0,0,1-1.671-3.1Z" transform="translate(504 4159) rotate(90)" fill="#fffdfc"/>
                                        </g>
                                    </svg>

                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="video_text">
                            {
                                data?.data?.subtitle &&
                                <h3 className={'split-up'}>{data?.data?.subtitle}</h3>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={`${data?.images?.[0]?.short_desc}`}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>


        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
  
  .video_text{
    padding-top: 100px;
    padding-right: 100px;
    padding-left: 15px;
    h3{
      font-family: Ubuntu;
      font-weight: 400;
      color: ${text};
      font-size: 40px;
      line-height: 48px;
    }
    @media(max-width: 767px){
      padding-top: 40px;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .overflow {
    overflow: hidden;
  }
  

  @media (max-width: 991px) {

    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(75%);

      svg {
        width: 40% !important;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 40%;
      }
    }

    .shadow-left {
      transform: translateX(-20%);
      left: 0;

      svg {
        width: 50%;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
    .col-lg-6 {
      margin-bottom: 40px;

      :nth-child(even) {
        padding-right: 15px;
        padding-left: 15px;
      }

      :nth-child(odd) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .shadow-right {
      position: absolute;
      bottom: 30px;
      right: -20px;
      transform: none;

      svg {
        width: 150px;
        height: 150px;
      }
    }

    .shadow-left {
      transform: none;
      left: 0;
      bottom: 30px;
      top: auto;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .dc-btn {
      margin-top: 30px !important;
      margin-bottom: 0 !important;
    }

  }
  @media (max-width: 1200px) {
    .shadow-right {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: none;

      svg {
        width: 40% !important;
      }
    }
  }

  @media (max-width: 767px) {
    .project__img__content {
      left: 20px;
    }
  };

  
  
  
  //project 
  .project {
    &__img {
      position: relative;
      padding-top: calc(700/1170*100%);
      //overflow: hidden;
      z-index: 4;
      cursor: pointer;

      a.wrapped {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        z-index: 5;
      }

      &:after {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0px;
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }


      &__content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        right: 40px;
        z-index: 1;
        //transform: translateY(calc(100% - 30px));
        @media (max-width: 992px) {
          font-size: 18px;
          line-height: 27px;
        };

        h2 {
          color: #F2EEE8;
          opacity: 1;
          font-weight: 400;
          font-size: 32px;
          line-height: 32px;
          //bottom: 36px;
          //transition: 0.5s;
        }


        p {
          font-size: 20px;
          color: #F2EEE8;
          line-height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          margin-bottom: 7px;
          opacity: 0.6;
          //transition: 0.5s;

        }

        // &__bottom {
        //   opacity: 1;
        // }
      }
    }
    &:hover {
      svg {
        .video-hover {
          cx: 30px;
          opacity: 1;
          transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
        }
        path{
          fill:#ffffff;
          transition: all 0.5s ease;
        }
      }
    }
  }

  .video__box__inner__click {
    position: absolute;
    z-index: 8;
    inset: 0px;
    width: fit-content;
    height: fit-content;
    margin: auto;
    overflow: unset !important;
    


    svg {
      overflow: hidden;
      border-radius: 50%;

      .video-hover {
        cx: -30px;
        opacity: 0;
        transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
      }
      path{
        transition: all 0.5s ease;
      }
    }

    

    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #f2eee8;
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      position: absolute;
      transition: all .3s ease;
      transform: translateY(10px);
    }

   

  }


  @media (max-width: 768px) {
    .project__img__content__bottom {
      display: none;

    }

    .project__img__content {
      transform: none;
      bottom: 26px;

    }
  }

`
export default React.memo(ProjectList);

