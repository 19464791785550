import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import ProjectOverview from "../../components/project details/ProjectOverview";
import AtaGlance from "../../components/project details/AtaGlance";
import ProjectVideo from "../../components/project details/ProjectVideo";
import FeatureAmenities from "../../components/project details/FeatureAmenities";
import FloorPlan from "../../components/project details/FloorPlan";
import ProjectDetailsGallery from "../../components/project details/ProjectDetailsGallery";
import ProjectMap from "../../components/project details/ProjectMap";
import ProjectForm from "../../components/project details/ProjectForm";
import {fetchProjectDetail} from "../../api/redux/project";
import {ApiParam as ApiParamKey} from "../../api/network/apiParams";

const MyComponent = () => {

    const dispatch = useDispatch()
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT_DATA;
        let api_params = {
            [ApiParamKey.type]: 'slug',
            [ApiParamKey.value]: `${slug}`,
            [ApiParamKey.get_section]: 'yes',
            [ApiParamKey.image]: 'yes',
            [ApiParamKey.post]: 'yes',
            [ApiParamKey.file]: 'yes',
            [ApiParamKey.gallery]: 'yes',
            [ApiParamKey.specification]: 'yes',
        }
        dispatch(fetchProjectDetail([api_url , api_params]))
    }, [])
    const getData = useSelector(store => store.projects);
    const banner = getData?.detail?.data?.posts?.list?.[0];
    const projectOverview = getData?.detail?.data?.posts?.list?.[1];
    const atAglance = getData?.detail?.data?.posts?.list?.[2];
    const projectVideo = getData?.detail?.data?.posts?.list?.[3];
    const projectfeature = getData?.detail?.data?.posts?.list?.[4];
    const projectfloor = getData?.detail?.data?.posts?.list?.[5];
    const projectgallery = getData?.detail?.data?.posts?.list?.[6];
    const projectMap = getData?.detail?.data?.posts?.list?.[7];


    useEffect(() => {
        window.scroll(0, 0)
    }, [getData])


    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getData?.detail?.data?.product_data?.title} | Climax Holdings Ltd.`}</title>
                <meta name="description" content="Description"/>
            </Helmet>
            <StyledComponent>
                <InnerBanner
                    img={banner?.images?.[0]?.full_path}
                    title={getData?.detail?.data?.product_data?.title}
                    type={getData?.detail?.data?.product_data?.type}
                    location={getData?.detail?.data?.product_data?.location} detail
                />
                {/*{*/}
                {/*    banner?.images?.[0]?.full_path && getData?.detail?.data?.product_data?.title &&*/}
                {/*    getData?.detail?.data?.product_data?.type && getData?.detail?.data?.product_data?.location &&*/}
                {/*    */}
                {/*}*/}
                {
                    projectOverview?.data?.background_text &&
                    <ProjectOverview data={projectOverview} textOne={projectOverview?.data?.background_text}
                                     textTwo={projectOverview?.data?.background_text_two}
                                     project_name={getData?.detail?.data?.product_data?.title}
                    />
                }
                {
                    atAglance?.images?.[0]?.full_path && atAglance?.data?.description &&
                    <AtaGlance data={atAglance}/>
                }
                {
                    projectVideo?.images?.[0]?.full_path && projectVideo?.images?.[0]?.short_desc &&
                    <ProjectVideo data={projectVideo}/>
                }
                {
                    projectfeature?.data?.description &&
                    <FeatureAmenities data={projectfeature}/>
                }
                {
                    projectfloor?.images?.length &&
                    <FloorPlan data={projectfloor}/>
                }
                {
                    projectgallery?.images?.length>0 &&
                    <ProjectDetailsGallery data={projectgallery}/>
                }

                {
                    projectMap && getData?.detail?.data?.product_data?.type && getData?.detail?.data?.product_data?.location &&
                    <ProjectMap data={projectMap}
                                type={getData?.detail?.data?.product_data?.type}
                                location={getData?.detail?.data?.product_data?.location}
                                title={getData?.detail?.data?.product_data?.title}
                    />
                }

                {/*<ProjectForm/>*/}
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
