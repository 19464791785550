import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img,svg,title,padding,mobilePaddingBottom,description}) => {


    return (
        <StyledComponent className={'pt-100'} paddingBottom={padding} mobilePaddingBottom={mobilePaddingBottom}>
            <Container fluid className={'climax-zigzag'}>
                <Row>
                    <Col md={8} className={'pl-0'}>
                        <div className="climax-img climax-reveal">
                            <Img src={img} alt={'climax reverse image'}/>
                        </div>
                    </Col>
                </Row>
                <Row className={'climax-text-row'}>
                    <Col md={{span:10,offset:2}  } className={'reverse climax-text'}>
                        <Col md={4} className={'climax-letter-wrapper'}>
                            <div className="climax-letter">
                                <img src={svg} alt={'climax-letter'}/>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="climax-desc">
                                <h2 className={'split-up'}>{title}</h2>
                                <p className={'split-up'}>{description}</p>
                            </div>
                        </Col>
                    </Col>
                </Row>

            </Container>



        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -2px;
  padding-bottom: ${(props) => props.paddingBottom || '0'};


  .climax-img{
    position: relative;
    padding-top: calc(450 / 968 * 100%);
  }
  .climax-text-row{
    margin-top: -160px;
    //transform: translateY(-50%);
    @media(max-width: 767px){
      margin-top: 0;
    }
  }
  .reverse{
    display: flex;
    flex-direction: row-reverse;
  }
  
  .climax-text{
    background-color: #8D8D8D;
    display: flex;
    padding: 60px;
    
    .climax-letter-wrapper{
      display: flex;
      justify-content: center;
    }

    .climax-letter{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .climax-desc{
      display: flex;
      flex-direction: column;
      h2{
        color: #ffffff;
        font-family: Century;
        font-size: 60px;
        line-height: 68px;
        margin-bottom: 20px;
      }
      p{
        color: #ffffff;
      }
    }
  }
  @media(max-width: 767px){
    padding-bottom: ${(props) => props.mobilePaddingBottom || '0'};

    .col-md-8{
      padding-right: 0;
    }
    .climax-text{
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }
    .climax-letter-wrapper{
      justify-content: flex-start !important;
      margin-bottom: 20px;
    }
    .col-md-4{
      padding-left: 0;
    }
    .col-md-8{
      padding-left: 0;
      padding-right: 0;
      .climax-desc{
        h2{
          font-size: 40px;
          line-height: 48px;
        }
      }
    }

  }

`;

export default React.memo(MyComponent);
