import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
import {title} from "../../styles/globalStyleVars";

const AtGlance = ({data}) => {



    // offset
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    return (
        <StyledOverviewWithImage id={'glance'} className='atglance pb-150 pt-150'>
            <Container className=''>
                <Row className={'atglance'}>
                    <Col  md={5} className='pr-0'>
                        {
                            data?.images?.[0]?.full_path &&
                            <div className="atglance__img reveal">
                                <Img src={data?.images?.[0]?.full_path} alt={'at a glance'}/>
                            </div>
                        }

                    </Col>

                    <Col md={{span:6,offset:1}} className='atglance__content'>
                        <div className='atglance__content__title'>
                            {
                                data?.data?.subtitle &&
                                <Title classname={'split-up'} text={data?.data?.subtitle} color={'#FFFFFF'} />
                            }
                            <div >
                                {
                                    data?.data?.description &&  reactHtmlParser(data?.data?.description)

                                }
                            </div>
                        </div>

                    </Col>


                </Row>
            </Container>
        </StyledOverviewWithImage>
    );
};
const StyledOverviewWithImage = styled.section`
  background-color: #3C4348;

  .atglance {
    @media(max-width: 767px){
      .col-md-5{
        padding-left: 0;
      }
    }
    &__img {
      position: relative;
      padding-top: calc(600 / 500 * 100%);
      @media (max-width: 767px) {
        position: relative;
        padding-top: calc(451 / 345 * 100%);
      }
    }

    &__content {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      padding-right: 70px;
      padding-left: 0;

      @media (max-width: 991px) {
        padding-top: 50px;
      }
      @media (max-width: 767px) {
        padding-right: 15px !important;
        padding-left: 30px;
      }

      &__title {
        width: 100%;

        h3 {
          font-family: ${title};
          margin-bottom: 60px;
        }

        table {

          tr {
            display: flex;
            width: 100%;
            border-bottom: 1px solid rgba(172, 139, 124, 0.5);
            padding-top: 20px;
            padding-bottom: 20px;

            //&:last-child {
            //  border-bottom: none;
            //  padding-bottom: 0;
            //
            //  td {
            //    padding-bottom: 0;
            //  }
            //}
            &:first-child{
              padding-top: 0;
            }

          }

          &:last-child {
            margin-bottom: 0;

          }
          

          td {
            font-weight: 400;
            flex: 0 0 50%;
            font-size: 16px;
            line-height: 24px;

            &:first-child {
              flex: 0 0 50%;
              padding-left: 0px;
              opacity: 0.8;

            }

          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .atglance-row{
      flex-direction: column-reverse;
    }
    .title {
      margin-bottom: 0 !important;
    }
  }


`;
export default React.memo(AtGlance);

