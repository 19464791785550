import { gsap, TimelineLite } from "gsap";
import { useLocation } from "react-router-dom";
import React, { useEffect, } from 'react';
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import { loading } from "../../api/redux/global";
import {useDispatch, useSelector} from "react-redux";

const PageTransition = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin);
    const location = useLocation();
    let MainStore = useSelector(state => state);
    const dispatch = useDispatch();

    // preloader start on page/router change
    useEffect(() => {
        const tl = new TimelineLite();
        dispatch(loading());
        tl.fromTo('.page-transition', {
            xPercent: -100, // Start from left side
            opacity: 1,
            display: 'flex'
        }, {
            xPercent: 0, // Move to the center
            duration: 1,
            ease: 'power3.out' // Use a smooth easing function

        }).to('.page-transition .logo', {
            duration: .3,
            opacity: 1
        }, '-=.7');
    }, [location?.pathname]);

    // preloader end after page load
    useEffect(() => {
        const tl = new TimelineLite();
        if (!MainStore.globalReducer.globalLoader) {
            tl.to('.page-transition', {
                delay: 1,
                xPercent: 100, // Move to the right side
                opacity: 0,
                duration: 0.8,
                display: 'none'
            }).to('.page-transition .logo', {
                duration: .6,
                opacity: 0
            }, '-=.6');
        }
    }, [MainStore]);

    return null; // This component doesn't render anything
};

export default PageTransition;
