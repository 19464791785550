import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {CSSPlugin, gsap} from "gsap";

const MyComponent = ({img,svg,title,padding,mobilePaddingTop,description}) => {

    // const containerRef = useRef(null);
    // const imgRef = useRef(null);
    //
    // let getPost = useSelector(state => state);
    //
    // useEffect(() => {
    //     const container = containerRef.current;
    //     const img = imgRef.current;
    //
    //     if (container && img) {
    //         gsap.to(img, {
    //             yPercent: -20, // Adjust this value for desired parallax effect
    //             ease: "none",
    //             scrollTrigger: {
    //                 trigger: container,
    //                 scrub: true,
    //             },
    //         });
    //     }
    // }, [getPost]);


    return (
        <StyledComponent className={''} paddingTop={padding} mobilePaddingTop={mobilePaddingTop}>
            <Container fluid className={'climax-zigzag'}>
                <Row>
                    <Col md={ {span:8,offset:4} } className={'pr-0'}>
                        {
                            img &&
                            <div className="climax-img climax-reveal" >
                                <Img dataSpeed={1} src={img} alt={'climax reveal'} />
                            </div>
                        }

                    </Col>
                </Row>
                <Row className={'climax-text-row'}>
                    <Col md={ 10 } className={'climax-text'}>
                        <Col md={4} className={'climax-letter-wrapper'}>
                            <div className="climax-letter">
                                <img src={svg}/>
                                {/*{reactHtmlParser(svg)}*/}
                            </div>
                        </Col>
                        <Col md={{span:7,offset:1}}>
                            <div className="climax-desc">
                                <h2 className={'split-up'}>{title}</h2>
                                <p className={'split-up'}>{description}</p>
                            </div>
                        </Col>
                    </Col>
                </Row>

            </Container>



        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  background-color: #3C4348;
  margin-top: -5px;
  padding-top: ${(props) => props.paddingTop || '0'};
  

  .climax-img{
    position: relative;
    padding-top: calc(450 / 968 * 100%);
  }
  .climax-text-row{
    margin-top: -160px;
    //transform: translateY(-50%);
    @media(max-width: 767px){
      margin-top: 0;
    }
  }
  .reverse{
    display: flex;
    flex-direction: row-reverse;
  }
  .climax-text{
    background-color: #8D8D8D;
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 60px;

    .climax-letter-wrapper{
      display: flex;
      justify-content: center;
    }
    .climax-letter{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .climax-desc{
      display: flex;
      flex-direction: column;
      h2{
        color: #ffffff;
        font-family: Century;
        font-size: 60px;
        line-height: 68px;
        margin-bottom: 20px;
      }
      p{
        color: #ffffff;
      }
    }
  }
  
  @media(max-width: 767px){
    padding-top: ${(props) => props.mobilePaddingTop || '0'};

    .col-md-8{
      padding-left: 0;
    }
    .climax-text{
      flex-direction: column;
      padding-right: 15px;
    }
    .climax-letter-wrapper{
      justify-content: flex-start !important;
      margin-bottom: 20px;
    }
    .col-md-4{
      padding-left: 0;
    }
    .col-md-7{
      padding-left: 0;
      padding-right: 0;
      .climax-desc{
        h2{
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
    
  }
`;

export default React.memo(MyComponent);
